
import { ToastContainer } from 'react-toastify';
import Routing from './Routing'
import { useEffect } from 'react';


function App() {

  useEffect(() => {
    window.document.title = 'Reservaciones | AGN'
    
  }, [])
  

  return (
    <div className="App">
      <ToastContainer/>
      <Routing/>

    </div>
  );
}

export default App;
