//Importaciones bootrap
import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { BsUnlockFill } from 'react-icons/bs'

//Importaciones Tabla
import { TableColumn } from 'react-data-table-component'
import TablaRae from '../../../Components/Tablas/TablaRae'
import UseUsuarios from '../Hooks/UseUsuarios'

//Importaciones creadas
import { MediadorRae } from '../Interfaces/InterfaceMediadores'
import FormUsuario from './FormUsuario'
import FormUsuarioPassword from './FormUsuarioPassword'

const TablaUsuarios = () => {
    const { mediadores, mediadoresGet, permisosGet, permisos, setPermisos } =
        UseUsuarios()

    const [mostrarModal, setMostrarModal] = useState(false)
    const [mostrarModalPassword, setMostrarModalPassword] = useState(false)
    const [registroSeleccionado, setRegistroSeleccionado] = useState<
        MediadorRae | any
    >({
        id_mediador: '',
        nombre: '',
        ap_paterno: '',
        ap_materno: '',
        correo_electronico: '',
        es_mediador: 0,
        estatus: 0,

        //Datos para crear la cuenta de usuario para los mediadores
        usuariorae: {
            id_usuario: '',
            usuario: '',
            password: '',

            modulos: {
                id_modulo: '',
                estado: ''
            }
        }
    })

    useEffect(() => {
        setPermisos(permisos)
        mediadoresGet()
        setRegistroSeleccionado(false)
        // eslint-disable-next-line
    }, [])

    const cerrarModal = () => {
        setMostrarModal(false)
        if (registroSeleccionado) {
            setRegistroSeleccionado(false)
        }
    }

    const columns: TableColumn<MediadorRae>[] = [
        {
            cell: (row) => (
                <div>
                    <Button
                        variant="warning"
                        onClick={() => {
                            permisosGet(row.usuariorae?.id_usuario!)
                            setRegistroSeleccionado(row)
                            setMostrarModal(true)
                        }}
                    >
                        Editar
                    </Button>
                </div>
            )
        },
        {
            cell: (row) => (
                <div>
                    <Button
                        variant="warning"
                        onClick={() => {
                            setRegistroSeleccionado(row)
                            setMostrarModalPassword(true)
                        }}
                    >
                        <BsUnlockFill />
                    </Button>
                </div>
            )
        },
        {
            name: 'Número',
            selector: (row) => row.id_mediador!
        },
        {
            name: 'Nombre Mediador',
            selector: (row) => row.nombre!
        },
        {
            name: 'Apellido paterno',
            selector: (row) => row.ap_paterno!
        },
        {
            name: 'Apellido Materno',
            selector: (row) => row.ap_materno!
        },
        {
            name: 'Correo Electronico',
            selector: (row) => row.correo_electronico!
        },
        {
            name: 'usuario',
            cell: (row) => (
                <div>
                    {(row.usuariorae &&
                        row.usuariorae.usuario &&
                        row.usuariorae.usuario) ||
                        ''}
                </div>
            )
        },
        {
            name: 'Estado',
            cell: (row) => (
                <div>
                    {row.estatus === 1 ? (
                        <div className="text-primary">
                            <h6> Habilitado </h6>
                        </div>
                    ) : (
                        <div className="text-danger">
                            <h6> No habilitado </h6>
                        </div>
                    )}
                </div>
            )
        }
    ]

    return (
        <div>
            {/* 
            -------------Visualizadores de PDF para los reportes del Sistema--------------

            <PDFViewer style={{ width: '100%', height: '700px' }}>
                <PDFDownloadLink document={<TablaUsuarios usuarios={mediadores} />} fileName="UsuariosRae.pdf">
            </PDFViewer>
            <PDFViewer style={{ width: '100%', height: '700px' }}>
                <TablaModulos />
            </PDFViewer> 
            */}

            <Modal
                show={mostrarModalPassword}
                onHide={() => setMostrarModalPassword(false)}
            >
                <TablaRae.ModalHeader>
                    {'Cambiar Contraseña'}
                </TablaRae.ModalHeader>
                <TablaRae.ModalBody>
                    <FormUsuarioPassword
                        registro_Seleccionado={registroSeleccionado}
                    />
                </TablaRae.ModalBody>
            </Modal>

            <TablaRae
                columns={columns}
                data={mediadores}
                onclicNuevo={() => {
                    setMostrarModal(true)
                }}
                closeModal={cerrarModal}
                show_modal={mostrarModal}
            >
                <TablaRae.ModalHeader>
                    {registroSeleccionado
                        ? 'Actualizar usuario'
                        : 'Nuevo Usuario'}{' '}
                </TablaRae.ModalHeader>
                <TablaRae.ModalBody>
                    <FormUsuario
                        registro_Seleccionado={registroSeleccionado}
                        returnSubmitSuccess={() => {
                            mediadoresGet()
                            cerrarModal()
                        }}
                        cerrarModalForm={cerrarModal}
                    />
                </TablaRae.ModalBody>
            </TablaRae>
        </div>
    )
}

export default TablaUsuarios
