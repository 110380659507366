import { format, getDay } from 'date-fns';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { TableColumn } from 'react-data-table-component'
import TablaRae from '../../../Components/Tablas/TablaRae'
import useDiasRally from '../Hooks/UseDiasRally'
import { DiasRallyProps } from '../Interfaces/InterfaceCatalogos'
import FormDiasRally from './FormDiasRally'
import { BiMessageSquareEdit } from "react-icons/bi";
const TablaDiasRally = () => {

  const { diasRallyGet, diasRally } = useDiasRally()
  const [mostrarModal, setMostrarModal] = useState(false)

  const [registroSeleccionado, setRegistroSeleccionado] = useState<DiasRallyProps | any>({
    id_dia: '',
    nombre_dia: '',
    fecha_inicio: '',
    fecha_fin: '',
    horario_inicio: '',
    horario_fin: ''
  })

  useEffect(() => {
    diasRallyGet()
    setRegistroSeleccionado(false)
  }, [])


  const cerrarModal = () => {
    setMostrarModal(false)
    if (registroSeleccionado) {
      setRegistroSeleccionado(false)
    }
  }


  const columns: TableColumn<DiasRallyProps>[] = [
    {
      cell: (row) => (
        <div>
          <Button variant='warning' onClick={() => {
            console.log(row)
            setRegistroSeleccionado(row)
            setMostrarModal(true)
          }} >
            <BiMessageSquareEdit size={'1.3rem'} className="text-light" />
          </Button>
        </div>
      ),

    },
    {
      name: "Número",
      selector: (row) => row.id_dia!,
    },
    {
      name: "Descripción",
      selector: (row) => row.nombre_dia!,
    },
    {
      name: "Fecha inicio",
      selector: (row) => row.fecha_inicio!,
    },
    {
      name: "Fecha de término",
      selector: (row) => row.fecha_fin!,
    },
    {
      name: "Horario de inicio",
      selector: (row) => format(new Date(row.horario_inicio!), "HH:mm"),
    },
    {
      name: "Horario de término",
      selector: (row) => format(new Date(row.horario_fin!), "HH:mm"),
    }
  ]



  return (
    <div>
      <TablaRae
        columns={columns}
        data={diasRally}
        onclicNuevo={() => { setMostrarModal(true) }}
        closeModal={cerrarModal}
        show_modal={mostrarModal}
        paginationPerPage={10}

      >
        <TablaRae.ModalHeader>{registroSeleccionado ? "Actualizar actividad" : "Nueva actividad"}  </TablaRae.ModalHeader>
        <TablaRae.ModalBody>
          <FormDiasRally
            registro_Seleccionado={registroSeleccionado}
            returnSubmitSuccess={() => {
              diasRallyGet()
              cerrarModal()
            }}
            cerrarModalForm={cerrarModal}
          />
        </TablaRae.ModalBody>

      </TablaRae>
    </div>
  )
}

export default TablaDiasRally