import { Form, Formik } from 'formik'
import React, { useState,useEffect } from 'react'

import { default as FormRae } from 'react-bootstrap/Form'
import { ReservacionesPropTabla, ReservacionesRae } from '../Interfaces/InterfaceReservacionesConfirmadas';

interface Props {
    registro_Seleccionado: ReservacionesRae | undefined;
    returnSubmitSuccess: () => void,
    cerrarModalForm: () => void
}



const FormVehiculos = ({ cerrarModalForm, registro_Seleccionado, returnSubmitSuccess }: Props) => {


    const [mostraModal, setMostraModal] = useState(false);
    const [reservacionSelect, setReservacionSelect] = useState<ReservacionesPropTabla | any>({

        vehiculos: {
            id_vehiculo: '',
            tipo_vehiculo: '',
            num_vehiculos: '',
            placas: ''
        }

    })

    useEffect(() => {
        if (registro_Seleccionado) {
            setReservacionSelect({

                id_vehiculo: registro_Seleccionado.vehiculos.id_vehiculo,
                tipo_vehiculo: registro_Seleccionado.vehiculos.tipo_vehiculo,
                num_vehiculos: registro_Seleccionado.vehiculos.num_vehiculos,
                placas: registro_Seleccionado.vehiculos.placas,
            })
        }

    }, [registro_Seleccionado])

    return (
        <div>


            <Formik
                initialValues={reservacionSelect}
                onSubmit={async (values) => {
                   
                }}
                enableReinitialize
            >
                {({  values  }) => (
                    <Form>

                        <FormRae.Group controlId="tipo_vehiculo" className="mb-1">
                            <FormRae.Label>Tipo vehículo</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="tipo_vehiculo"
                                value={values.tipo_vehiculo || ''}
                                disabled
                            />
                        </FormRae.Group>

                        <FormRae.Group controlId="num_vehiculos" className="mb-1">
                            <FormRae.Label>Número de vehículos</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="num_vehiculos"
                                value={values.num_vehiculos || ''}
                                disabled
                            />
                        </FormRae.Group>

                        <FormRae.Group controlId="placas" className="mb-1">
                            <FormRae.Label>Números de las placas</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="placas"
                                value={values.placas || ''}
                                disabled
                            />
                        </FormRae.Group>

                    </Form>
                )}
            </Formik>




        </div>
    )
}

export default FormVehiculos