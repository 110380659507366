import React, { useState } from 'react'
import clienteAxios from '../../../Config/Axios';


const useTipoActividad = () => {

    const [tipoActividad, setTipoActividad] = useState([]);

    //------------------------Peticiones para los talleres---------------
    const tipoActividadGet = async () => {
        const consultaActividades = await clienteAxios.get('/tipoactividad');
        setTipoActividad(consultaActividades.data);
    }

    return {

        tipoActividad, tipoActividadGet
    }
}
export default useTipoActividad