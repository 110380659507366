import { useState } from 'react'
import { AlertaRae } from '../../../Components/Alertas/AlertaToastify'
import clienteAxios from '../../../Config/Axios'
import { ResponseData } from '../../../InterfacesGlobales/InterfaceResponse'
import { MediadorRae, PermisosProps } from '../Interfaces/InterfaceMediadores'
import { IntFormUsuarioPassword } from '../Interfaces/InterfaceFormPassword'

const UseUsuarios = () => {
    const [mediadores, setMediadores] = useState<MediadorRae[]>([])
    const [permisos, setPermisos] = useState<PermisosProps[]>([])

    //-------Peticiones para el sevidor

    const mediadoresGet = async () => {
        const obtenerMediadores = await clienteAxios.get<MediadorRae[]>(
            '/usuarios/mediador'
        )

        setMediadores(obtenerMediadores.data)
    }

    const mediadoresPost = async (values: MediadorRae) => {
        const nuevoMediador = await clienteAxios
            .post<ResponseData>('/usuarios', values)
            .catch((err) => console.log(err))
        AlertaRae(nuevoMediador?.data.styles, nuevoMediador?.data.msg)
    }

    const cambiarContraseñaUsuario = async (values: IntFormUsuarioPassword) => {
        const nuevoContrasena = await clienteAxios
            .post<ResponseData>('/usuarios/nuevapassword', values)
            .catch((err) => console.log(err))
        AlertaRae(nuevoContrasena?.data.styles, nuevoContrasena?.data.msg)
    }

    const permisosGet = async (id_usuario: number) => {
        const obtenerPermisos = await clienteAxios.get<PermisosProps[]>(
            `/usuarios/permisos/${id_usuario}`
        )
        setPermisos(obtenerPermisos.data)
        
    }

    const permisosPost = async (values: PermisosProps) => {
        const nuevoPermiso = await clienteAxios
            .post<ResponseData>('/usuarios/permisos', values)
            .catch((err) => console.log(err))

        if (nuevoPermiso?.status === 200) {
            return nuevoPermiso.data
        } else {
            AlertaRae(nuevoPermiso?.data.styles, nuevoPermiso?.data.msg)
            return
        }
    }

    return {
        mediadores,
        mediadoresGet,
        mediadoresPost,
        setMediadores,
        permisosPost,
        permisosGet,
        permisos,
        setPermisos,
        cambiarContraseñaUsuario
    }
}

export default UseUsuarios
