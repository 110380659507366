import React from 'react'
import TablaReservaciones from '../Components/TablaReservaciones'



//<TablaCitas />
const PageCitas = () => {
  return (
    <div>
      <hr className="my-1 gob"/>
      <TablaReservaciones />
    </div>
  )
}

export default PageCitas