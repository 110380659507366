import { ErrorMessage, Form, Formik } from 'formik'
import { useContext } from 'react'
import UseTalleres from '../Hooks/UseTalleres'
import { useState, useEffect, ChangeEvent } from 'react';
import { TalleresPropTabla, ModalTalleres } from '../Interfaces/InterfaceTalleres';
import { default as FormRae } from 'react-bootstrap/Form'
import { Button } from 'react-bootstrap';
import TablaRae from '../../../Components/Tablas/TablaRae';
import * as Yup from 'yup'
import { ContextApp } from '../../../Context/StateApp';

import CryptoJS from 'crypto-js';
import { AlertaRae } from '../../../Components/Alertas/AlertaToastify';
import Swal from 'sweetalert2';


interface Props {
  registro_Seleccionado: TalleresPropTabla | undefined;
  returnSubmitSuccess: () => void,
  cerrarModalForm: () => void
}

const FormTaller = ({ registro_Seleccionado, returnSubmitSuccess, cerrarModalForm }: Props) => {

  const { talleresPost } = UseTalleres()

  const { state: { usuario_logeado, logeado } } = useContext(ContextApp)

  const [tallerSelect, setTallerSelect] = useState<TalleresPropTabla | any>({
    id_taller: '',
    nombre_t: '',
    estado: '',
    usuario_logeado: usuario_logeado?.id_mediador,
  })


  useEffect(() => {
    if (registro_Seleccionado) {
      setTallerSelect({
        id_taller: registro_Seleccionado.id_taller,
        nombre_t: registro_Seleccionado.nombre_t,
        estado: registro_Seleccionado.estado === 1 ? true : false,
        usuario_logeado: usuario_logeado?.id_mediador
      })
    }

  }, [registro_Seleccionado])

  const validaciones = Yup.object().shape({
    nombre_t: Yup.string().required('Este campo es obligatorio'),
  })




  return (
    <div>
      <Formik
        initialValues={tallerSelect}
        onSubmit={async (values) => {
          await talleresPost(values)
          returnSubmitSuccess()
        }}
        enableReinitialize
        validationSchema={validaciones}
      >
        {({ handleChange, values, setFieldValue }) => (


          <Form>

            <FormRae.Group controlId="id_taller" className="mb-3">
              <FormRae.Label>Número</FormRae.Label>
              <FormRae.Control
                type="text"
                name="id_taller"
                placeholder='Número Taller'
                disabled
                value={values.id_taller || ''}

              />
            </FormRae.Group>

            <FormRae.Group controlId="nombre_t" className="mb-3">
              <FormRae.Label>Nombre</FormRae.Label>
              <FormRae.Control
                type="text"
                name="nombre_t"
                placeholder='Escribe el nombre del taller'
                onChange={handleChange}
                value={values.nombre_t || ''}
              />
              <ErrorMessage name='nombre_t' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />

            </FormRae.Group>
         

            <FormRae.Group controlId="estado" className="mb-3">
              <FormRae.Label>Estado</FormRae.Label>
              <FormRae.Check
                type="checkbox"
                name="estado"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("estado", e.target.checked)}
                checked={values.estado || ''}
              />
            </FormRae.Group>

            <TablaRae.ModalFooter>
              <Button variant="primary"
                type="submit"
              >
                {registro_Seleccionado ? "Actualizar" : "Guardar"}
              </Button>

              <Button variant="primary" onClick={cerrarModalForm} >
                Cerrar
              </Button>

            </TablaRae.ModalFooter>


          </Form>
        )}
      </Formik>

    </div>
  )
}

export default FormTaller