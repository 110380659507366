import { Form, Formik } from 'formik';
import { useState, useEffect } from 'react'
import UseContactos from '../Hooks/UseContactos';
import { ContactosProps } from '../Interfaces/InterfaceContactos';
import { default as FormRae } from 'react-bootstrap/Form'
import TablaRae from '../../../Components/Tablas/TablaRae';
import { Button } from 'react-bootstrap';



interface Props {
    registro_Seleccionado: ContactosProps | undefined;
    returnSubmitSuccess: () => void,
    cerrarModalForm: () => void
}


const FormContactos = ({ registro_Seleccionado, returnSubmitSuccess, cerrarModalForm }: Props) => {

    const { contactos, contactosGet } = UseContactos()
    const [mostraModal, setMostraModal] = useState(false);
    const [contactoSelect, setContactoSelect] = useState<ContactosProps | any>({
        nombre: '',
        ap_paterno: '',
        ap_materno: '',
        correo_elec: '',
        telefono: '',
        nombre_institucion: '',

    })

    const cerrarModal = () => {
        setMostraModal(false)
        if (registro_Seleccionado) {
            setContactoSelect(false)
        }
    }

    useEffect(() => {
        if (registro_Seleccionado) {
            setContactoSelect({
                nombre: registro_Seleccionado.nombre,
                ap_paterno: registro_Seleccionado.ap_paterno,
                ap_materno: registro_Seleccionado.ap_materno,
                correo_elec: registro_Seleccionado.correo_elec,
                telefono: registro_Seleccionado.telefono,
                nombre_institucion: registro_Seleccionado.nombre_institucion
            })
        }

    }, [registro_Seleccionado])

    return (
        <div>
            <Formik
                initialValues={contactoSelect}
                onSubmit={async (values) => {

                }}
                enableReinitialize

            >
                {({ values }) => (
                    <Form>

                        <FormRae.Group controlId="nombre" className="mb-3">
                            <FormRae.Label>Nombre de la institución</FormRae.Label>
                            <FormRae.Control defaultValue={values.nombre_institucion} disabled />
                        </FormRae.Group>

                        <FormRae.Group controlId="nombre" className="mb-3">
                            <FormRae.Label>Nombre</FormRae.Label>
                            <FormRae.Control defaultValue={values.nombre} disabled />
                        </FormRae.Group>

                        <FormRae.Group controlId="ap_paterno" className="mb-3">
                            <FormRae.Label>Primer apellido</FormRae.Label>
                            <FormRae.Control defaultValue={values.ap_paterno} disabled />
                        </FormRae.Group>

                        <FormRae.Group controlId="ap_materno" className="mb-3">
                            <FormRae.Label>Segundo apellido</FormRae.Label>
                            <FormRae.Control defaultValue={values.ap_materno} disabled />
                        </FormRae.Group>

                        <FormRae.Group controlId="correo_elec" className="mb-3">
                            <FormRae.Label>Correo electrónico</FormRae.Label>
                            <FormRae.Control defaultValue={values.correo_elec} disabled />
                        </FormRae.Group>
                        <FormRae.Group controlId="telefono" className="mb-3">
                            <FormRae.Label>Teléfono</FormRae.Label>
                            <FormRae.Control defaultValue={values.telefono} disabled />
                        </FormRae.Group>

                        <TablaRae.ModalFooter>


                            <Button variant="primary" onClick={cerrarModalForm} >
                                Cerrar
                            </Button>

                        </TablaRae.ModalFooter>


                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default FormContactos
