import TablaCuentacuentos from "../Components/TablaCuentacuentos"


const PageCuentacuentos = () => {


  return (

    <div>
      <hr className="my-1 gob"/>
      <TablaCuentacuentos />
    </div >

  )
}


export default PageCuentacuentos