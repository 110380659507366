import React from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { ReservacionesRae } from '../Interfaces/InterfaceReservacionesCanceladas';


import FormDatosGenerales from './FormDatosGenerales';




interface Props {
    registro_Seleccionado: ReservacionesRae | undefined;
    returnSubmitSuccess: () => void,
    cerrarModalForm: () => void
}





const FormCitasCanceladas = ({ cerrarModalForm, registro_Seleccionado, returnSubmitSuccess }: Props) => {
    return (
        <div >

            <Tabs
                defaultActiveKey="uno"
                id="justify-tab-example"
                className=""
                justify
            >
                <Tab eventKey="uno" title=" Información General"  >
                    <FormDatosGenerales cerrarModalForm={cerrarModalForm} registro_Seleccionado={registro_Seleccionado} returnSubmitSuccess={returnSubmitSuccess} />
                </Tab>

            </Tabs>

        </div>
    )
}

export default FormCitasCanceladas