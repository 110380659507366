import { Form, Formik } from 'formik'
import { useState, useEffect, ChangeEvent } from 'react';
import { default as FormRae } from 'react-bootstrap/Form'
import { Button, Tabs } from 'react-bootstrap';
import TablaRae from '../../../Components/Tablas/TablaRae';
import { ReservacionesPropTabla, ReservacionesRae } from '../Interfaces/InterfaceReservacionesRally';

import ModalGrande from '../../../Components/Modales/ModalGrande';

import Tab from 'react-bootstrap/Tab'
import FormResponsable from './FormResponsable';
import FormGrupo from './FormGrupo';
import FormDatosGenerales from './FormDatosGenerales';
import FormVehiculos from './FormVehiculos';

interface Props {
    registro_Seleccionado: ReservacionesRae | undefined;
    returnSubmitSuccess: () => void,
    cerrarModalForm: () => void
}


const FormCitas = ({ cerrarModalForm, registro_Seleccionado, returnSubmitSuccess }: Props) => {

    return (
        <div >

            <Tabs
                defaultActiveKey="uno"
                id="justify-tab-example"
                className="mb-3"
                justify
            >
                <Tab eventKey="uno" title="Datos generales" >
                    <FormDatosGenerales cerrarModalForm={cerrarModalForm} registro_Seleccionado={registro_Seleccionado} returnSubmitSuccess={returnSubmitSuccess} />
                </Tab>
               <Tab eventKey="dos" title="Datos particulares">
                    <FormGrupo cerrarModalForm={cerrarModalForm} registro_Seleccionado={registro_Seleccionado} returnSubmitSuccess={returnSubmitSuccess} />
                </Tab>
                  <Tab eventKey="tres" title="Datos del responsable">
                    <FormResponsable cerrarModalForm={cerrarModalForm} registro_Seleccionado={registro_Seleccionado} returnSubmitSuccess={returnSubmitSuccess} />
                </Tab>
                <Tab eventKey="cuatro" title="Datos del vehículo">
                    <FormVehiculos cerrarModalForm={cerrarModalForm} registro_Seleccionado={registro_Seleccionado} returnSubmitSuccess={returnSubmitSuccess} />
                </Tab> 
            </Tabs>

        </div>
    )
}

export default FormCitas