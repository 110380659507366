

import DataTable, { TableColumn, TableProps } from 'react-data-table-component';
import Button from 'react-bootstrap/Button';
import { BsPlusSquareFill } from "react-icons/bs";
import { useState, ReactNode, Children } from 'react';
import { Modal } from 'react-bootstrap';

import PaginacionTablas from '../Paginaciones/PaginacionTablas'

interface PropsTabla<T> extends TableProps<T> {
    show_modal?: boolean,
    closeModal?: () => void,
    onclicNuevo?: () => void,
    children?: ReactNode,
    paginationPerPage?: number,
}




function TablaRae<T>({ columns, data, show_modal, closeModal, onclicNuevo, children, paginationPerPage, ...resto}: PropsTabla<T>) {

    //const [registro, setRegistro] = useState<number | undefined>(undefined)

    return (
        <>
            <div className=' p-2 mb-1' >
                {onclicNuevo ? <Button variant='warning' size='sm' onClick={onclicNuevo}> <BsPlusSquareFill /> Nuevo </Button> : null}

            </div>
    
            <DataTable
                columns={columns}
                data={data}
                responsive
                
             
                noDataComponent={<h3 className='text-warning '>Sin resultados</h3>}
                highlightOnHover
                selectableRowsHighlight
               
                customStyles={{
                    
                    headCells: {
                        style: {
                            backgroundColor: "var(--bs-primary)",
                            color: "white",
                            fontSize: ".8rem",
                            width:"auto"
                        }

                    },
                    rows: {

                        selectedHighlightStyle: {
                            color: "white !important",
                            backgroundColor: "var(--bs-warning)!important",
                        }

                    }
                }}
                pagination
                paginationPerPage={paginationPerPage}
                paginationComponent={PaginacionTablas}
                {...resto}
            />



            <Modal show={show_modal} onHide={closeModal} >

                {children}

            </Modal>

        </>

    )
}
        /* onRowClicked={({ id, title, year}) => setRegistro(id)} */
            /* selectableRowSelected={(row) => registro ? row.id === registro  ? true : false : false} */
//----------Modal para los catalogos----------------
const ModalHeader = ({ children }: { children: ReactNode }) => {
    return (
        <Modal.Header closeButton className='bg-warning text-light'>
            <Modal.Title >{children}</Modal.Title>
        </Modal.Header>
    )
}


const ModalBody = ({ children }: { children: ReactNode }) => {
    return (
        <Modal.Body> {children} </Modal.Body>
    )
}

const ModalFooter = ({ children }: { children: ReactNode }) => {
    return (
        <Modal.Footer>
          {children}
        </Modal.Footer>
    )
}




TablaRae.ModalBody = ModalBody
TablaRae.ModalHeader = ModalHeader
TablaRae.ModalFooter = ModalFooter


export default TablaRae