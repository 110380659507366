import React, { useState } from 'react'
import Swal from 'sweetalert2';
import clienteAxios from '../../../Config/Axios';

import { ResponseDataSwet } from '../../../InterfacesGlobales/InterfaceResponseSweet';
import { Reservaciones, ReservacionesAtributos } from '../Interfaces/InterfaceReservacionesForm'
import { Response } from 'express';
import { useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';


const UseCitas = () => {

    const history = useNavigate();
    const [formReservaciones, setFormReservaciones] = useState<Reservaciones[]>([]);

    const reservacionesPost = async (values: Reservaciones) => {

        if (values.usuario_logeado) {


        } else {

            if (values.id_tipo_actividad == 6) {

            } else {
                const cantidadPersonas = Number(values.numero_hom) + Number(values.numero_muj)

                if (cantidadPersonas < 5) {
                    return await Swal.fire({
                        title: 'El número mínimo de personas es de 5',
                        icon: 'error',
                        showDenyButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: '#B38E5D',
                        buttonsStyling: true,
                        color: 'white',
                        background: '#13322B'
                    })
                }

                if (cantidadPersonas > 40) {
                    return await Swal.fire({
                        title: 'El número maximo de personas es de 40',
                        icon: 'error',
                        showDenyButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: '#B38E5D',
                        buttonsStyling: true,
                        color: 'white',
                        background: '#13322B'
                    })
                }
            }


        }


        const res = await clienteAxios.post<ResponseDataSwet>("/reservaciones", values).catch(err => err.response)

        await Swal.fire({
            title: res?.data.msg,
            icon: res?.data.styles,
            showDenyButton: false,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#B38E5D',
            buttonsStyling: true,
            color: 'white',
            background: '#13322B'
        })

        if (res.status === 200) {
            await Swal.fire({
                title: 'Gracias por tu reservación. Espera tu confirmación por correo electrónico',
                icon: 'info',
                showDenyButton: false,
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#B38E5D',
                buttonsStyling: true,
                color: 'white',
                background: '#13322B'
            })
        }

        if (values.usuario_logeado) {
            window.location.reload();
        } else {
            history('/');
        }

    }

    return {
        reservacionesPost, formReservaciones
    }

}

export default UseCitas