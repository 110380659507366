import React from 'react'
import TablaReservacionesCanceladas from '../Components/TablaReservacionesCanceladas';

const PageCitasCanceladas = () => {
  return (
    <div>
      <hr className="my-1 gob" />
      <TablaReservacionesCanceladas />
    </div>
  )
}

export default PageCitasCanceladas