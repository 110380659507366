import { useState, useEffect } from 'react'


import UseContactos from '../Hooks/UseContactos'
import { TalleresProps } from '../../Talleres/Interfaces/InterfaceTalleres'
import { TableColumn } from 'react-data-table-component'
import { ContactosProps } from '../Interfaces/InterfaceContactos';
import { Button } from 'react-bootstrap';
import TablaRae from '../../../Components/Tablas/TablaRae';
import FormContactos from './FormContactos';


import { BsZoomIn } from "react-icons/bs";




const TablaContactos = () => {


  const { contactos, contactosGet } = UseContactos()
  const [mostrarModal, setMostrarModal] = useState(false)
  const [registroSeleccionado, setRegistroSeleccionado] = useState<ContactosProps | any>({
    id_reservacion: '',
    id_responsable: '',
    nombre: '',
    ap_paterno: '',
    ap_materno: '',
    correo_elec: '',
    telefono: '',
  })

  useEffect(() => {
    contactosGet()
    setRegistroSeleccionado(false)
  }, [])

  const cerrarModal = () => {
    setMostrarModal(false)
    if (registroSeleccionado) {
      setRegistroSeleccionado(false)
    }
  }

  const columns: TableColumn<ContactosProps>[] = [
    {
      cell: (row) => (
        <div >
          <Button variant='warning' onClick={() => {

            setRegistroSeleccionado(row)
            setMostrarModal(true)
          }} >
            <BsZoomIn className='text-light' />
          </Button>
        </div>
      ),
      grow: 0.0

    },
    {
      name: "Nombre de la institución",
      selector: (row) => row.nombre_institucion!,
      grow: 3
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre!,
      grow: 3,
      center: true,
    },
    {
      name: "Primer apellido",
      selector: (row) => row.ap_paterno!,
      grow: 3
    },
    {
      name: "Segundo apellido",
      selector: (row) => row.ap_materno!,
      grow: 3
    },
    {
      name: "Correo electrónico",
      selector: (row) => row.correo_elec!,
      grow: 3
    },
    {
      name: "Teléfono",
      selector: (row) => row.telefono!,
      grow: 3
    },

  ]


  return (
    <div>
   

      <TablaRae
        columns={columns}
        data={contactos}
        //onclicNuevo={() => { setMostrarModal(true) }}
        closeModal={cerrarModal}
        show_modal={mostrarModal}

      >


        <TablaRae.ModalHeader>{registroSeleccionado ? "Información del contacto" : ""} </TablaRae.ModalHeader>
        <TablaRae.ModalBody>
          <FormContactos
            registro_Seleccionado={registroSeleccionado}
            returnSubmitSuccess={() => {
              contactosGet()
              cerrarModal()
            }}
            cerrarModalForm={cerrarModal}

          />
        </TablaRae.ModalBody>


      </TablaRae>



    </div>
  )
}

export default TablaContactos