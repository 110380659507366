import React, { useState } from 'react'
import clienteAxios from '../../../Config/Axios'
import { ContactosProps } from '../Interfaces/InterfaceContactos'
import CryptoJS from 'crypto-js';


const UseContactos = () => {

  const [contactos, setContactos] = useState<ContactosProps[]>([])

  const contactosGet = async () => {
    const obtenerContactos = await clienteAxios.get<ContactosProps[]>('/contactos');

    // Decrypt
    const Decrypt = (texto: string) => {

      const enDes = CryptoJS.AES.decrypt(texto, process.env.REACT_APP_CLAVE_ENCRIPT!);
      const enDeci = CryptoJS.enc.Utf8.stringify(enDes);
      return enDeci;
    }


    const arreglo = obtenerContactos.data;
    if (arreglo) {
      for (const i in arreglo) {
        arreglo[i].nombre = Decrypt(arreglo[i].nombre!)
        arreglo[i].ap_paterno = Decrypt(arreglo[i].ap_paterno!)
        arreglo[i].ap_materno = Decrypt(arreglo[i].ap_materno!)
        arreglo[i].correo_elec = Decrypt(arreglo[i].correo_elec!)
        arreglo[i].telefono = Decrypt(arreglo[i].telefono!)
      }

      const setObj = new Set(); // creamos pares de clave y array
      var arreglo_filtrado = arreglo.reduce((acc: any, Contacto: ContactosProps) => {
        if (!setObj.has(Contacto.correo_elec)) {
          setObj.add(Contacto.correo_elec)
          acc.push(Contacto)
        }
        return acc;
      }, []);
    }

    setContactos(arreglo_filtrado)
  }

  return {
    contactosGet, contactos
  }

}

export default UseContactos