import { ErrorMessage, Form, Formik } from "formik";
import React, { useState, useEffect, ChangeEvent } from "react";
import { Button } from "react-bootstrap";
import UseReservaciones from "../Hooks/UseReservaciones";
import { default as FormRae } from "react-bootstrap/Form";
import {
  ReservacionesRae,
  info_grupo,
} from "../Interfaces/InterfaceReservaciones";
import * as Yup from "yup";
import useTipoActividad from "../../Publica/hooks/useTipoActividad";
import UseTalleres from "../../Talleres/Hooks/UseTalleres";
import UseCuentos from "../../Cuentacuentos/Hooks/UseCuentos";
import { useContext } from "react";
import { ContextApp } from "../../../Context/StateApp";

interface Props {
  registro_Seleccionado: ReservacionesRae | undefined;
  returnSubmitSuccess: () => void;
  cerrarModalForm: () => void;
}

const FormGrupo = ({
  cerrarModalForm,
  registro_Seleccionado,
  returnSubmitSuccess,
}: Props) => {
  const { reservaciones, reservacionesGet } = UseReservaciones();

  const {
    state: { usuario_logeado },
  } = useContext(ContextApp);

  const { actualizarReservacion } = UseReservaciones();
  const { talleresGet, talleres } = UseTalleres();
  const { cuentacuentosGet, cuentos } = UseCuentos();

  const [mostraModal, setMostraModal] = useState(false);
  const [citaSelect, setCitaSelect] = useState<info_grupo | any>({
    id_reservacion: "",
    id_actividad_a: "",
    id_cuento: "",
    id_taller: "",
    momento_act: "",
    numero_hom: 0,
    numero_muj: 0,
    grupo: {
      tipo_grupo: "",
      nombre_institucion: "",
      numero_hom: "",
      numero_muj: "",
      codigo_postal: "",
      pais: {
        id_pais: "",
        nombre_pais: "",
      },
      estado: {
        id_estado: "",
        nombre_estado: "",
      },
      municipio: {
        id_municipio: "",
        nombre_municipio: "",
      },
      colonia: {
        id_cuento: "",
        id_colonia: "",
        nombre_colonia: "",
      },
      calle_num: "",
      grado_escolar: "",
      modalidad: "",
    },
    usuario_logeado: usuario_logeado?.id_mediador,
    expectativas: "",
  });

  useEffect(() => {
    cuentacuentosGet();
    talleresGet();
  }, []);

  useEffect(() => {
    if (registro_Seleccionado) {
      setCitaSelect({
        id_grupo: registro_Seleccionado.grupo!.id_grupo,
        id_reservacion: registro_Seleccionado.id_reservacion,
        tipo_grupo: registro_Seleccionado.grupo!.tipo_grupo
          ? registro_Seleccionado.grupo!.tipo_grupo
          : "",
        modalidad: registro_Seleccionado.modalidad
          ? registro_Seleccionado.modalidad
          : "",
        id_actividad_a: registro_Seleccionado.idactividad!.id_actividad
          ? registro_Seleccionado.idactividad!.id_actividad
          : "",

        id_taller: registro_Seleccionado.id_taller
          ? registro_Seleccionado.id_taller
          : "",
        id_cuento: registro_Seleccionado.id_cuento
          ? registro_Seleccionado.id_cuento
          : "",
        momento_act: registro_Seleccionado.momento_act
          ? registro_Seleccionado.momento_act
          : "",

        nombre_institucion: registro_Seleccionado.grupo!.nombre_institucion
          ? registro_Seleccionado.grupo!.nombre_institucion
          : "",
        numero_hom: registro_Seleccionado.grupo!.numero_hom
          ? registro_Seleccionado.grupo!.numero_hom
          : "",
        numero_muj: registro_Seleccionado.grupo!.numero_muj
          ? registro_Seleccionado.grupo!.numero_muj
          : "",
        codigo_postal: registro_Seleccionado.grupo!.codigo_postal
          ? registro_Seleccionado.grupo!.codigo_postal
          : "",

        id_pais: registro_Seleccionado.grupo!.pais
          ? registro_Seleccionado.grupo!.pais.nombre_pais!
          : "",
        id_estado: registro_Seleccionado.grupo!.estado
          ? registro_Seleccionado.grupo!.estado.nombre_estado
          : "",
        id_municipio: registro_Seleccionado.grupo!.municipio
          ? registro_Seleccionado.grupo!.municipio.nombre_municipio
          : "",
        id_colonia: registro_Seleccionado.grupo!.colonia
          ? registro_Seleccionado.grupo!.colonia.nombre_colonia
          : "",

        n_estado: registro_Seleccionado.grupo!.n_estado
          ? registro_Seleccionado.grupo!.n_estado
          : "",
        n_municipio: registro_Seleccionado.grupo!.n_municipio
          ? registro_Seleccionado.grupo!.n_municipio
          : "",
        n_colonia: registro_Seleccionado.grupo!.n_colonia
          ? registro_Seleccionado.grupo!.n_colonia
          : "",
        calle_num: registro_Seleccionado.grupo!.calle_num
          ? registro_Seleccionado.grupo!.calle_num
          : "",
        grado_escolar: registro_Seleccionado.grupo!.grado_escolar
          ? registro_Seleccionado.grupo!.grado_escolar
          : "",
        usuario_logeado: usuario_logeado?.id_mediador,

        expectativas: registro_Seleccionado.expectativas
          ? registro_Seleccionado.expectativas
          : "",
      });
    }
  }, [registro_Seleccionado]);

  const validaciones = Yup.object().shape({
    tipo_grupo: Yup.string().required("Este campo es obligatorio"),
    numero_hom: Yup.string().required("Este campo es obligatorio"),
    numero_muj: Yup.string().required("Este campo es obligatorio"),
    grado_escolar: Yup.string().required("Este campo es obligatorio"),
    id_actividad_a: Yup.string(),
    id_taller: Yup.string().when("id_actividad_a", {
      is: (val: number) => val == 3 || val == 4,
      then: Yup.string().required("Este campo es obligatorio"),
    }),
    id_cuento: Yup.string().when("id_actividad_a", {
      is: (val: number) => val == 2 || val == 5,
      then: Yup.string().required("Este campo es obligatorio"),
    }),
    momento_act: Yup.string().when("id_actividad_a", {
      is: (val: number) => val == 2 || val == 3,
      then: Yup.string().required("Este campo es obligatorio"),
    }),

    nombre_institucion: Yup.string().when("tipo_grupo", {
      is: (val: string) => val == "Institucional",
      then: Yup.string()
        .max(300, "Este campo solo permite 300 caracteres")
        .required("Este campo es obligatorio"),
    }),
  });

  interface PropsTalleres {
    id_taller?: number;
    nombre_t: string;
  }

  interface PropsCuentos {
    id_cuento?: number;
    nombre_c: string;
  }

  return (
    <div>
      <Formik
        initialValues={citaSelect}
        onSubmit={async (values) => {
          await actualizarReservacion(values);
          reservacionesGet();
        }}
        enableReinitialize
        validationSchema={validaciones}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            {/* 
                        <FormRae.Group controlId="modalidad" className="w-100">
                            <FormRae.Label>* Modalidad</FormRae.Label>
                            <FormRae.Select
                                name="modalidad"
                                as="select"
                                value={values.modalidad || ''}
                                onChange={handleChange}
                            >
                                <option value="">-- Selecciona una modalidad --</option>
                                <option key="1" value="Presencial">Presencial</option>
                                <option key="2" value="Virtual">Virtual</option>
                            </FormRae.Select>
                        </FormRae.Group> */}

            {values.id_actividad_a == 2 || values.id_actividad_a == 5 ? (
              <>
                <FormRae.Group controlId="id_cuento" className="w-100">
                  <FormRae.Label>* Tema del Cuentacuentos</FormRae.Label>
                  <FormRae.Select
                    name="id_cuento"
                    onChange={handleChange}
                    value={values.id_cuento || ""}
                  >
                    <option value="">
                      -- Selecciona el tema del cuentacuentos --
                    </option>
                    {cuentos.map((cuentos: PropsCuentos, index) => (
                      <option value={cuentos.id_cuento} key={index}>
                        {" "}
                        {cuentos.nombre_c}{" "}
                      </option>
                    ))}
                  </FormRae.Select>
                  <ErrorMessage
                    name="id_cuento"
                    className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                    component={"div"}
                  />
                </FormRae.Group>
              </>
            ) : (
              ""
            )}

            {values.id_actividad_a == 3 || values.id_actividad_a == 4 ? (
              <>
                <FormRae.Group controlId="id_taller" className="w-100">
                  <FormRae.Label>* Tema del taller</FormRae.Label>
                  <FormRae.Select
                    name="id_taller"
                    onChange={handleChange}
                    value={values.id_taller || ""}
                  >
                    <option value="">
                      -- Selecciona el tema del taller --
                    </option>
                    {talleres.map((talleres: PropsTalleres, index) => (
                      <option key={index} value={talleres.id_taller}>
                        {" "}
                        {talleres.nombre_t}{" "}
                      </option>
                    ))}
                  </FormRae.Select>

                  <ErrorMessage
                    name="id_taller"
                    className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                    component={"div"}
                  />
                </FormRae.Group>
              </>
            ) : (
              ""
            )}

            {values.id_actividad_a == 2 || values.id_actividad_a == 3 ? (
              <>
                <FormRae.Label>* Momento actividad</FormRae.Label>

                <FormRae.Group controlId="momento_act" className="w-100">
                  <FormRae.Select
                    name="momento_act"
                    as="select"
                    value={values.momento_act || ""}
                    onChange={handleChange}
                  >
                    <option value="">
                      -- Selecciona el momento de la actividad--
                    </option>
                    <option key="1" value="Antes del recorrido">
                      {" "}
                      Antes del recorrido{" "}
                    </option>
                    <option key="2" value="Después del recorrido">
                      {" "}
                      Después del recorrido{" "}
                    </option>
                  </FormRae.Select>
                  <ErrorMessage
                    name="momento_act"
                    className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                    component={"div"}
                  />
                </FormRae.Group>
              </>
            ) : (
              ""
            )}

            <FormRae.Label>* Tipo Grupo</FormRae.Label>
            <div className="d-flex">
              <FormRae.Group controlId="tipo_grupo" className="w-100">
                <FormRae.Select
                  name="tipo_grupo"
                  as="select"
                  value={values.tipo_grupo || ""}
                  onChange={(e) => {
                    setFieldValue("nombre_institucion", "");
                    handleChange(e);
                  }}
                >
                  <option value="">-- Selecciona el tipo de grupo--</option>
                  <option key="1" value="Particular">
                    Particular
                  </option>
                  <option key="2" value="Institucional">
                    Institucional
                  </option>
                </FormRae.Select>
              </FormRae.Group>
            </div>
            <ErrorMessage
              name="tipo_grupo"
              className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
              component={"div"}
            />

            {values.tipo_grupo == "Institucional" ? (
              <>
                <FormRae.Group controlId="nombre_institucion" className="">
                  <FormRae.Label>* Nombre de la institución</FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="nombre_institucion"
                    value={values.nombre_institucion || ""}
                    onChange={handleChange}
                  />
                </FormRae.Group>
                <ErrorMessage
                  name="nombre_institucion"
                  className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                  component={"div"}
                />
              </>
            ) : (
              ""
            )}

            <FormRae.Group controlId="numero_hom" className="">
              <FormRae.Label>* Número de Hombres</FormRae.Label>
              <FormRae.Control
                type="text"
                name="numero_hom"
                value={values.numero_hom || ""}
                onChange={(e) =>
                  setFieldValue(
                    "numero_hom",
                    e.target.value.replaceAll(/[A-Za-z]/g, "")
                  )
                }
                maxLength={2}
              />
            </FormRae.Group>
            <ErrorMessage
              name="numero_hom"
              className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
              component={"div"}
            />

            <FormRae.Group controlId="numero_muj" className="">
              <FormRae.Label>* Número de Mujeres</FormRae.Label>
              <FormRae.Control
                type="text"
                name="numero_muj"
                value={values.numero_muj || ""}
                onChange={(e) =>
                  setFieldValue(
                    "numero_muj",
                    e.target.value.replaceAll(/[A-Za-z]/g, "")
                  )
                }
                maxLength={2}
              />
            </FormRae.Group>
            <ErrorMessage
              name="numero_muj"
              className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
              component={"div"}
            />

            <FormRae.Group controlId="codigo_postal" className="mb-1">
              <FormRae.Label>Código postal</FormRae.Label>
              <FormRae.Control
                type="text"
                name="codigo_postal"
                value={values.codigo_postal || ""}
                disabled
              />
            </FormRae.Group>

            <FormRae.Group controlId="id_pais" className="mb-1">
              <FormRae.Label>País</FormRae.Label>
              <FormRae.Control
                type="text"
                name="id_pais"
                value={values.id_pais || ""}
                disabled
              />
            </FormRae.Group>

            {values.id_estado ? (
              <>
                <FormRae.Group controlId="id_estado" className="mb-1">
                  <FormRae.Label>Estado</FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="id_estado"
                    value={values.id_estado || ""}
                    disabled
                  />
                </FormRae.Group>

                <FormRae.Group controlId="id_municipio" className="mb-1">
                  <FormRae.Label> Municipio</FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="id_municipio"
                    value={values.id_municipio || ""}
                    disabled
                  />
                </FormRae.Group>

                <FormRae.Group controlId="id_colonia" className="mb-1">
                  <FormRae.Label> Colonia o Alcaldía</FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="id_colonia"
                    value={values.id_colonia || ""}
                    disabled
                  />
                </FormRae.Group>
              </>
            ) : (
              <>
                <FormRae.Group controlId="n_estado" className="mb-1">
                  <FormRae.Label>Estado</FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="n_estado"
                    value={values.n_estado || ""}
                    disabled
                  />
                </FormRae.Group>

                <FormRae.Group controlId="n_municipio" className="mb-1">
                  <FormRae.Label> Municipio</FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="n_municipio"
                    value={values.n_municipio || ""}
                    disabled
                  />
                </FormRae.Group>

                <FormRae.Group controlId="n_colonia" className="mb-1">
                  <FormRae.Label> Colonia o Alcaldía</FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="n_colonia"
                    value={values.n_colonia || ""}
                    disabled
                  />
                </FormRae.Group>
              </>
            )}

            <FormRae.Group controlId="calle_num" className="mb-1">
              <FormRae.Label>Calle y número</FormRae.Label>
              <FormRae.Control
                type="text"
                name="calle_num"
                value={values.calle_num || ""}
                onChange={handleChange}
                disabled
              />
            </FormRae.Group>

            <FormRae.Group controlId="grado_escolar" className="w-100">
              <FormRae.Label>* Grado Escolar</FormRae.Label>
              <FormRae.Select
                name="grado_escolar"
                as="select"
                value={values.grado_escolar || ""}
                onChange={handleChange}
              >
                <option value="">-- Selecciona el tipo de grupo--</option>
                <option key="1" value="Prescolar">
                  Prescolar
                </option>
                <option key="2" value="1° Primaria">
                  1° Primaria
                </option>
                <option key="3" value="2° Primaria">
                  2° Primaria
                </option>
                <option key="4" value="3° Primaria">
                  3° Primaria
                </option>
                <option key="5" value="4° Primaria">
                  4° Primaria
                </option>
                <option key="6" value="5° Primaria">
                  5° Primaria
                </option>
                <option key="7" value="6° Primaria">
                  6° Primaria
                </option>
                <option key="8" value="1° Secundaria">
                  1° Secundaria
                </option>
                <option key="9" value="2° Secundaria">
                  2° Secundaria
                </option>
                <option key="10" value="3° Secundaria">
                  3° Secundaria
                </option>
                <option key="11" value="1° Preparatoria">
                  1° Preparatoria
                </option>
                <option key="12" value="2° Preparatoria">
                  2° Preparatoria
                </option>
                <option key="13" value="3° Preparatoria">
                  3° Preparatoria
                </option>
                <option key="14" value="Licenciatura">
                  Licenciatura
                </option>
                <option key="15" value="Posgrado">
                  Posgrado
                </option>
                <option key="16" value="Grupo heterogéneo">
                  Grupo heterogéneo
                </option>
                <option key="17" value="NO APLICA">
                  NO APLICA
                </option>
              </FormRae.Select>
            </FormRae.Group>
            <ErrorMessage
              name="grado_escolar"
              className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
              component={"div"}
            />

            <FormRae.Group controlId="expectativas" className="mb-3 mt-1">
              <FormRae.Label>Intereses y Expectativas</FormRae.Label>
              <FormRae.Control
                as="textarea"
                type="text"
                name="expectativas"
                style={{ height: "150px" }}
                placeholder="Expectativas del grupo"
                disabled
                value={values.expectativas || ""}
              />
            </FormRae.Group>

            <Button variant="primary" type="submit">
              Actualizar
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormGrupo;
