import { useEffect, useState } from 'react'
import { Button, CardGroup } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

import rae from "../../../Complementos/Img/bienvenidaRae.jpg";

import agn_2 from "../../../Complementos/Img/agn_b2.jpg";
import agn_3 from "../../../Complementos/Img/agn_b3.jpg";
import agn_4 from "../../../Complementos/Img/agn_b4.jpg";


import rae_1 from "../../../Complementos/img _bienvenida/rae_1.jpg";
import rae_2 from "../../../Complementos/img _bienvenida/rae_2.jpg";
import rae_3 from "../../../Complementos/img _bienvenida/rae_3.jpg";
import rae_4 from "../../../Complementos/img _bienvenida/rae_4.jpg";
import rae_5 from "../../../Complementos/img _bienvenida/rae_5.jpg";
import rae_6 from "../../../Complementos/img _bienvenida/rae_6.jpg";
import rae_7 from "../../../Complementos/img _bienvenida/rae_7.jpg";
import rae_8 from "../../../Complementos/img _bienvenida/rae_8.jpg";
import rae_9 from "../../../Complementos/img _bienvenida/rae_9.jpg";
import rae_10 from "../../../Complementos/img _bienvenida/rae_10.jpg";
import rae_11 from "../../../Complementos/img _bienvenida/rae_11.jpg";
import rae_12 from "../../../Complementos/img _bienvenida/rae_12.jpg";
import rae_13 from "../../../Complementos/img _bienvenida/rae_13.jpg";



import { Link } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';



const PageBienvenidos = () => {

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    return () => {
      window.document.title = 'Reservaciones | AGN'
    }
  }, [])
  return (
    <div className=''>
      <div className="imgs d-block  img-fluid mb-3 h-100 bg-secondary" style={{ minWidth: '200px', maxHeight: "500px", minHeight: '250px', }}>

        <Carousel fade className=' w-md-100'>
          <Carousel.Item>
            <img
              className="d-block m-auto "
              style={{ maxWidth: "100%", maxHeight: "500px", minHeight: '250px' }}
              src={rae_8}
              alt="First slide"
            />
            <Carousel.Caption>

            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block m-auto"
              style={{ maxWidth: "100%", maxHeight: "500px", minHeight: '250px' }}
              src={rae_6}
              alt="Second slide"
            />
            <Carousel.Caption>

            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block m-auto"
              style={{ maxWidth: "100%", maxHeight: "500px", minHeight: '250px' }}
              src={rae_7}
              alt="Third slide"
            />
            <Carousel.Caption>

            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block m-auto"
              style={{ maxWidth: "100%", maxHeight: "500px", minHeight: '250px' }}
              src={rae_12}
              alt="Third slide"
            />
            <Carousel.Caption>

            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

      </div>
      <div className='text-center p-2'>
        <small className="text-muted"><h2> ¡ Reserva aquí ! </h2></small>
      </div>

      <div className='text-center mb-3 ' >
        <Link to="/form" className="">
          <Button variant="danger" id="btnEnv" style={{ fontSize: '20px' }}>
            Reservar
          </Button>
        </Link>
      </div>

      {/*  <div className='d-flex mx-5 mt-5'>
        <small className="text-muted"><h2> Cuentacuentos </h2></small>
      </div>
      <hr className="my-3 mx-5 gob" />
 */}
      <div className='px-5 g-3'>
        <CardGroup className='g-5'>
          <Card className=' cards mx-2 boder rounded-3'>
            <Card.Img variant="top" src={rae_9} />
            <Card.Body>
              <Card.Text className='txt_aviso'>
                Recorre los espacios expositivos de esta casa y conoce algunos de los documentos más destacados que resguardamos.
                A lo largo de tu viaje podrás comprender el significado del patrimonio documental para la memoria histórica nacional y del mundo entero.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
             {/*  <small className="text-muted">Archivo General de la Nación | 03 de noviembre de 2022</small> */}
            </Card.Footer>
          </Card>
          <Card className='g-5 cards mx-2 boder rounded-3'>
            <Card.Img variant="top" src={rae_5} />
            <Card.Body>
              <Card.Text className='txt_aviso'>
                En esta casa de la memoria histórica y la labor archivística los documentos cobran vida para compartir contigo sus secretos y experiencias.
                Atrévete a conocerlos y escucharlos de viva voz.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
             {/*  <small className="text-muted">Archivo General de la Nación | 03 de noviembre de 2022</small> */}
            </Card.Footer>
          </Card>
          <Card className='g-5 cards mx-2 boder rounded-3'>
            <Card.Img variant="top" src={rae_11} />
            <Card.Body>

              <Card.Text className='txt_aviso'>
                Descubre las riquezas documentales que se encuentran en los fondos reservados de la Biblioteca
                – Hemeroteca Ignacio Cubas, con la guía de sus más destacados guardianes para viajar en este gran territorio documental.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
             {/*  <small className="text-muted">Archivo General de la Nación | 03 de noviembre de 2022</small> */}
            </Card.Footer>
          </Card>
        </CardGroup>
      </div>
      <br />

      <div className='px-5 g-5'>
        <CardGroup className='g-5'>
          <Card className='g-5 cards mx-2 boder rounded-3'>
            <Card.Img variant="top" src={rae_1} />
            <Card.Body>

              <Card.Text className='txt_aviso'>
                Explora los rincones de la casa de la archivística y la memoria histórica. Descubre los secretos y maravillas que se encuentran en su interior.
                En esta casa puedes aprender mientras te diviertes.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              {/* <small className="text-muted">Archivo General de la Nación | 03 de noviembre de 2022</small> */}
            </Card.Footer>
          </Card>
          <Card className='g-5 cards mx-2 boder rounded-3'>
            <Card.Img variant="top" src={rae_3} />
            <Card.Body>
              <Card.Text className='txt_aviso'>
                Acércate y experimenta la manera en que puedes explorar el patrimonio documental,
                manipularlo de manera adecuada y contribuir a que siga disponible para las generaciones presentes y futuras.
              </Card.Text>
            </Card.Body>
            <Card.Footer>{/* 
              <small className="text-muted">Archivo General de la Nación | 03 de noviembre de 2022</small> */}
            </Card.Footer>
          </Card>
          <Card className='g-5 cards mx-2 boder rounded-3'>
            <Card.Img variant="top" src={rae_2} />
            <Card.Body>

              <Card.Text className='txt_aviso'>
                El Archivo General de la Nación es tu casa de la memoria histórica y de la labor archivística.
                Atrévete a explorarla, a descubrir, a divertirte y a aprender acerca del maravilloso mundo del patrimonio documental.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
             {/*  <small className="text-muted">Archivo General de la Nación | 03 de noviembre de 2022</small> */}
            </Card.Footer>
          </Card>
        </CardGroup>

      </div>


      <br />
      <br />
    </div>
  )
}

export default PageBienvenidos