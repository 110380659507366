import React, { useState } from 'react'
import { ReservacionesRae, ConfirmacionReservacion, DiasRally, EmailProps } from '../Interfaces/InterfaceReservacionesRally';
import clienteAxios from '../../../Config/Axios';
import Swal from 'sweetalert2';
import { ResponseDataSwet } from '../../../InterfacesGlobales/InterfaceResponseSweet';
import { format } from 'date-fns';
import { ResponseData } from '../../../InterfacesGlobales/InterfaceResponse';
import { AlertaRae } from '../../../Components/Alertas/AlertaToastify';
import CryptoJS from 'crypto-js';

const UseReservacionesRally = () => {

    const [reservacionesRally, setReservacionesRally] = useState<ReservacionesRae | undefined | any>(undefined)

    const [horarioRally, SetHorarioRally] = useState<DiasRally | undefined>(undefined)
    const [diasRally, SetDiasRally] = useState<DiasRally[]>([])

    // Decrypt
    const Decrypt = (texto: string) => {

        const enDes = CryptoJS.AES.decrypt(texto, process.env.REACT_APP_CLAVE_ENCRIPT!);
        const enDeci = CryptoJS.enc.Utf8.stringify(enDes);
        return enDeci;
    }


    const reservacionesRallyGet = async (fecha_inicio: Date | string | undefined) => {
        const obtenerReservaciones = await clienteAxios.get<ReservacionesRae | any>(`/reservaciones/reservacionesrally/${fecha_inicio}`)

        const arreglo = obtenerReservaciones.data;
        if (arreglo) {
            for (const i in arreglo) {
                arreglo[i].grupo.calle_num = Decrypt(arreglo[i].grupo.calle_num!)

                arreglo[i].responsable.nombre = Decrypt(arreglo[i].responsable?.nombre!)
                arreglo[i].responsable.ap_paterno = Decrypt(arreglo[i].responsable?.ap_paterno!)
                arreglo[i].responsable.ap_materno = Decrypt(arreglo[i].responsable?.ap_materno!)
                arreglo[i].responsable.correo_elec = Decrypt(arreglo[i].responsable?.correo_elec!)
                arreglo[i].responsable.telefono = Decrypt(arreglo[i].responsable?.telefono!)
            }
        }
        setReservacionesRally(obtenerReservaciones.data)
    }


    const confirmacionReservacionRallyPost = async (values: ConfirmacionReservacion) => {
        const citaPost = await clienteAxios.post<ResponseDataSwet>("/reservaciones/confirmacionreservacionrally", values).catch(err => err.response)

        await Swal.fire({
            title: citaPost?.data.msg,
            icon: citaPost?.data.styles,
            showDenyButton: false,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#B38E5D',
            buttonsStyling: true,
            color: 'white',
            background: '#13322B'
        })
    }

    const enviarEmailConfirmacion = async (values: ReservacionesRae, num_grupo_rally: string | number) => {
        const emailPost = await clienteAxios.post("/reservaciones/emailconfirmacionrally", {
            folio_reservacion: values.folio_reservacion,
            correo_elect: values.responsable!.correo_elec,
            nombre: values.responsable!.nombre,
            ap_paterno: values.responsable!.ap_paterno,
            ap_materno: values.responsable!.ap_materno,
            fecha_actividad: values.fecha_actividad,
            horario_inicio: values.horario_inicio,
            horario_fin: values.horario_fin,
            num_grupo_rally: num_grupo_rally
        }).catch(err => err.response)

    }


    const obtenerHorariosRallyGet = async (fecha_inicio: Date | string | undefined) => {
        const horariosRally = await clienteAxios.get<DiasRally>(`/diasrally/${fecha_inicio}`);
        const obj = horariosRally.data

        console.log(horariosRally)

        for (const object in obj) {
            const horario_inicio = new Date(obj.horario_inicio!.toString().split(".")[0])
            obj.horario_inicio = format(new Date(horario_inicio), 'yyyy/MM/dd HH:mm:ss')

            const horario_fin = new Date(obj.horario_fin!.toString().split(".")[0])
            obj.horario_fin = format(new Date(horario_fin), 'yyyy/MM/dd HH:mm:ss')
        }

        SetHorarioRally(horariosRally.data);
    }

    const obtenerDiasRallyGet = async () => {
        const diasRally = await clienteAxios.get('/diasrally/dias');

        SetDiasRally(diasRally.data);
    }

    const actualizarReservacion = async (values: ReservacionesRae) => {
        const res = await clienteAxios.post<ResponseData>("/reservaciones/actreservacion", values).catch(err => err.response)
        AlertaRae(res?.data.styles, res?.data.msg)

    }

    const cancelarReservacion = async (values: ReservacionesRae ) => {

        const res = await clienteAxios.post<ResponseDataSwet>("/reservaciones/cancelareservacionrally", values).catch(err => err.response)
        await Swal.fire({
            title: res?.data.msg,
            icon: res?.data.styles,
            showDenyButton: false,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#B38E5D',
            buttonsStyling: true,
            color: 'white',
            background: '#13322B'
        })
    }

    const cancelarReservacionEmail = async (values: EmailProps ) => {

        const res = await clienteAxios.post<ResponseData>("/reservaciones/cancelareservacion", values).catch(err => err.response)
      
    }




    return {
        confirmacionReservacionRallyPost, enviarEmailConfirmacion,

        diasRally, obtenerDiasRallyGet,
        horarioRally, obtenerHorariosRallyGet,
        reservacionesRally, reservacionesRallyGet,

        actualizarReservacion,

        cancelarReservacion,
        cancelarReservacionEmail
    }
}

export default UseReservacionesRally