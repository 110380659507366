import { useNavigate } from "react-router-dom"


import { Form, Formik } from 'formik';

import { default as FormRae } from 'react-bootstrap/Form'
import { LoginForm } from '../Interfaces/InterfaceLogin';
import BtnSubmit from "../../../Components/BtnSubmit";
import { useState } from "react";
import useLogin from '../Hooks/useLogin';


const PageLogin = () => {
  const Navigate = useNavigate();
  const { iniciarSesion } = useLogin()
  const [cargando, setCargando] = useState(false)

  const variablesForm: LoginForm = {
    usuario: '',
    password: '',
  }

  return (


    <div>
      <br />
      <section className="">
        <div className="container h-100" >
          <div className="row justify-content-sm-center h-100" >
            <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">

              <div className="card shadow-lg" id="login">
                <div className="card-body p-5">
                  <h1 className="fs-4 card-title fw-bold mb-4">Gestor Administrativo</h1>

                  <Formik
                    initialValues={variablesForm}

                    onSubmit={ async(values) => {
                      setCargando(true)

                      const resPost = await iniciarSesion(values)
                      setCargando(false)
                      if (!resPost) return 
                      localStorage.setItem("Token", resPost.extras!.token);
                      Navigate("/admin")
                      
                      
                    }}

                  >

                    {({ handleChange }) => (

                      
                      <Form className="needs-validation" autoComplete="off">
                        
                        <FormRae.Group controlId="usuario" className="mb-3">
                          <FormRae.Label>Usuario</FormRae.Label>
                          <FormRae.Control
                            type="text"
                            name="usuario"
                            onChange={handleChange}
                            placeholder='Ingresa tú usuario'
                          />
                        </FormRae.Group>

                        <FormRae.Group controlId="password">
                          <FormRae.Label>Contraseña</FormRae.Label>
                          <FormRae.Control
                            type="password"
                            name="password"
                            onChange={handleChange}
                            placeholder='Ingresa tú contraseña'
                          />
                        </FormRae.Group>
                        <br />


                        <BtnSubmit cargando={cargando} texto="Ingresar" />


                      </Form>
                    )}
                  </Formik>


                </div>
                <div className="card-footer py-3 border-0 bg-warning">
                  <div className="text-center ">
                    Archivo General de la Nación
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}

export default PageLogin