import clienteAxios from "./Axios";

interface Params {
    token?: string | null,
    tipo_usuario: 1 | 2
}

export const loginHeader = async ({token, tipo_usuario }: Params ) => {

    if (token) {
        clienteAxios.defaults.headers.common['x-login-token'] = token
    } else {
        delete clienteAxios.defaults.headers.common['x-login-token'];
    }
    
    if(tipo_usuario){
        clienteAxios.defaults.headers.common['tipo-usuario'] = tipo_usuario
    }else{
        delete clienteAxios.defaults.headers.common['tipo-usuario'];
    }

}
