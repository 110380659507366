import React, { useState } from 'react'
import { AlertaRae } from '../../../Components/Alertas/AlertaToastify'
import clienteAxios from '../../../Config/Axios'
import { ResponseData } from '../../../InterfacesGlobales/InterfaceResponse'
import { DiasFestivosProps } from '../Interfaces/InterfaceCatalogos'




const useDiasFestivos = () => {
    
    const [diasFestivos, setDiasFestivos] = useState<DiasFestivosProps[]>([])
    const [diasFestivosAdmin, setDiasFestivosAdmin] = useState<DiasFestivosProps[]>([])


    //-------Peticiones para el sevidor 

    const diasFestivosGet = async () => {
        const obtenerDiasFestivos = await clienteAxios.get<DiasFestivosProps[]>('/diasfestivos');
        setDiasFestivos( obtenerDiasFestivos.data)
    }
    
    const diasFestivosGetAdmin = async () => {
        const obtenerDiasFestivos = await clienteAxios.get<DiasFestivosProps[]>('/diasfestivos/diasfestivosadmin');
        setDiasFestivosAdmin( obtenerDiasFestivos.data)
    }

   const diasfestivosPost = async (values: DiasFestivosProps) => {
        const res = await clienteAxios.post<ResponseData>("/diasfestivos", values).catch(err => err.response)
        AlertaRae(res?.data.styles, res?.data.msg)

    } 
      
    return {
        diasFestivos, diasFestivosGet, diasfestivosPost, diasFestivosGetAdmin, diasFestivosAdmin
    }
}

export default useDiasFestivos