import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import TablaRae from '../../../Components/Tablas/TablaRae'
import { default as FormRae } from 'react-bootstrap/Form'
import { DiasFestivosProps } from '../Interfaces/InterfaceCatalogos'
import useDiasFestivos from '../Hooks/UseDiasFestivos'

import * as Yup from 'yup'
import ReactDatePicker from 'react-datepicker'
import { addMonths, parseISO, getDay } from 'date-fns';
import { format } from 'date-fns/esm'

import { ContextApp } from '../../../Context/StateApp';
import { useContext } from 'react'
import { AlertaRae } from '../../../Components/Alertas/AlertaToastify';

interface Props {
    registro_Seleccionado: DiasFestivosProps,
    returnSubmitSuccess: () => void,
    cerrarModalForm: () => void,
}

const FormDiasFestivos = ({ registro_Seleccionado, returnSubmitSuccess, cerrarModalForm }: Props) => {

    const { diasFestivosGet, diasfestivosPost } = useDiasFestivos()
    const { state: { usuario_logeado, logeado } } = useContext(ContextApp)

    const [diaSelect, setDiaSelect] = useState<DiasFestivosProps>({
        id_dia: undefined,
        nombre_dia: '',
        fecha_inicio: undefined,
        fecha_fin: undefined,
        usuario_log: usuario_logeado?.id_mediador,
    })
    console.log(diaSelect)
    useEffect(() => {

        if (registro_Seleccionado) {

            setDiaSelect({
                id_dia: registro_Seleccionado.id_dia ? registro_Seleccionado.id_dia : 0,
                nombre_dia: registro_Seleccionado.nombre_dia ? registro_Seleccionado.nombre_dia : '',
                fecha_inicio: registro_Seleccionado.fecha_inicio ? parseISO(registro_Seleccionado.fecha_inicio) : undefined,
                fecha_fin: registro_Seleccionado.fecha_fin ? parseISO(registro_Seleccionado.fecha_fin) : undefined,
                usuario_log: usuario_logeado?.id_mediador,
            })
        }

    }, [registro_Seleccionado])


    const validaciones = Yup.object().shape({
        nombre_dia: Yup.string().required('Este campo es obligatorio'),
        fecha_inicio: Yup.string().required('Este campo es obligatorio'),
        fecha_fin: Yup.string().required('Este campo es obligatorio'),
    })

    const isWeekday = (date: any) => {
        const day = getDay(date);
        return day !== 0;
    };

    return (
        <div>
            <Formik
                initialValues={diaSelect}
                onSubmit={
                    async (values) => {
                        const object = {
                            id_dia: values.id_dia,
                            nombre_dia: values.nombre_dia,
                            fecha_inicio: format(new Date(values.fecha_inicio), "yyyy-MM-dd"),
                            fecha_fin: format(new Date(values.fecha_fin), "yyyy-MM-dd"),
                            usuario_logeado: usuario_logeado?.id_mediador,
                        }

                        if (values.fecha_fin < values.fecha_inicio) {
                            console.log('Entro');
                            AlertaRae("danger", "La fecha de inicio no puede ser menor")
                        } else {

                           await diasfestivosPost(object)
                            returnSubmitSuccess() 
                        }
                    }}
                enableReinitialize //Importante atributo para reiniciar el componente
                validationSchema={validaciones}
            >

                {({ handleChange, values, setFieldValue }) => (
                    <Form  >
                        <FormRae.Group controlId="id_dia" className="mb-3">
                            <FormRae.Label>Número</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="id_dia"
                                placeholder='Número día'
                                onChange={handleChange}
                                disabled
                                value={values.id_dia || undefined}
                            />
                        </FormRae.Group>

                        <FormRae.Group controlId="nombredia" className="mb-3">
                            <FormRae.Label>Descripción</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="nombre_dia"
                                placeholder='Escribe la descripción del día'
                                onChange={handleChange}
                                value={values.nombre_dia || ''}
                            />
                            <ErrorMessage name='nombre_dia' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                        </FormRae.Group>

                        <FormRae.Group controlId="fecha_inicio" className="mb-3">
                            <FormRae.Label>Fecha de inicio</FormRae.Label>


                            <ReactDatePicker
                                selected={values.fecha_inicio || undefined}
                                name="fecha_inicio"
                                onChange={
                                    (date) => {
                                        console.log(date);
                                        setFieldValue("fecha_inicio", date)
                                    }}
                                minDate={new Date()}
                                maxDate={addMonths(new Date(), 12)}
                                startDate={values.fecha_inicio}
                                endDate={values.fecha_fin}
                                selectsStart


                                locale="es"
                                placeholderText='-- Selecciona una fecha --'
                                className='w-100 rounded-2  form-control '
                                dayClassName={(date: Date) => ' rounded-4 '}
                                useWeekdaysShort
                                useShortMonthInDropdown
                                dateFormat={'yyyy-MM-dd'}
                                filterDate={isWeekday}
                            />


                            <ErrorMessage name='fecha_inicio' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                        </FormRae.Group>

                        <FormRae.Group controlId="fecha_fin" className="mb-3">
                            <FormRae.Label>Fecha de término</FormRae.Label>
                            {/* 
                            <ReactDatePicker
                                selected={values.fecha_fin || ''}
                                name="fecha_fin"
                                onChange={(e: any) => {
                                    setFieldValue("fecha_fin", format(new Date(e), 'yyyy-MM-dd'))
                                }}
                                minDate={new Date()}
                                maxDate={addMonths(new Date(), 12)}
                                startDate={values.fecha_inicio}
                                endDate={values.fecha_fin}
                                selectsEnd

                                showDisabledMonthNavigation
                                locale="es"
                                placeholderText='-- Selecciona una fecha --'
                                className='w-100 rounded-2  form-control '
                                dayClassName={(date: Date) => ' rounded-4 '}
                                useWeekdaysShort
                                useShortMonthInDropdown
                                dateFormat={'yyyy-MM-dd'}
                            /> */}
                            <ReactDatePicker
                                selected={values.fecha_fin || undefined}
                                name="fecha_fin"
                                onChange={date => {
                                    setFieldValue("fecha_fin", date)
                                }}
                                minDate={new Date()}
                                maxDate={addMonths(new Date(), 12)}
                                startDate={values.fecha_inicio}
                                endDate={values.fecha_fin}
                                selectsEnd

                                showDisabledMonthNavigation
                                locale="es"
                                placeholderText='-- Selecciona una fecha --'
                                className='w-100 rounded-2  form-control '
                                dayClassName={(date: Date) => ' rounded-4 '}
                                useWeekdaysShort
                                useShortMonthInDropdown
                                dateFormat={'yyyy-MM-dd'}
                                filterDate={isWeekday}

                            />

                            <ErrorMessage name='fecha_fin' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                        </FormRae.Group>

                        <TablaRae.ModalFooter>

                            <Button variant="primary"
                                type="submit"
                            >
                                {registro_Seleccionado ? "Actualizar" : "Guardar"}
                            </Button>
                            <Button variant="primary" onClick={cerrarModalForm} >
                                Cerrar
                            </Button>

                        </TablaRae.ModalFooter>
                    </Form>
                )}
            </Formik>


        </div >
    )
}

export default FormDiasFestivos