import { PDFDownloadLink } from '@react-pdf/renderer'
import ReporteUsuarios from '../Components/Reportes/ReporteUsuarios'
import TablaUsuarios from '../Components/TablaUsuarios'
import UseUsuarios from '../Hooks/UseUsuarios'
import { useEffect } from 'react'

const PageUsuarios = () => {
    const { mediadoresGet, mediadores } = UseUsuarios()
    useEffect(() => {
        // Ejecutar cualquier lógica adicional cuando mediadores se actualice
        // Puedes añadir cualquier acción aquí si es necesario
        mediadoresGet()
    }, [])
    return (
        <div className="">
            <hr className="my-1 gob" />
            <div className="text-center">
                <PDFDownloadLink
                    document={<ReporteUsuarios usuarios={mediadores} />}
                    fileName="reporte_usuarios.pdf"
                    className="btn btn-info"
                >
                    {({ blob, url, loading, error }) =>
                        loading ? 'Generando PDF...' : 'Descargar reporte '
                    }
                </PDFDownloadLink>
            </div>

            <TablaUsuarios />
        </div>
    )
}

export default PageUsuarios
