import {
    Document,
    Font,
    Image,
    Page,
    StyleSheet,
    Text,
    View
} from '@react-pdf/renderer'
import source from '../../../../Complementos/fuentes/Montserrat-Regular.ttf'
import source_2 from '../../../../Complementos/fuentes/Montserrat-Bold.ttf'
import hoja_bot from '../../../../Complementos/Img/encabezado_bot.jpg'
import hoja_top from '../../../../Complementos/Img/encabezado_top.jpg'
import { MediadorRae } from '../../Interfaces/InterfaceMediadores'

interface propsPDF {
    usuarios: MediadorRae[]
}

const ReporteUsuarios = ({ usuarios }: propsPDF) => {
    Font.register({
        family: 'Montserrat',
        src: source,
        fontStyle: 'normal',
        fontWeight: 'normal'
    })

    Font.register({
        family: 'Montserrat-bold',
        src: source_2,
        fontStyle: 'normal',
        fontWeight: 'normal'
    })

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingBottom: '70px',
            fontFamily: 'Montserrat'
        },
        section: {
            margin: 10,
            padding: 10
        },
        container: {
            position: 'relative',
            backgroundColor: '#D35400'
        },

        img_encabezado: {
            top: 30,
            left: 30,
            width: '240px',
            height: '70px',
            position: 'absolute'
        },
        img_pie_pagina: {
            top: 720,
            left: 30,
            width: '550px',
            height: '60px',
            position: 'absolute'
        },
        section_direccion: {
            top: 62,
            left: 468,
            width: '112px',
            fontSize: '12px',
            position: 'absolute',
            fontWeight: 'bold',
            fontFamily: 'Montserrat-bold'
        },

        section_direccion_nom: {
            top: 78,
            left: 320,
            width: '260px',
            position: 'absolute',
            fontSize: '8px',
            fontFamily: 'Montserrat-bold'
        },

        section_subdireccion: {
            top: 90,
            left: 435,
            width: '145px',
            position: 'absolute',
            fontSize: '7px'
        },
        section_departamento: {
            top: 100,
            left: 435,
            width: '145px',
            position: 'absolute',
            fontSize: '7px'
        },

        section_titulo_usuarios: {
            top: 130,
            left: 160,
            width: '300px',
            position: 'absolute',
            fontSize: '15px',
            textAlign: 'center',
            fontFamily: 'Montserrat-bold'
        },

        /*-----------------------------Tabla de los módulos-------------*/
        tabla_titulo_modulos: {
            top: 180,
            left: 140,
            width: '300px',
            position: 'absolute',
            fontSize: '15px',
            textAlign: 'center'
        },

        tabla_header_numeroModulo: {
            top: 200,
            left: 160,
            width: '80px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_numModulo_uno: {
            top: 215,
            left: 160,
            width: '80px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_numModulo_dos: {
            top: 230,
            left: 160,
            width: '80px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_numModulo_tres: {
            top: 245,
            left: 160,
            width: '80px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_numModulo_cuatro: {
            top: 260,
            left: 160,
            width: '80px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_numModulo_cinco: {
            top: 275,
            left: 160,
            width: '80px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_numModulo_seis: {
            top: 290,
            left: 160,
            width: '80px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_numModulo_siete: {
            top: 305,
            left: 160,
            width: '80px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_numModulo_ocho: {
            top: 320,
            left: 160,
            width: '80px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_numModulo_nueve: {
            top: 335,
            left: 160,
            width: '80px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_numModulo_dies: {
            top: 350,
            left: 160,
            width: '80px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_numModulo_once: {
            top: 365,
            left: 160,
            width: '80px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_nombreModulo: {
            top: 200,
            left: 239,
            width: '200px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_nombre_uno: {
            top: 215,
            left: 239,
            width: '200px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_nombre_dos: {
            top: 230,
            left: 239,
            width: '200px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_nombre_tres: {
            top: 245,
            left: 239,
            width: '200px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_nombre_cuatro: {
            top: 260,
            left: 239,
            width: '200px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_nombre_cinco: {
            top: 275,
            left: 239,
            width: '200px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_nombre_seis: {
            top: 290,
            left: 239,
            width: '200px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_nombre_siete: {
            top: 305,
            left: 239,
            width: '200px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_nombre_ocho: {
            top: 320,
            left: 239,
            width: '200px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_nombre_nueve: {
            top: 335,
            left: 239,
            width: '200px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_nombre_dies: {
            top: 350,
            left: 239,
            width: '200px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        tabla_header_nombre_once: {
            top: 365,
            left: 239,
            width: '200px',
            position: 'absolute',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D'
        },

        /*------------------------Tabla Usuarios----------------------*/

        header_numero_usuario: {
            top: 200,
            left: 40,
            width: '55px',
            height: '39px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '10px'
        },

        header_nombre_usuario: {
            top: 200,
            left: 95,
            width: '180px',
            height: '39px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '10px'
        },

        header_estado: {
            top: 200,
            left: 275,
            width: '100px',
            height: '39px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '10px'
        },

        header_titulo_modulos: {
            top: 200,
            left: 374,
            width: '212px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        header_modulos_1: {
            top: 219,
            left: 374,
            width: '20px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        header_modulos_2: {
            top: 219,
            left: 393,
            width: '20px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        header_modulos_3: {
            top: 219,
            left: 412,
            width: '20px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        header_modulos_4: {
            top: 219,
            left: 431,
            width: '20px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        header_modulos_5: {
            top: 219,
            left: 450,
            width: '20px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        header_modulos_6: {
            top: 219,
            left: 469,
            width: '20px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        header_modulos_7: {
            top: 219,
            left: 488,
            width: '20px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        header_modulos_8: {
            top: 219,
            left: 507,
            width: '20px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        header_modulos_9: {
            top: 219,
            left: 526,
            width: '20px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },
        header_modulos_10: {
            top: 219,
            left: 545,
            width: '21px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        header_modulos_11: {
            top: 219,
            left: 565,
            width: '21px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        columnas: {
            top: 235,
            left: 40,
            height: '20px',
            flexDirection: 'row',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        column_num: {
            //top: 238,
            //left: 20,
            width: '55px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',

            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        column_usuario: {
            //top: 238,
            //left: 69,
            width: '180px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',

            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        column_estado: {
            /*  top: 238,
         left: 260, */
            width: '100px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: '#EBEDEF',
            border: '1px solid #B38E5D',

            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        column_modulos_1: {
            top: 3,
            left: 334,
            width: '20px',
            height: '20px',
            textAlign: 'center',

            border: '1px solid #B38E5D',
            position: 'absolute',
            paddingTop: '3px'
        },

        column_modulos_2: {
            top: 3,
            left: 353,
            width: '20px',
            height: '20px',
            textAlign: 'center',

            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        column_modulos_3: {
            top: 3,
            left: 372,
            width: '20px',
            height: '20px',
            textAlign: 'center',

            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        column_modulos_4: {
            top: 3,
            left: 391,
            width: '20px',
            height: '20px',
            textAlign: 'center',

            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        column_modulos_5: {
            top: 3,
            left: 410,
            width: '20px',
            height: '20px',
            textAlign: 'center',

            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        column_modulos_6: {
            top: 3,
            left: 429,
            width: '20px',
            height: '20px',
            textAlign: 'center',

            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        column_modulos_7: {
            top: 3,
            left: 448,
            width: '20px',
            height: '20px',
            textAlign: 'center',

            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        column_modulos_8: {
            top: 3,
            left: 467,
            width: '20px',
            height: '20px',
            textAlign: 'center',

            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        column_modulos_9: {
            top: 3,
            left: 486,
            width: '20px',
            height: '20px',
            textAlign: 'center',

            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        column_modulos_10: {
            top: 3,
            left: 505,
            width: '21px',
            height: '20px',
            textAlign: 'center',

            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        column_modulos_11: {
            top: 3,
            left: 525,
            width: '21px',
            height: '20px',
            textAlign: 'center',

            border: '1px solid #B38E5D',
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '3px'
        },

        img_modulos: {
            top: 500,
            left: 30,
            width: '10px',
            height: '10px',
            position: 'absolute'
        }
    })

    return (
        <Document>
            <Page size="LETTER" style={styles.page}>
                <Image source={hoja_top} style={styles.img_encabezado} fixed />

                <View style={styles.section_direccion} fixed>
                    <Text>Dirección General</Text>
                </View>
                <View style={styles.section_direccion_nom} fixed>
                    <Text>
                        Dirección de Tecnologías de la Información y
                        Comunicaciones
                    </Text>
                </View>
                <View style={styles.section_subdireccion} fixed>
                    <Text>Subdirección de Sistemas de Información</Text>
                </View>
                <View style={styles.section_departamento} fixed>
                    <Text>Departamento de Desarrollo de Sistemas</Text>
                </View>

                <View style={styles.section_titulo_usuarios} fixed>
                    <Text>
                        Usuarios del Sistema de Reservaciones de Actvides
                        Educativas
                    </Text>
                </View>

                <View style={styles.header_numero_usuario}>
                    <Text>Número</Text>
                </View>
                <View style={styles.header_nombre_usuario}>
                    <Text>Nombre usuario</Text>
                </View>
                <View style={styles.header_estado}>
                    <Text>Estado</Text>
                </View>
                <View style={styles.header_titulo_modulos}>
                    <Text>Módulos</Text>
                </View>
                <View style={styles.header_modulos_1}>
                    <Text>1</Text>
                </View>
                <View style={styles.header_modulos_2}>
                    <Text>2</Text>
                </View>
                <View style={styles.header_modulos_3}>
                    <Text>3</Text>
                </View>
                <View style={styles.header_modulos_4}>
                    <Text>4</Text>
                </View>
                <View style={styles.header_modulos_5}>
                    <Text>5</Text>
                </View>
                <View style={styles.header_modulos_6}>
                    <Text>6</Text>
                </View>
                <View style={styles.header_modulos_7}>
                    <Text>7</Text>
                </View>
                <View style={styles.header_modulos_8}>
                    <Text>8</Text>
                </View>
                <View style={styles.header_modulos_9}>
                    <Text>9</Text>
                </View>
                <View style={styles.header_modulos_10}>
                    <Text>10</Text>
                </View>
                <View style={styles.header_modulos_11}>
                    <Text>11</Text>
                </View>

                {usuarios.map((usuario: MediadorRae, index) => (
                    <View style={styles.columnas} key={index} wrap={false}>
                        <Text style={styles.column_num} key={index}>
                            {usuario.usuariorae?.id_usuario}
                        </Text>

                        <Text
                            style={styles.column_usuario}
                            key={usuario.usuariorae?.usuario}
                        >
                            {usuario.usuariorae?.usuario}
                        </Text>
                        <Text style={styles.column_estado}>
                            {usuario.estatus === 1
                                ? 'Habilitado'
                                : 'No Habilitado'}
                        </Text>

                        <Text style={styles.column_modulos_1}>
                            {usuario.usuariorae?.modulos?.length! > 0
                                ? usuario.usuariorae?.modulos!.find(
                                      (Modulo) =>
                                          Modulo.id_modulo! === 1 &&
                                          Modulo.estado! === 1
                                  )
                                    ? 'x'
                                    : ''
                                : ''}
                        </Text>
                        <Text style={styles.column_modulos_2}>
                            {usuario.usuariorae?.modulos?.length! > 0
                                ? usuario.usuariorae?.modulos!.find(
                                      (Modulo) =>
                                          Modulo.id_modulo! === 2 &&
                                          Modulo.estado! === 1
                                  )
                                    ? 'x'
                                    : ''
                                : ''}
                        </Text>
                        <Text style={styles.column_modulos_3}>
                            {usuario.usuariorae?.modulos?.length! > 0
                                ? usuario.usuariorae?.modulos!.find(
                                      (Modulo) =>
                                          Modulo.id_modulo! === 3 &&
                                          Modulo.estado! === 1
                                  )
                                    ? 'x'
                                    : ''
                                : ''}
                        </Text>
                        <Text style={styles.column_modulos_4}>
                            {usuario.usuariorae?.modulos?.length! > 0
                                ? usuario.usuariorae?.modulos!.find(
                                      (Modulo) =>
                                          Modulo.id_modulo! === 4 &&
                                          Modulo.estado! === 1
                                  )
                                    ? 'x'
                                    : ''
                                : ''}
                        </Text>
                        <Text style={styles.column_modulos_5}>
                            {usuario.usuariorae?.modulos?.length! > 0
                                ? usuario.usuariorae?.modulos!.find(
                                      (Modulo) =>
                                          Modulo.id_modulo! === 5 &&
                                          Modulo.estado! === 1
                                  )
                                    ? 'x'
                                    : ''
                                : ''}
                        </Text>
                        <Text style={styles.column_modulos_6}>
                            {usuario.usuariorae?.modulos?.length! > 0
                                ? usuario.usuariorae?.modulos!.find(
                                      (Modulo) =>
                                          Modulo.id_modulo! === 6 &&
                                          Modulo.estado! === 1
                                  )
                                    ? 'x'
                                    : ''
                                : ''}
                        </Text>
                        <Text style={styles.column_modulos_7}>
                            {usuario.usuariorae?.modulos?.length! > 0
                                ? usuario.usuariorae?.modulos!.find(
                                      (Modulo) =>
                                          Modulo.id_modulo! === 7 &&
                                          Modulo.estado! === 1
                                  )
                                    ? 'x'
                                    : ''
                                : ''}
                        </Text>
                        <Text style={styles.column_modulos_8}>
                            {usuario.usuariorae?.modulos?.length! > 0
                                ? usuario.usuariorae?.modulos!.find(
                                      (Modulo) =>
                                          Modulo.id_modulo! === 8 &&
                                          Modulo.estado! === 1
                                  )
                                    ? 'x'
                                    : ''
                                : ''}
                        </Text>
                        <Text style={styles.column_modulos_9}>
                            {usuario.usuariorae?.modulos?.length! > 0
                                ? usuario.usuariorae?.modulos!.find(
                                      (Modulo) =>
                                          Modulo.id_modulo! === 9 &&
                                          Modulo.estado! === 1
                                  )
                                    ? 'x'
                                    : ''
                                : ''}
                        </Text>

                        <Text style={styles.column_modulos_10}>
                            {usuario.usuariorae?.modulos?.length! > 0
                                ? usuario.usuariorae?.modulos!.find(
                                      (Modulo) =>
                                          Modulo.id_modulo! === 10 &&
                                          Modulo.estado! === 1
                                  )
                                    ? 'x'
                                    : ''
                                : ''}
                        </Text>
                        <Text style={styles.column_modulos_11}>
                            {usuario.usuariorae?.modulos?.length! > 0
                                ? usuario.usuariorae?.modulos!.find(
                                      (Modulo) =>
                                          Modulo.id_modulo! === 11 &&
                                          Modulo.estado! === 1
                                  )
                                    ? 'x'
                                    : ''
                                : ''}
                        </Text>
                    </View>
                ))}

                <Image source={hoja_bot} style={styles.img_pie_pagina} fixed />
            </Page>
        </Document>
    )
}

export default ReporteUsuarios
