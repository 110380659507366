import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";

import { default as FormRae } from "react-bootstrap/Form";
import {
  ReservacionesPropTabla,
  ReservacionesRae,
} from "../Interfaces/InterfaceReservacionesConfirmadas";

interface Props {
  registro_Seleccionado: ReservacionesRae | undefined;
  returnSubmitSuccess: () => void;
  cerrarModalForm: () => void;
}

const FormGrupo = ({
  cerrarModalForm,
  registro_Seleccionado,
  returnSubmitSuccess,
}: Props) => {
  const [mostraModal, setMostraModal] = useState(false);
  const [reservacionSelect, setReservacionSelect] = useState<
    ReservacionesRae | any
  >({
    grupo: {
      tipo_grupo: "",
      numero_hom: "",
      numero_muj: "",
      codigo_postal: "",
      id_pais: "",
      id_estado: "",
      id_municipio: "",
      id_colonia: "",
      calle_num: "",
      grado_escolar: "",
      num_grupo_rally: "",
      nombre_institucion: "",
      n_estado: "",
      n_municipio: "",
      n_colonia: "",
      expectativas: "",
    },
  });

  useEffect(() => {
    if (registro_Seleccionado) {
      setReservacionSelect({
        id_reservacion: registro_Seleccionado.id_reservacion,
        tipo_actividad: registro_Seleccionado.idactividad.id_actividad,

        tipo_grupo: registro_Seleccionado.grupo.tipo_grupo,
        nombre_institucion: registro_Seleccionado.grupo.nombre_institucion,
        numero_hom: registro_Seleccionado.grupo.numero_hom,
        numero_muj: registro_Seleccionado.grupo.numero_muj,
        codigo_postal: registro_Seleccionado.grupo.codigo_postal,
        sexo: registro_Seleccionado.grupo!.sexo
          ? registro_Seleccionado.grupo!.sexo
          : "",

        id_pais: registro_Seleccionado.grupo.pais
          ? registro_Seleccionado.grupo.pais.nombre_pais!
          : "",
        id_estado: registro_Seleccionado.grupo.estado
          ? registro_Seleccionado.grupo.estado.nombre_estado
          : "",
        id_municipio: registro_Seleccionado.grupo.municipio
          ? registro_Seleccionado.grupo.municipio.nombre_municipio
          : "",
        id_colonia: registro_Seleccionado.grupo.colonia
          ? registro_Seleccionado.grupo.colonia.nombre_colonia
          : "",
        calle_num: registro_Seleccionado.grupo.calle_num,
        grado_escolar: registro_Seleccionado.grupo.grado_escolar,
        n_estado: registro_Seleccionado.grupo.n_estado,
        n_municipio: registro_Seleccionado.grupo.n_municipio,
        n_colonia: registro_Seleccionado.grupo.n_colonia,

        expectativas: registro_Seleccionado.expectativas
          ? registro_Seleccionado.expectativas
          : "",
      });
    }
  }, [registro_Seleccionado]);

  return (
    <div>
      <Formik
        initialValues={reservacionSelect}
        onSubmit={async (values) => {
          console.log(values);
        }}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <FormRae.Group controlId="tipo_grupo" className="mb-1">
              <FormRae.Label>Tipo Grupo</FormRae.Label>
              <FormRae.Control
                type="text"
                name="tipo_grupo"
                value={values.tipo_grupo || ""}
                disabled
              />
            </FormRae.Group>

            {values.tipo_grupo == "Institucional" && (
              <FormRae.Group controlId="nombre_institucion" className="mb-1">
                <FormRae.Label>Nombre de la institución</FormRae.Label>
                <FormRae.Control
                  defaultValue={values.nombre_institucion || ""}
                  disabled
                />
              </FormRae.Group>
            )}

            {values.tipo_actividad == 6 && (
              <FormRae.Group controlId="sexo" className="mb-1">
                <FormRae.Label>Sexo</FormRae.Label>
                <FormRae.Control
                  type="text"
                  name="sexo"
                  value={values.sexo || ""}
                  disabled
                />
              </FormRae.Group>
            )}

            {values.tipo_actividad !== 6 && (
              <>
                <FormRae.Group controlId="numero_hom" className="mb-1">
                  <FormRae.Label>Número de Hombres</FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="numero_hom"
                    value={values.numero_hom || ""}
                    disabled
                  />
                </FormRae.Group>

                <FormRae.Group controlId="numero_muj" className="mb-1">
                  <FormRae.Label>Número de Mujeres</FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="numero_muj"
                    value={values.numero_muj || ""}
                    disabled
                  />
                </FormRae.Group>
              </>
            )}

            <FormRae.Group controlId="codigo_postal" className="mb-1">
              <FormRae.Label>Código postal</FormRae.Label>
              <FormRae.Control
                type="text"
                name="codigo_postal"
                value={values.codigo_postal || ""}
                disabled
              />
            </FormRae.Group>

            <FormRae.Group controlId="id_pais" className="mb-1">
              <FormRae.Label>País</FormRae.Label>
              <FormRae.Control
                type="text"
                name="id_pais"
                value={values.id_pais || ""}
                disabled
              />
            </FormRae.Group>

            {values.id_estado ? (
              <>
                <FormRae.Group controlId="id_estado" className="mb-1">
                  <FormRae.Label>Estado</FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="id_estado"
                    value={values.id_estado || ""}
                    disabled
                  />
                </FormRae.Group>

                <FormRae.Group controlId="id_municipio" className="mb-1">
                  <FormRae.Label> Municipio o Alcaldía </FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="id_municipio"
                    value={values.id_municipio || ""}
                    disabled
                  />
                </FormRae.Group>

                <FormRae.Group controlId="id_colonia" className="mb-1">
                  <FormRae.Label> Colonia </FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="id_colonia"
                    value={values.id_colonia || ""}
                    disabled
                  />
                </FormRae.Group>
              </>
            ) : (
              <>
                <FormRae.Group controlId="n_estado" className="mb-1">
                  <FormRae.Label>Estado</FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="n_estado"
                    value={values.n_estado || ""}
                    disabled
                  />
                </FormRae.Group>

                <FormRae.Group controlId="n_municipio" className="mb-1">
                  <FormRae.Label> Municipio</FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="n_municipio"
                    value={values.n_municipio || ""}
                    disabled
                  />
                </FormRae.Group>

                <FormRae.Group controlId="n_colonia" className="mb-1">
                  <FormRae.Label> Colonia o Alcaldía</FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="n_colonia"
                    value={values.n_colonia || ""}
                    disabled
                  />
                </FormRae.Group>
              </>
            )}
            <FormRae.Group controlId="calle_num" className="mb-1">
              <FormRae.Label>Calle y número</FormRae.Label>
              <FormRae.Control
                type="text"
                name="calle_num"
                value={values.calle_num || ""}
                disabled
              />
            </FormRae.Group>

            <FormRae.Group controlId="grado_escolar" className="mb-1">
              <FormRae.Label>Grado Escolar</FormRae.Label>
              <FormRae.Control
                type="text"
                name="grado_escolar"
                value={values.grado_escolar || ""}
                disabled
              />
            </FormRae.Group>

            <FormRae.Group controlId="expectativas" className="mb-3 mt-1">
              <FormRae.Label>Intereses y Expectativas</FormRae.Label>
              <FormRae.Control
                as="textarea"
                type="text"
                name="expectativas"
                style={{ height: "150px" }}
                placeholder="Expectativas del grupo"
                disabled
                value={values.expectativas || ""}
              />
            </FormRae.Group>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormGrupo;
