
import TablaTaller from '../Components/TablaTaller';


const PageTalleres = () => {

  return (
    <div>
      <hr className="my-1 gob"/>
      <TablaTaller />
    </div >
  )
}

export default PageTalleres