import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { TableColumn } from 'react-data-table-component'
import TablaRae from '../../../Components/Tablas/TablaRae'
import UseTalleres from '../Hooks/UseTalleres'
import { TalleresProps } from '../Interfaces/InterfaceTalleres'
import FormTaller from './FormTaller'
import { BiMessageSquareEdit } from "react-icons/bi";
import TooltipAgn from '../../../Components/Tooltips/TooltipAgn';

import CryptoJS from 'crypto-js';


const TablaTaller = () => {
    const { talleresAdmin, talleresGetAdmin } = UseTalleres()
    const [mostrarModal, setMostrarModal] = useState(false)
    const [registroSeleccionado, setRegistroSeleccionado] = useState<TalleresProps | any>({
        id_taller: '',
        nombre_t: '',
        estado: ''
    })

    useEffect(() => {
        talleresGetAdmin()
        setRegistroSeleccionado(false)
    }, [])


    const cerrarModal = () => {
        setMostrarModal(false)
        if (registroSeleccionado) {
            setRegistroSeleccionado(false)
        }
    }

    const columns: TableColumn<TalleresProps>[] = [
        {
            cell: (row) => (
                <div >
                    <Button variant='warning' onClick={() => {
                        setRegistroSeleccionado(row)
                        setMostrarModal(true)
                    }} >
                        <BiMessageSquareEdit size={'1.3rem'} className="text-light" />
                    </Button>
                </div>
            ),
            grow: 0.0

        },
        {
            name: "Número",
            selector: (row) => row.id_taller!,
            grow: 1,
            center: true,
        },
        {
            name: "Nombre",
            selector: (row) => row.nombre_t,
            grow: 7
        },
        {
            name: "Estado",

            cell: (row) => (
                <div>
                    {row.estado === 1 ? (
                        <div className="text-primary">
                            <h6> Habilitado </h6>
                        </div>
                    ) :
                        (
                            <div className="text-danger">
                                <h6> No habilitado </h6>
                            </div>
                        )
                    }
                </div>
            )
        }
    ]




    return (
        <div>
           {/*  <p data-for='prueba'> Pruebas </p>
            <TooltipAgn dataFor={'prueba'} /> */}

          


            <TablaRae
                columns={columns}
                data={talleresAdmin}
                onclicNuevo={() => { setMostrarModal(true) }}
                closeModal={cerrarModal}
                show_modal={mostrarModal}
                paginationPerPage={15}
            >

                <TablaRae.ModalHeader>{registroSeleccionado ? "Actualizar Taller" : "Nuevo Taller"} </TablaRae.ModalHeader>
                <TablaRae.ModalBody>
                    <FormTaller
                        registro_Seleccionado={registroSeleccionado}
                        returnSubmitSuccess={() => {
                            talleresGetAdmin()
                            cerrarModal()
                        }}
                        cerrarModalForm={cerrarModal}
                    />
                </TablaRae.ModalBody>


            </TablaRae>


        </div>
    )
}

export default TablaTaller