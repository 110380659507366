
import { useContext, useState, lazy, Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom'

import Placa from "../Complementos/Img/pleca_verde.svg";
import Logo from "../Complementos/Img/Logo_128x50.png";


import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button';


import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import PaginaBase from '../Components/PaginaBase';

import { ContextApp } from '../Context/StateApp';
import { BsFilterLeft } from "react-icons/bs"; //-------Menu

import { FiLogOut } from "react-icons/fi";
import { loginHeader } from '../Config/Login';
const SidebarAdmin = lazy(() => import('./SidebarAdmin'));




// loginHeader({ tipo_usuario: 1 })


const LayoutAdmin = () => {
    const { state: { usuario_logeado, logeado } } = useContext(ContextApp)
    const { cerrarSesion } = useContext(ContextApp)
    const navigate = useLocation()
    const [show, setShow] = useState(false);
    const [toggled, setToggled] = useState(false);

    const itemsShowSidebar = () => {
        setToggled(!toggled)
        if (!show) {
            setShow(!show)
        }
    }



    return (

        <div className='d-flex column '>

            {
                navigate.pathname === "/login" ? null : (
                    <div className='Sidebar fw-bold'  >
                        <Suspense fallback={<p>Cargando</p>}>
                            <SidebarAdmin itemsShowSidebar={itemsShowSidebar} show={show} toggled={toggled} />
                        </Suspense>
                    </div>
                )
            }

            <div className="wrapper flex-grow-1 h-100 vw-100" >
                <div className="Navbar bg-primary  p-1 w-100 h-25">
                    <Navbar>
                        {
                            navigate.pathname === "/login" ? null : (
                                <div className='d-flex p-0'>
                                    <div className="bg-warning rounded-3 m-auto d-none d-lg-block text-light">
                                        <Button
                                            variant='warning'
                                            className='text-light m-0 p-0'
                                            onClick={() => {
                                                setShow(!show)
                                            }}
                                        >
                                            <BsFilterLeft size={"2.2rem"} />
                                        </Button>
                                    </div>
                                </div>
                            )
                        }

                        {
                            navigate.pathname === "/login" ? null : (
                                <div className='d-flex'>
                                    <div className="bg-warning rounded-3 m-auto d-lg-none text-light">
                                        <Button
                                            variant='warning'
                                            className='text-light m-0 p-0'
                                            onClick={() => {
                                                setShow(false)
                                                setToggled(!toggled)
                                            }}
                                        >
                                            <BsFilterLeft
                                                size={"2.2rem"}

                                            />
                                        </Button>


                                    </div>
                                </div>
                            )
                        }

                        <div className=' w-25 m-auto'>
                            <div className='d-flex justify-content-center p-2'>
                                <img
                                    src={Logo}
                                    alt="Responsive image"
                                    className="img-fluid "
                                    loading="lazy"
                                    style={{ minHeight: '50px', minWidth: '150px' }}
                                />
                            </div>
                        </div>


                        {
                            navigate.pathname === "/login" ? null : (
                                <>
                                    <div className='d-flex px-1'>
                                        <div className="bg-warning rounded-3 m-auto text-light">
                                            <Button
                                                variant='warning'
                                                className='text-light p-0 m-0'
                                                onClick={() => {
                                                    cerrarSesion()
                                                }}>
                                                <FiLogOut
                                                    size={"1.6rem"}
                                                    className='m-1'
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            )
                        }


                        {/*    {
                            navigate.pathname === "/login" ? null : <p className='fw-bold text-white'>Hola: {usuario_logeado?.nombre}</p>
                        }  */}

                    </Navbar>

                    {
                        navigate.pathname === "/login" ? null : (

                            <>
                                <div className=''>
                                    <p className='text-light p-0 m-0 text-end h6 '>v1.0.2</p>
                                </div>
                            </>
                        )
                    }

                </div>


                <div className="p-2 min-vh-100"  >
                    <div>

                        {
                            navigate.pathname === "/admin" ?
                                (
                                    <div className='h1 text-center m-5 fw-bold text-warning'>
                                        Bienvenido
                                    </div>
                                ) : ('')
                        }
                    </div>


                    <Outlet />
                </div>

                <div className='bg-primary'>
                    <img
                        src={Placa}
                        alt="logo"
                        className="img-fluid w-100 h-100"
                        style={{ height: '185px' }}
                        loading="lazy"
                    />
                </div>




            </div>


        </div>

    )
}

export default LayoutAdmin