import React, { useState } from 'react'
import clienteAxios from '../../../Config/Axios'
import { ReservacionesRae } from '../Interfaces/InterfaceReservacionesCanceladas'
import { format } from 'date-fns';

import CryptoJS from 'crypto-js';
const UseCitasCanceladas = () => {
 
    const [reservacionesCanceladas, setReservacionesCanceladas] = useState<ReservacionesRae[]>([])

        
    // Decrypt
    const Decrypt = (texto: string) => {
       
        const enDes = CryptoJS.AES.decrypt(texto, process.env.REACT_APP_CLAVE_ENCRIPT!);
        const enDeci = CryptoJS.enc.Utf8.stringify(enDes);
        return enDeci;
    }


    const reservacionesCanceladasGet = async () => {
        const obtenerReservaciones = await clienteAxios.get<ReservacionesRae[]>('/reservaciones/reservacionescanceladas')
        const arreglo = obtenerReservaciones.data;
        if (arreglo) {
        for (const i in arreglo) {
            obtenerReservaciones.data[i].horario_final_inicio =    obtenerReservaciones.data[i].horario_final_inicio ? format(new Date(obtenerReservaciones.data[i].horario_final_inicio!.toString().split(".")[0]), "HH:mm aa") : 'No aplica'
            obtenerReservaciones.data[i].horario_final_fin = obtenerReservaciones.data[i].horario_final_fin ? format(new Date(obtenerReservaciones.data[i].horario_final_fin!.toString().split(".")[0]), "HH:mm aa") : 'No aplica'
            arreglo[i].responsable!.nombre = Decrypt(arreglo[i].responsable?.nombre!)
            arreglo[i].responsable!.ap_paterno = Decrypt(arreglo[i].responsable?.ap_paterno!)
            arreglo[i].responsable!.ap_materno = Decrypt(arreglo[i].responsable?.ap_materno!)
            arreglo[i].responsable!.correo_elec = Decrypt(arreglo[i].responsable?.correo_elec!)
            arreglo[i].responsable!.telefono = Decrypt(arreglo[i].responsable?.telefono!)
        }
    }

setReservacionesCanceladas(obtenerReservaciones.data)
    }

    return { reservacionesCanceladas, reservacionesCanceladasGet}

}

export default UseCitasCanceladas