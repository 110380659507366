
import { Button } from 'react-bootstrap'
import { TableColumn } from 'react-data-table-component'
import ModalGrande from '../../../Components/Modales/ModalGrande'
import TablaRae from '../../../Components/Tablas/TablaRae'
import { BsZoomIn } from "react-icons/bs";
import { ReservacionesRae } from '../Interfaces/InterfaceReservacionesCanceladas'
import { format } from 'date-fns'
import UseCitasCanceladas from '../Hooks/UseReservacionesCanceladas'
import { useEffect, useState } from 'react'
import FormCitasCanceladas from './FormCitasCanceladas';


const TablaCitasCanceladas = () => {

    const { reservacionesCanceladas, reservacionesCanceladasGet } = UseCitasCanceladas()
    const [mostrarModal, setMostrarModal] = useState(false)

    const [registroSeleccionado, setRegistroSeleccionado] = useState<ReservacionesRae | any>({
        id_reservacion: '',
        folio_reservacion: '',
        modalidad: '',
        fecha_registro: '',

        fecha_uno: '',
        horario_uno_inicio: '',
        horario_uno_fin: '',
        fecha_dos: '',
        horario_dos_inicio: '',
        horario_dos_fin: '',

        momento_act: '',
        prim_vez: '',
        enterado_por: '',
        id_guia: '',
        estado: '',
        fecha_final: '',

        taller: {
            id_taller: '',
            nombre_t: '',
        },

        cuento: {
            id_cuento: '',
            nombre_c: '',
        },

        grupo: {
            tipo_grupo: '',
            numero_hom: '',
            numero_muj: '',
            codigo_postal: '',

            pais: {
                id_pais: '',
                nombre_pais: '',
            },
            estato: {
                id_estado: '',
                nombre_estado: ''
            },
            municipio: {
                id_municipio: '',
                nombre_municipio: '',
            },
            colonia: {
                id_colonia: '',
                nombre: ''
            },
            calle_num: '',
            grado_escolar: '',
            sexo: '',
            nombre_institucion: ''
        },

        responsable: {
            nombre: '',
            ap_paterno: '',
            ap_materno: '',
            correo_elec: '',
            telefono: '',
        },

        vehiculos: {
            tipo_vehiculo: '',
            num_vehiculos: '',
            placas: '',
        },

        idactividad: {
            id_actividad: '',
            nombre_actividad: '',
        },
    })

    useEffect(() => {
        reservacionesCanceladasGet()
        setRegistroSeleccionado(false)
    }, [])

    const cerrarModal = () => {
        setMostrarModal(false)
        if (registroSeleccionado) {
            setRegistroSeleccionado(false)
        }
    }


    const columns: TableColumn<ReservacionesRae | any>[] = [
        {
            cell: (row) => (
                <div>
                    <Button variant='warning' onClick={() => {
                        setRegistroSeleccionado(row)
                        setMostrarModal(true)

                    }} >
                        <BsZoomIn className='text-light' />
                    </Button>
                </div>
            ),
            grow: 1

        },

        {
            name: "Folio Reservacion",
            cell: (row) => row.folio_reservacion,
            grow: 2
        },

        {
            name: "Fecha",
            selector: (row) => row.fecha_final ? format(new Date(row.fecha_final), 'yyyy-MM-dd')  : 'No aplica',
            grow: 2
        },
        {
            name: "Horario de inicio",
            selector: (row) => row.horario_final_inicio ,
            grow: 2
        },
        {
            name: "Horario de término",
            selector: (row) => row.horario_final_fin  ,
            grow: 2
        },
    ]

    return (
        <div>
            <TablaRae
                columns={columns}
                data={reservacionesCanceladas}
                //onclicNuevo={() => { setMostrarModal(true) }}
                closeModal={cerrarModal}
                show_modal={mostrarModal}
                paginationPerPage={20}
            />

            <ModalGrande
                size='xl'
                show_modal={mostrarModal}
                closeModal={cerrarModal}
            >
                <ModalGrande.ModalHeader >
                    <h2 style={{ color: "white" }}>Reservación Cancelada</h2>
                </ModalGrande.ModalHeader>

                <ModalGrande.ModalBody >
                   <FormCitasCanceladas cerrarModalForm={cerrarModal} registro_Seleccionado={registroSeleccionado} returnSubmitSuccess={() => {
                        reservacionesCanceladasGet()
                        cerrarModal()
                    }} /> 

                </ModalGrande.ModalBody>
            </ModalGrande>





        </div>
    )
}

export default TablaCitasCanceladas