import React, { useEffect, useState, ChangeEvent } from 'react'
import { Form, Formik, ErrorMessage } from 'formik'
import { Button } from 'react-bootstrap'
import { default as FormRae } from 'react-bootstrap/Form'
import { MediadorRae, PermisosProps } from '../Interfaces/InterfaceMediadores'
import UseUsuarios from '../Hooks/UseUsuarios'
import TablaRae from '../../../Components/Tablas/TablaRae'
import Swal from 'sweetalert2'
import clienteAxios from '../../../Config/Axios'
import { useContext } from 'react'
import { ContextApp } from '../../../Context/StateApp'
import * as Yup from 'yup'

interface Props {
    registro_Seleccionado: MediadorRae | undefined
    returnSubmitSuccess: () => void
    cerrarModalForm: () => void
}

const FormUsuario = ({
    registro_Seleccionado,
    returnSubmitSuccess,
    cerrarModalForm
}: Props) => {
    const { mediadoresPost, permisosPost, permisosGet, mediadoresGet } =
        UseUsuarios()

    const {
        state: { usuario_logeado }
    } = useContext(ContextApp)

    /* Módulos del sistema  */
    const [modFormulario, setModFormulario] = useState(false)
    const [modReservaciones, setModReservaciones] = useState(false)
    const [modReservacionesRally, setModReservacionesRally] = useState(false)
    const [modReservacionesConfirmadas, setModReservacionesConfirmadas] =
        useState(false)
    const [modReservacionesCanceladas, setModReservacionesCanceladas] =
        useState(false)
    const [modCalendario, setModCalendario] = useState(false)
    const [modCatalogos, setModCatalogos] = useState(false)
    const [modCuentacuentos, setModCuentacuentos] = useState(false)
    const [modTalleres, setModTalleres] = useState(false)
    const [modContactos, setModContactos] = useState(false)
    const [modUsuarios, setModUsuarios] = useState(false)

    const [registroSelect, setRegistroSelect] = useState<MediadorRae | any>({
        id_mediador: '' || false,
        nombre: '',
        ap_paterno: '',
        ap_materno: '',
        correo_electronico: '',
        es_mediador: '',
        estatus: true,

        //Datos para crear la cuenta de usuario para los mediadores
        usuariorae: {
            id_usuario: '',
            usuario: '',
            password: ''
        },

        id_usuario: '',
        usuario: '',
        password: '',
        repetir_password: '',

        usuario_log: usuario_logeado,

        //Campos para traer los modulos que contiene
        modulos: {
            modulo_formulario: '',
            modulo_reservaciones: '',
            modulo_reservacionesRally: '',
            modulo_reservacionesConfirmadas: '',
            modulo_reservacionesCanceladas: '',
            modulo_calendario: '',
            modulo_catalogos: '',
            modulo_cuentacuentos: '',
            modulo_taller: '',
            modulo_contactos: '',
            modulo_usuarios: ''
        }
    })

    useEffect(() => {
        if (registro_Seleccionado) {
            permisosGet(registro_Seleccionado.usuariorae?.id_usuario!)

            setRegistroSelect({
                id_mediador: registro_Seleccionado.id_mediador,
                nombre: registro_Seleccionado.nombre,
                ap_paterno: registro_Seleccionado.ap_paterno,
                ap_materno: registro_Seleccionado.ap_materno,
                correo_electronico: registro_Seleccionado.correo_electronico,
                estatus: registro_Seleccionado.estatus === 1 ? true : false,
                es_mediador: registro_Seleccionado.es_mediador,
                id_usuario: registro_Seleccionado.usuariorae?.id_usuario,
                usuario: registro_Seleccionado.usuariorae?.usuario,

                usuario_log: usuario_logeado?.id_mediador!
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registro_Seleccionado])

    useEffect(() => {
        if (registro_Seleccionado) {
            const obtenerPermisos = async () => {
                const { data } = await clienteAxios.get<PermisosProps[]>(
                    `/usuarios/permisos/${registro_Seleccionado?.usuariorae?.id_usuario}`
                )

                if (data) {
                    const modulo_formulario = data.find(
                        (p) => p.id_modulo === 1
                    )
                    const modulo_reservaciones = data.find(
                        (p) => p.id_modulo === 2
                    )
                    const modulo_reservacionesRally = data.find(
                        (p) => p.id_modulo === 3
                    )
                    const modulo_reservcionesConfirmadas = data.find(
                        (p) => p.id_modulo === 4
                    )
                    const modulo_reservacionesCanceladas = data.find(
                        (p) => p.id_modulo === 5
                    )
                    const modulo_calendario = data.find(
                        (p) => p.id_modulo === 6
                    )
                    const modulo_catalogos = data.find((p) => p.id_modulo === 7)

                    const modulo_cuentacuentos = data.find(
                        (p) => p.id_modulo === 8
                    )
                    const modulo_talleres = data.find((p) => p.id_modulo === 9)

                    const modulo_contactos = data.find(
                        (p) => p.id_modulo === 10
                    )
                    const modulo_usuarios = data.find((p) => p.id_modulo === 11)

                    setModFormulario(
                        modulo_formulario?.id_modulo ? true : false
                    )
                    setModReservaciones(
                        modulo_reservaciones?.id_modulo ? true : false
                    )
                    setModReservacionesRally(
                        modulo_reservacionesRally?.id_modulo ? true : false
                    )
                    setModReservacionesConfirmadas(
                        modulo_reservcionesConfirmadas?.id_modulo ? true : false
                    )
                    setModReservacionesCanceladas(
                        modulo_reservacionesCanceladas?.id_modulo ? true : false
                    )
                    setModCalendario(
                        modulo_calendario?.id_modulo ? true : false
                    )
                    setModCatalogos(modulo_catalogos?.id_modulo ? true : false)
                    setModCuentacuentos(
                        modulo_cuentacuentos?.id_modulo ? true : false
                    )
                    setModTalleres(modulo_talleres?.id_modulo ? true : false)
                    setModContactos(modulo_contactos?.id_modulo ? true : false)
                    setModUsuarios(modulo_usuarios?.id_modulo ? true : false)
                } else {
                    setModFormulario(false)
                    setModReservaciones(false)
                    setModReservacionesRally(false)
                    setModReservacionesConfirmadas(false)
                    setModReservacionesCanceladas(false)
                    setModCalendario(false)
                    setModCatalogos(false)
                    setModCuentacuentos(false)
                    setModTalleres(false)
                    setModContactos(false)
                    setModUsuarios(false)
                }
            }
            obtenerPermisos()
        }
    }, [registro_Seleccionado])

    const asignarPermisos = (cb: (isConfirmerd: boolean) => void) => {
        Swal.fire({
            title: '¿Está seguro de proceder con la acción?',
            confirmButtonText: 'Si',
            showCancelButton: true,
            cancelButtonText: 'No'
        }).then(({ isConfirmed }) => {
            cb(isConfirmed)
        })
    }

    const validaciones = Yup.object().shape({
        nombre: Yup.string()
            .matches(
                /[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+/,
                'El formato del campo es inválido'
            )
            .max(300, 'Este campo solo permite 300 caracteres')
            .required('Este campo es obligatorio'),
        ap_paterno: Yup.string()
            .matches(
                /[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+/,
                'El formato del campo es inválido'
            )
            .max(300, 'Este campo solo permite 300 caracteres')
            .required('Este campo es obligatorio'),
        ap_materno: Yup.string()
            .matches(
                /[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+/,
                'El formato del campo es inválido'
            )
            .max(300, 'Este campo solo permite 300 caracteres')
            .required('Este campo es obligatorio'),
        correo_electronico: Yup.string()
            .email('El formato del correo es incorrecto')
            .max(300, 'Este campo solo permite 300 caracteres')
            .required('Este campo es obligatorio'),
        usuario: Yup.string()
            .matches(
                /[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+/,
                'El formato del campo es inválido'
            )
            .max(300, 'Este campo solo permite 300 caracteres')
            .required('Este campo es obligatorio'),
        cambiarContraseña: Yup.string(),
        password: Yup.string().when('cambiarContraseña', {
            is: (val: boolean) => val === true,
            then: Yup.string()
                .required('Este campo es obligatorio')
                .min(8, 'Debes de introducir almenos 8 caracteres')
        }),
        repetir_password: Yup.string().when('cambiarContraseña', {
            is: (val: boolean) => val === true,
            then: Yup.string()
                .required('Este campo es obligatorio')
                .min(8, 'Debes de introducir almenos 8 caracteres')
                .oneOf(
                    [Yup.ref('password'), null],
                    'Las contraseñas no son iguales'
                )
        })
    })

    return (
        <div>
            <Formik
                initialValues={registroSelect}
                onSubmit={async (values) => {
                    await mediadoresPost(values)
                    mediadoresGet()
                    returnSubmitSuccess()
                }}
                enableReinitialize
                validationSchema={validaciones}
            >
                {({ handleChange, values, setFieldValue }) => (
                    <Form>
                        <FormRae.Group controlId="id_mediador">
                            <FormRae.Label>Número</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="id_mediador"
                                value={values.id_mediador || ''}
                                disabled
                                placeholder="Número usuario"
                            />
                        </FormRae.Group>

                        <FormRae.Group controlId="nombre" className="p-1 mb-2">
                            <FormRae.Label>Nombre</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="nombre"
                                onChange={handleChange}
                                value={values.nombre}
                                placeholder="Ingresa el nombre"
                            />
                            <ErrorMessage
                                name="nombre"
                                className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                component={'div'}
                            />
                        </FormRae.Group>
                        <FormRae.Group
                            controlId="ap_paterno"
                            className="p-1 mb-2"
                        >
                            <FormRae.Label>Primer apellido</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="ap_paterno"
                                onChange={handleChange}
                                value={values.ap_paterno}
                                placeholder="Ingresa el primer apellido"
                            />
                            <ErrorMessage
                                name="ap_paterno"
                                className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                component={'div'}
                            />
                        </FormRae.Group>

                        <FormRae.Group
                            controlId="ap_materno"
                            className="p-1 mb-2"
                        >
                            <FormRae.Label>Segundo apellido</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="ap_materno"
                                onChange={handleChange}
                                value={values.ap_materno}
                                placeholder="Ingresa el segundo apellido"
                            />
                            <ErrorMessage
                                name="ap_materno"
                                className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                component={'div'}
                            />
                        </FormRae.Group>

                        <FormRae.Group
                            controlId="correo_electronico"
                            className="p-1 mb-2"
                        >
                            <FormRae.Label>Correo electrónico</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="correo_electronico"
                                onChange={handleChange}
                                value={values.correo_electronico}
                                placeholder="Ingresa el correo electrónico"
                            />
                            <ErrorMessage
                                name="correo_electronico"
                                className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                component={'div'}
                            />
                        </FormRae.Group>

                        <FormRae.Group
                            controlId="es_mediador"
                            className="p-1 mb-2"
                        >
                            <FormRae.Label>Mediador</FormRae.Label>
                            <FormRae.Check
                                type="checkbox"
                                name="es_mediador"
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setFieldValue(
                                        'es_mediador',
                                        e.target.checked
                                    )
                                }
                                checked={values.es_mediador || ''}
                            />
                        </FormRae.Group>

                        {registro_Seleccionado ? (
                            <>
                                <FormRae.Group
                                    controlId="estatus"
                                    className="p-1 mb-2"
                                >
                                    <FormRae.Label>Estatus</FormRae.Label>
                                    <FormRae.Check
                                        type="checkbox"
                                        name=""
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                        ) =>
                                            setFieldValue(
                                                'estatus',
                                                e.target.checked
                                            )
                                        }
                                        checked={values.estatus || ''}
                                    />
                                </FormRae.Group>

                                <div className="d-flex border-top border-bottom p-2 justify-content-center">
                                    <FormRae.Label>
                                        Asignar Permisos
                                    </FormRae.Label>
                                </div>

                                <FormRae.Group
                                    controlId="modulo_formulario"
                                    className="d-flex"
                                >
                                    <FormRae.Check
                                        type="checkbox"
                                        name="modulo_formulario"
                                        onChange={async (
                                            e: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            asignarPermisos((isConfirmed) => {
                                                if (isConfirmed) {
                                                    setModFormulario(
                                                        !modFormulario
                                                    )
                                                    permisosPost({
                                                        id_tipo_usuario: 1,
                                                        id_modulo: 1,
                                                        id_usuario:
                                                            registro_Seleccionado!
                                                                .usuariorae
                                                                ?.id_usuario,
                                                        estado: !modFormulario
                                                    })
                                                }
                                            })
                                        }}
                                        checked={modFormulario || false}
                                        className="m-1"
                                    />
                                    <FormRae.Label className="m-1">
                                        {' '}
                                        Formulario{' '}
                                    </FormRae.Label>
                                </FormRae.Group>

                                <FormRae.Group
                                    controlId="modulo_reservaciones"
                                    className="d-flex"
                                >
                                    <FormRae.Check
                                        type="checkbox"
                                        name="modulos_reservaciones"
                                        onChange={async (
                                            e: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            asignarPermisos((isConfirmed) => {
                                                if (isConfirmed) {
                                                    setModReservaciones(
                                                        !modReservaciones
                                                    )
                                                    permisosPost({
                                                        id_tipo_usuario: 1,
                                                        id_modulo: 2,
                                                        id_usuario:
                                                            registro_Seleccionado!
                                                                .usuariorae
                                                                ?.id_usuario,
                                                        estado: !modReservaciones
                                                    })
                                                }
                                            })
                                        }}
                                        checked={modReservaciones || false}
                                        className="m-1"
                                    />
                                    <FormRae.Label className="m-1">
                                        {' '}
                                        Reservaciones{' '}
                                    </FormRae.Label>
                                </FormRae.Group>

                                <FormRae.Group
                                    controlId="modulo_reservacionesRally"
                                    className="d-flex"
                                >
                                    <FormRae.Check
                                        type="checkbox"
                                        name="modulo_reservacionesRally"
                                        onChange={async (
                                            e: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            asignarPermisos((isConfirmed) => {
                                                if (isConfirmed) {
                                                    setModReservacionesRally(
                                                        !modReservacionesRally
                                                    )
                                                    permisosPost({
                                                        id_tipo_usuario: 1,
                                                        id_modulo: 3,
                                                        id_usuario:
                                                            registro_Seleccionado!
                                                                .usuariorae
                                                                ?.id_usuario,
                                                        estado: !modReservacionesConfirmadas
                                                    })
                                                }
                                            })
                                        }}
                                        checked={modReservacionesRally || false}
                                        className="m-1"
                                    />
                                    <FormRae.Label className="m-1">
                                        Reservaciones Rally
                                    </FormRae.Label>
                                </FormRae.Group>

                                <FormRae.Group
                                    controlId="modulo_reservacionesConfirmadas"
                                    className="d-flex"
                                >
                                    <FormRae.Check
                                        type="checkbox"
                                        name="modulo_reservacionesConfirmadas"
                                        onChange={async (
                                            e: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            asignarPermisos((isConfirmed) => {
                                                if (isConfirmed) {
                                                    setModReservacionesConfirmadas(
                                                        !modReservacionesConfirmadas
                                                    )
                                                    permisosPost({
                                                        id_tipo_usuario: 1,
                                                        id_modulo: 4,
                                                        id_usuario:
                                                            registro_Seleccionado!
                                                                .usuariorae
                                                                ?.id_usuario,
                                                        estado: !modReservacionesConfirmadas
                                                    })
                                                }
                                            })
                                        }}
                                        checked={
                                            modReservacionesConfirmadas || false
                                        }
                                        className="m-1"
                                    />
                                    <FormRae.Label className="m-1">
                                        {' '}
                                        Reservaciones Confirmadas{' '}
                                    </FormRae.Label>
                                </FormRae.Group>

                                <FormRae.Group
                                    controlId="modulo_reservacionesCanceladas"
                                    className="d-flex"
                                >
                                    <FormRae.Check
                                        type="checkbox"
                                        name="modulo_reservacionesCanceladas"
                                        onChange={async (
                                            e: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            asignarPermisos((isConfirmed) => {
                                                if (isConfirmed) {
                                                    setModReservacionesCanceladas(
                                                        !modReservacionesCanceladas
                                                    )
                                                    permisosPost({
                                                        id_tipo_usuario: 1,
                                                        id_modulo: 5,
                                                        id_usuario:
                                                            registro_Seleccionado!
                                                                .usuariorae
                                                                ?.id_usuario,
                                                        estado: !modReservacionesCanceladas
                                                    })
                                                }
                                            })
                                        }}
                                        checked={
                                            modReservacionesCanceladas || false
                                        }
                                        className="m-1"
                                    />
                                    <FormRae.Label className="m-1">
                                        {' '}
                                        Reservaciones Canceladas
                                    </FormRae.Label>
                                </FormRae.Group>

                                <FormRae.Group
                                    controlId="modulo_calendario"
                                    className="d-flex"
                                >
                                    <FormRae.Check
                                        type="checkbox"
                                        name="modulo_calendario"
                                        onChange={async (
                                            e: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            asignarPermisos((isConfirmed) => {
                                                if (isConfirmed) {
                                                    setModCalendario(
                                                        !modCalendario
                                                    )
                                                    permisosPost({
                                                        id_tipo_usuario: 1,
                                                        id_modulo: 6,
                                                        id_usuario:
                                                            registro_Seleccionado!
                                                                .usuariorae
                                                                ?.id_usuario,
                                                        estado: !modCalendario
                                                    })
                                                }
                                            })
                                        }}
                                        checked={modCalendario || false}
                                        className="m-1"
                                    />
                                    <FormRae.Label className="m-1">
                                        {' '}
                                        Calendario{' '}
                                    </FormRae.Label>
                                </FormRae.Group>

                                <FormRae.Group
                                    controlId="modulo_catalogos"
                                    className="d-flex"
                                >
                                    <FormRae.Check
                                        type="checkbox"
                                        name="modulo_catalogos"
                                        onChange={async (
                                            e: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            asignarPermisos((isConfirmed) => {
                                                if (isConfirmed) {
                                                    setModCatalogos(
                                                        !modCatalogos
                                                    )
                                                    permisosPost({
                                                        id_tipo_usuario: 1,
                                                        id_modulo: 7,
                                                        id_usuario:
                                                            registro_Seleccionado!
                                                                .usuariorae
                                                                ?.id_usuario,
                                                        estado: !modCatalogos
                                                    })
                                                }
                                            })
                                        }}
                                        checked={modCatalogos || false}
                                        className="m-1"
                                    />
                                    <FormRae.Label className="m-1">
                                        {' '}
                                        Catálogos{' '}
                                    </FormRae.Label>
                                </FormRae.Group>

                                <FormRae.Group
                                    controlId="modulo_cuentacuentos"
                                    className="d-flex"
                                >
                                    <FormRae.Check
                                        type="checkbox"
                                        name="modulo_cuentacuentos"
                                        onChange={async (
                                            e: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            asignarPermisos((isConfirmed) => {
                                                if (isConfirmed) {
                                                    setModCuentacuentos(
                                                        !modCuentacuentos
                                                    )
                                                    permisosPost({
                                                        id_tipo_usuario: 1,
                                                        id_modulo: 8,
                                                        id_usuario:
                                                            registro_Seleccionado!
                                                                .usuariorae
                                                                ?.id_usuario,
                                                        estado: !modCuentacuentos
                                                    })
                                                }
                                            })
                                        }}
                                        checked={modCuentacuentos || false}
                                        className="m-1"
                                    />
                                    <FormRae.Label className="m-1">
                                        {' '}
                                        Cuentacuentos{' '}
                                    </FormRae.Label>
                                </FormRae.Group>

                                <FormRae.Group
                                    controlId="modulo_taller"
                                    className="d-flex"
                                >
                                    <FormRae.Check
                                        type="checkbox"
                                        name="modulo_taller"
                                        onChange={async (
                                            e: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            asignarPermisos((isConfirmed) => {
                                                if (isConfirmed) {
                                                    setModTalleres(!modTalleres)
                                                    permisosPost({
                                                        id_tipo_usuario: 1,
                                                        id_modulo: 9,
                                                        id_usuario:
                                                            registro_Seleccionado!
                                                                .usuariorae
                                                                ?.id_usuario,
                                                        estado: !modTalleres
                                                    })
                                                }
                                            })
                                        }}
                                        checked={modTalleres || false}
                                        className="m-1"
                                    />
                                    <FormRae.Label className="m-1">
                                        {' '}
                                        Talleres{' '}
                                    </FormRae.Label>
                                </FormRae.Group>

                                <FormRae.Group
                                    controlId="modulo_contactos"
                                    className="d-flex"
                                >
                                    <FormRae.Check
                                        type="checkbox"
                                        name="modulo_contactos"
                                        onChange={async (
                                            e: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            asignarPermisos((isConfirmed) => {
                                                if (isConfirmed) {
                                                    setModContactos(
                                                        !modContactos
                                                    )
                                                    permisosPost({
                                                        id_tipo_usuario: 1,
                                                        id_modulo: 10,
                                                        id_usuario:
                                                            registro_Seleccionado!
                                                                .usuariorae
                                                                ?.id_usuario,
                                                        estado: !modContactos
                                                    })
                                                }
                                            })
                                        }}
                                        checked={modContactos || false}
                                        className="m-1"
                                    />
                                    <FormRae.Label className="m-1">
                                        {' '}
                                        Contactos{' '}
                                    </FormRae.Label>
                                </FormRae.Group>

                                <FormRae.Group
                                    controlId="modulo_usuarios"
                                    className="d-flex"
                                >
                                    <FormRae.Check
                                        type="checkbox"
                                        name="modulo_usuarios"
                                        onChange={async (
                                            e: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            asignarPermisos((isConfirmed) => {
                                                if (isConfirmed) {
                                                    setModUsuarios(!modUsuarios)
                                                    permisosPost({
                                                        id_tipo_usuario: 1,
                                                        id_modulo: 11,
                                                        id_usuario:
                                                            registro_Seleccionado!
                                                                .usuariorae
                                                                ?.id_usuario,
                                                        estado: !modUsuarios
                                                    })
                                                }
                                            })
                                        }}
                                        checked={modUsuarios || false}
                                        className="m-1"
                                    />
                                    <FormRae.Label className="m-1">
                                        {' '}
                                        Usuarios{' '}
                                    </FormRae.Label>
                                </FormRae.Group>

                                {/*  <FormRae.Group controlId="modulo_reportes" className='d-flex' >
                                <FormRae.Check
                                    type='checkbox'
                                    name="modulo_reportes"
                                    onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                                        asignarPermisos((isConfirmed) => {
                                            if (isConfirmed) {
                                                setModReportes(!modReportes)
                                                permisosPost({
                                                    id_tipo_usuario: 1,
                                                    id_modulo: 10,
                                                    id_usuario: registro_Seleccionado!.usuariorae.id_usuario,
                                                    estado: !modReportes,
                                                })
                                            }
                                        })
                                    }}
                                    checked={modReportes || false}
                                    className='m-1'
                                />
                                <FormRae.Label className='m-1'> Reportes </FormRae.Label>
                            </FormRae.Group> */}
                            </>
                        ) : (
                            ''
                        )}
                        {!registro_Seleccionado ? (
                            <>
                                <FormRae.Group
                                    controlId="usuario"
                                    className="p-1 mb-2"
                                >
                                    <FormRae.Label>
                                        Nombre Usuario
                                    </FormRae.Label>
                                    <FormRae.Control
                                        type="text"
                                        name="usuario"
                                        value={values.usuario || ''}
                                        onChange={handleChange}
                                        placeholder="Ingresa el nombre del usuario"
                                    />
                                    <ErrorMessage
                                        name="usuario"
                                        className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                        component={'div'}
                                    />
                                </FormRae.Group>

                                <FormRae.Group
                                    controlId="password"
                                    className="p-1 mb-2"
                                >
                                    <FormRae.Label>Contraseña</FormRae.Label>
                                    <FormRae.Control
                                        type="password"
                                        name="password"
                                        value={values.password || ''}
                                        onChange={handleChange}
                                        placeholder="Ingresa la contraseña"
                                    />
                                    <ErrorMessage
                                        name="password"
                                        className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                        component={'div'}
                                    />
                                </FormRae.Group>

                                <FormRae.Group
                                    controlId="repetir_password"
                                    className="p-1 mb-2"
                                >
                                    <FormRae.Label>
                                        Repetir Contraseña
                                    </FormRae.Label>
                                    <FormRae.Control
                                        type="password"
                                        name="repetir_password"
                                        onChange={handleChange}
                                        value={values.repetir_password || ''}
                                        placeholder="Repite la contraseña "
                                    />
                                    <ErrorMessage
                                        name="repetir_password"
                                        className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                        component={'div'}
                                    />
                                </FormRae.Group>
                            </>
                        ) : (
                            <></>
                        )}

                        <TablaRae.ModalFooter>
                            <Button variant="primary" type="submit">
                                {registro_Seleccionado
                                    ? 'Actualizar'
                                    : 'Guardar'}
                            </Button>

                            <Button variant="primary" onClick={cerrarModalForm}>
                                Cerrar
                            </Button>
                        </TablaRae.ModalFooter>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default FormUsuario
