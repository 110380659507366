import React, { useContext, useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { loginHeader } from '../../Config/Login';
import useLogin from '../../Modulos/Login/Hooks/useLogin';
import { ContextApp } from '../../Context/StateApp';


const RequireAuthAdmin = ({ children }: { children: JSX.Element }) => {

  const navigate = useNavigate();
  const { iniciarSession, state: { logeado, cargando } } = useContext(ContextApp)



  const { obtenerPersonalLogeado } = useLogin();
  const token = localStorage.getItem("Token");
  
  useEffect(() => {
   /*  if (!logeado && cargando) {
      establecerUsuarioLogeado()
    } */

    establecerUsuarioLogeado()

  }, [logeado])


  
  const establecerUsuarioLogeado = async () => {
    
    loginHeader({token, tipo_usuario: 1})
    

    const res = await obtenerPersonalLogeado()

    if (res) {
      iniciarSession(res)
    } else {
      localStorage.removeItem("Token")
      navigate("/login")
    }

  }



  if (!token) {
    return <Navigate to="/login" replace />;
  }











  return children;
}

export default RequireAuthAdmin



