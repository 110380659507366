import React, { useState } from 'react'
import { EventosAtributos, PropsEventosPost } from '../Interfaces/InterfaceCalendario'
import clienteAxios from '../../../Config/Axios';
import { addHours, format } from 'date-fns';
import { subHours } from 'date-fns/esm';



const UseCalendario = () => {

    const [calendario, setCalendario] = useState<EventosAtributos[]>([])

    const [reservacionesAsignadas, setReservacionesAsignadas] = useState<[]>([])

    //-----Eventos es igual a obtener los citas del calendario 

    const eventos = async () => {
        const obtenerEventos = await clienteAxios.get<PropsEventosPost[]>('/calendario');

        const arreglo = obtenerEventos.data;
        for (const i in arreglo) {
            const horario_uno = new Date(arreglo[i].start.toString().split(".")[0]);
            const horario_dos = new Date(arreglo[i].end.toString().split(".")[0]);
            arreglo[i].start = horario_uno;
            arreglo[i].end = horario_dos;
        }
        setCalendario(arreglo)

    }

    interface props {
        num_grupo_rally: string,
        fecha_final: string | Date
    }

    const ReservacionesAsignadasRally = async (values: props) => {
      const obtenerReservacionesAsignadas = await clienteAxios.post("/reservaciones/reservacionesasignadas", values).catch(err => err.response)
        setReservacionesAsignadas(obtenerReservacionesAsignadas.data)
       console.log(obtenerReservacionesAsignadas)
   
    }

    return { eventos, calendario, ReservacionesAsignadasRally, reservacionesAsignadas}
}

export default UseCalendario