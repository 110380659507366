import { useLocation, Navigate } from 'react-router-dom';




const  RequireAuth = ({ children }: { children: JSX.Element }) => {
     
    let location = useLocation();
  
    if (localStorage.getItem("Token")) {
 
      return <Navigate to="/admin"  replace />;
    }
  
    return children;
  }

  export default RequireAuth
  