import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ContextApp } from "../../Context/StateApp";


const  RutaPrivadaCitasConfirmadas = ({ children }: { children: JSX.Element }) => {
     
    const {state:{ usuario_logeado, logeado }} = useContext(ContextApp)
    const [acceso, setAcceso] = useState(true)

    
    useEffect(() => {
      if ( logeado && !usuario_logeado?.permisos.find((permiso)=>  permiso.id_modulo === 4 )) {
      
        setAcceso(false)
      }else{
        setAcceso(true)

        
      }
    }, [logeado])
    
    
  
    if ( !acceso ) {
      
      return <Navigate to="/admin"  replace />;
    }
  
    return children;
  }

  export default RutaPrivadaCitasConfirmadas
  