import { InitialState } from './StateApp';
import { UsuarioLogeado } from '../Modulos/Login/Interfaces/InterfaceLogin';

type ActionProps = | { type: "INICIAR_SESSION", payload: UsuarioLogeado } 
                    | {type: "CERRAR_SESION"  }


export const ReducerApp = (state: InitialState, action: ActionProps): InitialState => {

  switch (action.type) {
    case "INICIAR_SESSION":
      const { nombre, ap_materno, ap_paterno, permisos, id_mediador } = action.payload

      return {
        ...state,
        usuario_logeado: {
          nombre,
          ap_paterno,
          ap_materno,
          permisos,
          id_mediador
        },
        logeado: true,
        cargando: false
      }

    case "CERRAR_SESION":
      localStorage.removeItem("Token");
      return {
        ...state,
        usuario_logeado:undefined,
        logeado: false,
        cargando: true
      }
    default:
      return state;
  }


}