import React, { useState } from 'react'
import { ReservacionProps, ReservacionesRae, ConfirmacionReservacion, EventosAtributos, EmailProps } from '../Interfaces/InterfaceReservaciones';
import clienteAxios from '../../../Config/Axios';
import Swal from 'sweetalert2';
import { ResponseDataSwet } from '../../../InterfacesGlobales/InterfaceResponseSweet';
import { format } from 'date-fns';
import { AlertaRae } from '../../../Components/Alertas/AlertaToastify';
import { ResponseData } from '../../../InterfacesGlobales/InterfaceResponse';


import CryptoJS from 'crypto-js';
import { date } from 'yup';



const UseReservaciones = () => {

    const [reservaciones, setReservaciones] = useState<ReservacionesRae[]>([])

    // Decrypt
    const Decrypt = (texto: string) => {

        const enDes = CryptoJS.AES.decrypt(texto, process.env.REACT_APP_CLAVE_ENCRIPT!);
        const enDeci = CryptoJS.enc.Utf8.stringify(enDes);
        return enDeci;
    }

    const reservacionesGet = async () => {
        const obtenerReservaciones = await clienteAxios.get<ReservacionesRae[]>('/reservaciones')

        const arreglo = obtenerReservaciones.data;
        console.log(arreglo)
        if (arreglo) {
            for (const i in arreglo) {
               
                const horario_uno_inicio = new Date((arreglo[i].horario_uno_inicio!).toString().split(".")[0]); 
                arreglo[i].horario_uno_inicio = format(new Date(horario_uno_inicio), 'yyyy-MM-dd HH:mm');


                const horario_uno_fin = new Date(arreglo[i].horario_uno_fin!.toString().split(".")[0]);
                arreglo[i].horario_uno_fin = format(new Date(horario_uno_fin), 'yyyy-MM-dd HH:mm');

                const horario_dos_inicio = new Date(arreglo[i].horario_dos_inicio!.toString().split(".")[0])
                arreglo[i].horario_dos_inicio = format(new Date(horario_dos_inicio), 'yyyy-MM-dd HH:mm')
                const horario_dos_fin = new Date(arreglo[i].horario_dos_fin!.toString().split(".")[0])
                arreglo[i].horario_dos_fin = format(new Date(horario_dos_fin), 'yyyy-MM-dd HH:mm')

                arreglo[i].grupo!.calle_num = Decrypt(arreglo[i].grupo!.calle_num!)

                arreglo[i].responsable!.nombre = Decrypt(arreglo[i].responsable?.nombre!)
                arreglo[i].responsable!.ap_paterno = Decrypt(arreglo[i].responsable?.ap_paterno!)
                arreglo[i].responsable!.ap_materno = Decrypt(arreglo[i].responsable?.ap_materno!)
                arreglo[i].responsable!.correo_elec = Decrypt(arreglo[i].responsable?.correo_elec!)
                arreglo[i].responsable!.telefono = Decrypt(arreglo[i].responsable?.telefono!)
            }

        }

        setReservaciones(arreglo)
    }

    const confirmacionReservacionPost = async (values: ConfirmacionReservacion) => {
        const reservacionesPost = await clienteAxios.post<ResponseDataSwet>("reservaciones/confirmareservacion", values).catch(err => err.response)

        /*  
        if (reservacionesPost.status === 404) {

            return await Swal.fire({
                title: 'Ocurrio un error intentalo de nuevo',
                icon: 'error',
                showDenyButton: false,
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#B38E5D',
                buttonsStyling: true,
                color: 'white',
                background: '#13322B'
            })
        } */

        await Swal.fire({
            title: reservacionesPost?.data.msg,
            icon: reservacionesPost?.data.styles,
            showDenyButton: false,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#B38E5D',
            buttonsStyling: true,
            color: 'white',
            background: '#13322B'
        })
    }

    const enviarEmailConfirmacion = async (values: EmailProps) => {
        const emailPost = await clienteAxios.post("/reservaciones/emailconfirmacion", values).catch(err => console.log(err))

    }

    const actualizarReservacion = async (values: ReservacionesRae) => {
        const res = await clienteAxios.post<ResponseData>("/reservaciones/actreservacion", values).catch(err => err.response)
        console.log(values);
        AlertaRae(res?.data.styles, res?.data.msg)

    }

    return { reservaciones, reservacionesGet, confirmacionReservacionPost, enviarEmailConfirmacion, actualizarReservacion }
}

export default UseReservaciones