import React from 'react'
import TablaReservacionesRally from '../Components/TablaReservacionRally';

const PageReservacionRally = () => {
  return (
    <div>
      <hr className="my-1 gob" />
      <TablaReservacionesRally />
    </div>
  )
}

export default PageReservacionRally