import React from 'react'
import Button from 'react-bootstrap/Button'

interface Props {
    cargando: boolean,
    texto:string 
}

const BtnSubmit = ({ cargando,texto} :Props) => {
    return (

        <Button
            type='submit'
            variant='danger'
            //variant='outline-danger'
            disabled={cargando}
        >
            {cargando  ?   "Cargando" : texto}

        </Button>

    )
}

export default BtnSubmit