import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState, ChangeEvent, useRef } from "react";
import { Accordion, Button } from "react-bootstrap";
import { default as FormRae } from "react-bootstrap/Form";
import ModalConfirmacion from "../../../Components/Modales/ModalConfirmacion";
import TablaRae from "../../../Components/Tablas/TablaRae";
import { useLocation } from "react-router-dom";

import UseCuentos from "../../Cuentacuentos/Hooks/UseCuentos";
import UseTalleres from "../../Talleres/Hooks/UseTalleres";
import UseCatalogos from "../hooks/UseCatalogos";
import UseReservacionesForm from "../hooks/UseReservacionesForm";
import useTipoActividad from "../hooks/useTipoActividad";
import {
  Reservaciones,
  ReservacionesAtributos,
} from "../Interfaces/InterfaceReservacionesForm";

import format from "date-fns/format";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReCAPTCHA from "react-google-recaptcha";
import CryptoJS from "crypto-js";

//Estilos
import "../../../Styles/styles.scss";
//Validaciones
import * as Yup from "yup";
//Alertas
import Swal from "sweetalert2";

import { useContext } from "react";
import { ContextApp } from "../../../Context/StateApp";

//ReactDatapicker
import {
  add,
  addDays,
  addMonths,
  getDay,
  setHours,
  setMinutes,
  subMonths,
  addHours,
  addMinutes,
} from "date-fns";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { parseISO, subDays, subYears } from "date-fns/esm";
import { ColoniasAtributos } from "../../../../../backend/src/Modulos/Colonias/Interfaces/InterfaceColonias";

import { useNavigate } from "react-router-dom";
import { BsExclamationSquare } from "react-icons/bs";
import "../../../index.scss";

registerLocale("es", es);

const FormDiasRally = () => {
  useEffect(() => {
    return () => {
      window.document.title = "Reservaciones | AGN";
    };
  }, []);

  //Hooks Para los catálogos
  const { tipoActividadGet, tipoActividad } = useTipoActividad();
  const { talleresGet, talleres } = UseTalleres();
  const { cuentacuentosGet, cuentos } = UseCuentos();
  const {
    paisesGet,
    paises,
    estadosGet,
    estados,
    municipiosGet,
    municipios,
    SetMunicipios,
    coloniasGet,
    colonias,
    SetColonias,
    obtenerAñoGet,
    diasAño,
    obtenerDiasFestivosGet,
    diasFestivos,
    obtenerDiasRallyGet,
    diasRally,
    obtenerHorariosRallyGet,
    horariosRally,
  } = UseCatalogos();

  const {
    state: { usuario_logeado, logeado },
  } = useContext(ContextApp);

  //Hooks para peticion POST
  const { reservacionesPost } = UseReservacionesForm();

  interface PropsActividad {
    id_actividad?: number;
    nombre_actividad: string;
    acronimo_act: string;
  }

  interface PropsTalleres {
    id_taller?: number;
    nombre_t: string;
  }

  interface PropsCuentos {
    id_cuento?: number;
    nombre_c: string;
  }

  interface PropsPaises {
    id_pais?: number | string | null;
    iso?: string;
    nombre_pais?: string;
  }

  interface PropsEstados {
    id_pais?: number | string;
    id_estado?: number | string;
    nombre_estado?: string;
  }

  interface PropsMunicipios {
    id_estado?: number;
    id_municipio?: number;
    nombre_municipio?: string;
  }

  interface PropsColonias {
    id_municipio?: number;
    id_colonia?: number;
    nombre_colonia?: string;
  }

  interface PropsDiasRally {
    horario_fin?: string;
    horario_inicio?: Date | string | number;
    id_dia?: number;
    nombre_dia?: string;
    fecha_inicio?: string;
    fecha_fin?: string;
  }

  useEffect(() => {
    cuentacuentosGet();
    tipoActividadGet();
    talleresGet();
    paisesGet();
    estadosGet();
    obtenerAñoGet();
    obtenerDiasFestivosGet();
    obtenerDiasRallyGet();
  }, []);

  const captcha: any = useRef(null);

  const [id_dia, fecha_inicio, fecha_fin, horario_inicio, horario_fin] =
    diasRally;

  let fechaRally = fecha_inicio;
  const horarioInicio = horario_inicio; //format(new Date(horario_inicio!), 'HH:mm:ss')
  const horarioFin = horario_fin;
  const rally = id_dia;

  const isWeekday = (date: any) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  const Festivos = diasFestivos?.map((dias) => {
    const conversion = new Date(dias);
    return conversion;
  });

  const Rally = diasRally!.map((dia) => {
    const dias = dia.fecha_inicio?.replaceAll("-", "/");
    const conversion = new Date(dias!);
    return conversion;
  });

  let highlightWithRanges: any = [
    {
      "react-datepicker__day--highlighted-custom-3": Festivos,
    },
    {
      "react-datepicker__day--highlighted-custom-2": Rally,
    },
  ];

  const anio = format(new Date(), "yyyy");

  let location = useLocation();

  const [reservacionesSelect, setReservacionesSelect] = useState<
    Reservaciones | any
  >({
    id_reservacion: "",
    folio_anio: new Date(anio),
    folio_reservacion: "",

    fecha_actividad: "",
    horario_inicio: "",
    horario_fin: "",

    id_tipo_actividad: "",
    modalidad: "",
    fecha_registro: "",

    fecha_uno: "",
    horario_uno: "",
    horario_uno_inicio: "",
    horario_uno_fin: "",

    fecha_dos: "",
    horario_dos: "",
    horario_dos_inicio: "",
    horario_dos_fin: "",

    momento_act: "",
    id_cuento: "",
    id_taller: "",

    prim_vez: "",
    enterado_por: "",
    descripcion_otro: "",
    expectativas: "",
    id_guia: "",
    estado: "",
    fecha_final: "",

    tipo_grupo: "",
    nombre_institucion: "",
    sexo: "",
    numero_muj: "",
    numero_hom: "",
    codigo_postal: "",
    id_pais: "",
    id_estado: "",
    id_municipio: "",
    id_colonia: "",
    n_estado: "",
    n_colonia: "",
    n_municipio: "",

    calle_num: "",
    grado_escolar: "",

    nombre: "",
    ap_paterno: "",
    ap_materno: "",
    correo_elec: "",
    telefono: "",

    tipo_vehiculos: "",
    num_vehiculos: "",
    placas: "",
    ingresa_vehiculo: "",
    hora_actividad_rally: "",
    captcha: "",

    usuario_logeado_cat: location.pathname,
  });

  const validaciones = Yup.object().shape({
    id_tipo_actividad: Yup.string().required(`Este campo es obligatorio`),

    modalidad: Yup.string().when("id_tipo_actividad", {
      is: (val: number) =>
        val == 1 || val == 2 || val == 3 || val == 4 || val == 5,
      then: Yup.string().required("Este campo es obligatorio"),
    }),

    fecha_actividad: Yup.string().when("id_tipo_actividad", {
      is: (val: number) => val == 6,
      then: Yup.string().required("Este campo es obligatorio"),
    }),

    fecha_uno: Yup.string().when("id_tipo_actividad", {
      is: (val: number) =>
        val == 1 || val == 2 || val == 3 || val == 4 || val == 5,
      then: Yup.string().required("Este campo es obligatorio"),
    }),

    horario_uno: Yup.string().when("id_tipo_actividad", {
      is: (val: number) =>
        val == 1 || val == 2 || val == 3 || val == 4 || val == 5,
      then: Yup.string().required("Este campo es obligatorio"),
    }),

    fecha_dos: Yup.string().when("id_tipo_actividad", {
      is: (val: number) =>
        val == 1 || val == 2 || val == 3 || val == 4 || val == 5,
      then: Yup.string().required("Este campo es obligatorio"),
    }),
    horario_dos: Yup.string().when("id_tipo_actividad", {
      is: (val: number) =>
        val == 1 || val == 2 || val == 3 || val == 4 || val == 5,
      then: Yup.string().required("Este campo es obligatorio"),
    }),
    momento_act: Yup.string().when("id_tipo_actividad", {
      is: (val: number) => val == 2 || val == 3,
      then: Yup.string().required("Este campo es obligatorio"),
    }),

    id_cuento: Yup.string().when("id_tipo_actividad", {
      is: (val: number) => val == 2 || val == 5,
      then: Yup.string().required("Este campo es obligatorio"),
    }),

    id_taller: Yup.string().when("id_tipo_actividad", {
      is: (val: number) => val == 3 || val == 4,
      then: Yup.string().required("Este campo es obligatorio"),
    }),
    numero_muj: Yup.string().when("id_tipo_actividad", {
      is: (val: number) =>
        val == 1 || val == 2 || val == 3 || val == 4 || val == 5,
      then: Yup.string()
        .matches(/^[0-9]+$/, "Este campo solo permite números enteros")
        .max(2, "Solo puedes introducir  caracteres")
        .required("Este campo es obligatorio"),
    }),
    numero_hom: Yup.string().when("id_tipo_actividad", {
      is: (val: number) =>
        val == 1 || val == 2 || val == 3 || val == 4 || val == 5,
      then: Yup.string()
        .matches(/^[0-9]+$/, "Este campo solo permite números enteros")
        .max(2, "Solo puedes introducir  caracteres")
        .required("Este campo es obligatorio"),
    }),

    sexo: Yup.string().when("id_tipo_actividad", {
      is: (val: number) => val == 6,
      then: Yup.string().required("Este campo es obligatorio"),
    }),
    tipo_grupo: Yup.string().required("Este campo es obligatorio"),

    nombre_institucion: Yup.string().when("tipo_grupo", {
      is: (val: string) => val == "Institucional",
      then: Yup.string()
        .required("Este campo es obligatorio")
        .max(300, "Este campo solo permite 300 caracteres")
        .required("Este campo es obligatorio"),
    }),

    codigo_postal: Yup.string()
      .matches(/^[0-9]+$/, "Este campo solo permite números enteros")
      .min(5, "El minimo de caracteres para este campo es de 5")
      .max(5, "Solo puedes introducir 2 caracteres")
      .required("Este campo es obligatorio"),
    id_pais: Yup.string().required("Este campo es obligatorio"),

    n_estado: Yup.string().when("id_pais", {
      is: (val: number) => val != 146,
      then: Yup.string()
        .required("Este campo es obligatorio")
        .max(100, "Este campo solo permite 300 caracteres")
        .required("Este campo es obligatorio"),
    }),

    n_colonia: Yup.string().when("id_pais", {
      is: (val: number) => val != 146,
      then: Yup.string()
        .required("Este campo es obligatorio")
        .max(100, "Este campo solo permite 300 caracteres")
        .required("Este campo es obligatorio"),
    }),

    n_municipio: Yup.string().when("id_pais", {
      is: (val: number) => val != 146,
      then: Yup.string()
        .required("Este campo es obligatorio")
        .max(100, "Este campo solo permite 300 caracteres")
        .required("Este campo es obligatorio"),
    }),

    id_estado: Yup.string().when("id_pais", {
      is: (val: number) => val == 146,
      then: Yup.string()
        .required("Este campo es obligatorio")
        .max(300, "Este campo solo permite 300 caracteres")
        .required("Este campo es obligatorio"),
    }),
    id_municipio: Yup.string().when("id_pais", {
      is: (val: number) => val == 146,
      then: Yup.string()
        .required("Este campo es obligatorio")
        .max(300, "Este campo solo permite 300 caracteres")
        .required("Este campo es obligatorio"),
    }),
    id_colonia: Yup.string().when("id_pais", {
      is: (val: number) => val == 146,
      then: Yup.string()
        .required("Este campo es obligatorio")
        .max(300, "Este campo solo permite 300 caracteres")
        .required("Este campo es obligatorio"),
    }),

    calle_num: Yup.string()
      .max(300, "Este campo solo permite 300 caracteres")
      .required("Este campo es obligatorio"),
    grado_escolar: Yup.string().required("Este campo es obligatorio"),
    nombre: Yup.string()
      .matches(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+/, "El formato del campo es invalido")
      .max(300, "Este campo solo permite 300 caracteres")
      .required("Este campo es obligatorio"),
    ap_materno: Yup.string()
      .matches(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+/, "El formato del campo es invalido")
      .max(300, "Este campo solo permite 300 caracteres")
      .required("Este campo es obligatorio"),
    ap_paterno: Yup.string()
      .matches(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+/, "El formato del campo es invalido")
      .max(300, "Este campo solo permite 300 caracteres")
      .required("Este campo es obligatorio"),
    correo_elec: Yup.string()
      .email("El formato del campo no es valido")
      .max(300, "Este campo solo permite 300 caracteres")
      .required("Este campo es obligatorio"),
    telefono: Yup.string()
      .matches(/^[0-9]+$/, "Este campo solo permite números enteros")
      .typeError("Este campo solo permite números enteros")
      .required("Este campo es obligatorio")
      .min(10, "El minimo de caracteres para este campo es de 10"),

    prim_vez: Yup.string().required("Este campo es obligatorio"),
    ingresa_vehiculo: Yup.string()
      .when("modalidad", {
        is: (val: string) => val == "Presencial",
        then: Yup.string().required("Este campo es obligatorio"),
      })
      .when("id_tipo_actividad", {
        is: (val: number) => val == 6,
        then: Yup.string().required("Este campo es obligatorio"),
      }),

    tipo_vehiculos: Yup.string().when("ingresa_vehiculo", {
      is: (val: string) => val == "Si",
      then: Yup.string().required("Este campo es obligatorio"),
    }),

    num_vehiculos: Yup.string().when("ingresa_vehiculo", {
      is: (val: string) => val == "Si",
      then: Yup.string().required("Este campo es obligatorio"),
    }),
    placas: Yup.string().when("ingresa_vehiculo", {
      is: (val: string) => val == "Si",
      then: Yup.string().required("Este campo es obligatorio"),
    }),
    enterado_por: Yup.string().required("Este campo es obligatorio"),
    expectativas: Yup.string()
      .matches(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+/, "El formato del campo es invalido")
      .max(300, "Este campo solo permite 300 caracteres")
      .nullable(),
    descripcion_otro: Yup.string()
      .matches(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+/, "El formato del campo es invalido")
      .max(300, "Este campo solo permite 300 caracteres")
      .when("enterado_por", {
        is: (val: string) => val == "Otro",
        then: Yup.string().required("Este campo es obligatorio"),
      }),
    usuario_logeado_cat: Yup.string().nullable(),
    captcha: Yup.string().when("usuario_logeado_cat", {
      is: (val: string) => val == "/form",
      then: Yup.string().required("Este campo es obligatorio"),
    }),
  });

  const history = useNavigate();

  // Encrypt
  const Encrypt = (texto: string) => {
    const enEs = CryptoJS.AES.encrypt(
      texto,
      process.env.REACT_APP_CLAVE_ENCRIPT!
    ).toString();
    return enEs;
  };

  return (
    <>
      <div className="row p-0 m-3">
        <div className="col-lg-6 col-md-12">
          <Accordion className=" text-justify text-align-justify">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h4>Aviso de privacidad</h4>
              </Accordion.Header>
              <Accordion.Body>
                <h6 className="txt_aviso">
                  Al reservar una actividad a través del Sistema de
                  Reservaciones de Actividades Educativas la persona usuaria
                  autoriza el tratamiento de sus datos personales por parte del
                  Departamento de Programas Educativos para la Ciudadanía del
                  AGN. Los datos personales recabados tienen como propósito
                  acreditar la veracidad de la reservación, así como conformar
                  una base de datos del público atendido por el Departamento de
                  Programas Educativos para la Ciudadanía y que le permita
                  compartir avisos de interés relacionadas con actividades
                  educativas para el conocimiento del patrimonio documental del
                  AGN, así como para solicitar la retroalimentación del público
                  a partir de encuestas. Los reportes que genere el sistema de
                  reservaciones de actividades educativas, en los que se
                  asientan los datos personales recabados, son utilizados para
                  análisis estadístico y como medio de verificación para
                  corroborar los informes de resultados del área. Sus datos
                  personales se mantendrán bajo custodia y posesión exclusiva
                  del Departamento de Programas Educativos para la Ciudadanía y
                  solamente se permitirá acceso a las fuentes de información
                  original cuando, por proceso de auditoría, acto de
                  fiscalización o comprobación, sean requeridos por el área
                  competente al interior del AGN o por autoridades con
                  atribuciones en dichas materias.
                </h6>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="col-lg-6 col-md-12">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h4>Instrucciones </h4>
              </Accordion.Header>
              <Accordion.Body>
                <h6 className="txt_aviso">
                  Para concertar la reservación, realiza los siguientes pasos:{" "}
                  <br className="p-2" />
                  <br />
                  Los campos pueden cambiar de acuerdo con la actividad
                  seleccionada. <br />
                  El mínimo de personas por reservación es de 5 personas. <br />
                  El máximo de personas por reservación es de 40 personas.{" "}
                  <br /> <br />
                  1. Selecciona una actividad. Si eliges visita guiada y
                  cuentacuentos o visita guiada y taller, indica en qué momento
                  deseas tomar la actividad y selecciona el tema. Si eliges la
                  opción del Rally deberás seleccionar únicamente una fecha.{" "}
                  <br />
                  2. Selecciona la modalidad.
                  <br />
                  3. Selecciona la primera fecha y horario deseado para la
                  reservación.
                  <br />
                  4. Selecciona la segunda fecha y horario deseado para la
                  reservación.
                  <br />
                  5. Selecciona el número de mujeres o selecciona el sexo.{" "}
                  <br />
                  6. Selecciona el número de hombres o selecciona el sexo.{" "}
                  <br />
                  7. Selecciona el tipo de visitante.
                  <br />
                  8. Indica el nombre de la institución, en caso de ser
                  necesario.
                  <br />
                  9. Ingresa el código postal.
                  <br />
                  10. Selecciona el país.
                  <br />
                  11. Selecciona o indica la entidad federativa.
                  <br />
                  12. Selecciona o indica el municipio.
                  <br />
                  13. Selecciona o indica la colonia.
                  <br />
                  14. Selecciona el grado escolar del grupo. Si está conformado
                  por varios grados selecciona Grupo heterogéneo.
                  <br />
                  15. Ingresa el nombre del responsable.
                  <br />
                  16. Ingresa el primer apellido del responsable.
                  <br />
                  17. Ingresa el segundo apellido del responsable.
                  <br />
                  18. Ingresa el correo electrónico del responsable. Verifícalo
                  antes de reservar tu actividad, pues a este correo te será
                  enviada la confirmación de tu reservación.
                  <br />
                  19. Ingresa el número de teléfono del responsable.
                  <br />
                  20. Selecciona si el grupo visita por primera vez el AGN.
                  <br />
                  21. Selecciona si el grupo ingresara con vehículo.
                  <br />
                  22. Selecciona el tipo de vehículo.
                  <br />
                  23. Indica la cantidad de vehículos con los que ingresaras al
                  AGN.
                  <br />
                  24. Ingresa los números de placa de los vehículos.
                  <br />
                  25. Compártenos los intereses y expectativas que esperas de la
                  visita.
                  <br />
                  26. Selecciona cómo te enteraste sobre las actividades que
                  brinda el AGN.
                  <br />
                </h6>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      <div id="FormCitas">
        <Formik
          initialValues={reservacionesSelect}
          onSubmit={async (values) => {
            await Swal.fire({
              title: "Si tus datos son correctos, clic en el botón reservar",
              showDenyButton: true,
              confirmButtonText: "Reservar",
              denyButtonText: `Cancelar`,
              confirmButtonColor: "#13322B",
              denyButtonColor: "#9D2449",
            }).then(async (result) => {
              if (result.isConfirmed) {
                if (values.id_tipo_actividad == 6) {
                  //-----------Enviar la petición hacia el rally

                  const object = {
                    usuario_logeado: usuario_logeado?.id_mediador,
                    folio_anio: values.folio_anio,
                    folio_reservacion: "",
                    id_tipo_actividad: 6,
                    modalidad: "Presencial",
                    fecha_registro: "",
                    fecha_actividad: values.fecha_actividad,
                    horario_inicio: format(
                      new Date(horariosRally?.horario_inicio!),
                      "yyyy-MM-dd HH:mm:ss"
                    ),
                    horario_fin: format(
                      new Date(horariosRally?.horario_fin!),
                      "yyyy-MM-dd HH:mm:ss"
                    ),

                    descripcion_otro: values.descripcion_otro,

                    momento_act: values.momento_act,
                    id_cuento: null,
                    id_taller: null,
                    prim_vez: values.prim_vez,
                    enterado_por: values.enterado_por,
                    expectativas: values.expectativas,
                    grupo_rally: 1,
                    id_guia: "",
                    estado: "",
                    fecha_final: "",

                    tipo_grupo: values.tipo_grupo,
                    nombre_institucion: values.nombre_institucion,
                    sexo: values.sexo,
                    numero_muj: "",
                    numero_hom: "",
                    id_pais: values.id_pais ? values.id_pais : null,
                    id_estado: values.id_estado ? values.id_estado : null,
                    id_municipio: values.id_municipio
                      ? values.id_municipio
                      : null,
                    id_colonia: values.id_colonia ? values.id_colonia : null,
                    n_estado: values.n_estado,
                    n_municipio: values.n_municipio,
                    n_colonia: values.n_colonia,
                    codigo_postal: values.codigo_postal,
                    calle_num: Encrypt(values.calle_num),
                    grado_escolar: values.grado_escolar,

                    nombre: Encrypt(values.nombre),
                    ap_paterno: Encrypt(values.ap_paterno),
                    ap_materno: Encrypt(values.ap_materno),
                    correo_elec: Encrypt(values.correo_elec),
                    telefono: Encrypt(values.telefono),

                    tipo_vehiculo: values.tipo_vehiculos,
                    num_vehiculos: values.num_vehiculos,
                    placas: values.placas,
                    vehiculo: values.vehiculo,
                  };

                  await reservacionesPost(object);

                  //history('/');
                } else {
                  if (values.usuario_logeado_cat == "/admin/form") {
                  } else {
                    const cantidadPersonas =
                      Number(values.numero_hom) + Number(values.numero_muj);

                    if (cantidadPersonas < 5) {
                      return await Swal.fire({
                        title: "El número mínimo de personas es de 5",
                        icon: "error",
                        showDenyButton: false,
                        confirmButtonText: "Aceptar",
                        confirmButtonColor: "#B38E5D",
                        buttonsStyling: true,
                        color: "white",
                        background: "#13322B",
                      });
                    }

                    if (cantidadPersonas > 40) {
                      return await Swal.fire({
                        title: "El número maximo de personas es de 40",
                        icon: "error",
                        showDenyButton: false,
                        confirmButtonText: "Aceptar",
                        confirmButtonColor: "#B38E5D",
                        buttonsStyling: true,
                        color: "white",
                        background: "#13322B",
                      });
                    }
                  }

                  if (values.fecha_uno === values.fecha_dos) {
                    if (values.horario_uno === values.horario_dos) {
                      return await Swal.fire({
                        title: "Los horarios no pueden ser iguales",
                        icon: "error",
                        showDenyButton: false,
                        confirmButtonText: "Aceptar",
                        confirmButtonColor: "#B38E5D",
                        buttonsStyling: true,
                        color: "white",
                        background: "#13322B",
                      });
                    }
                  }

                  const horarioUno = values.horario_uno.split(" - ");
                  const horarioDos = values.horario_dos.split(" - ");

                  //-----------Enviar la petición hacia los grupos
                  const object = {
                    folio_reservacion: "",
                    id_tipo_actividad: values.id_tipo_actividad,
                    modalidad: values.modalidad,

                    fecha_uno: values.fecha_uno,
                    horario_uno_inicio:
                      format(new Date(), "yyyy-MM-dd") + " " + horarioUno[0],
                    horario_uno_fin:
                      format(new Date(), "yyyy-MM-dd") + " " + horarioUno[1],
                    fecha_dos: values.fecha_dos,
                    horario_dos_inicio:
                      format(new Date(), "yyyy-MM-dd") + " " + horarioDos[0],
                    horario_dos_fin:
                      format(new Date(), "yyyy-MM-dd") + " " + horarioDos[1],
                    descripcion_otro: values.descripcion_otro,

                    momento_act: values.momento_act,
                    id_cuento: values.id_cuento,
                    id_taller: values.id_taller,
                    prim_vez: values.prim_vez,
                    enterado_por: values.enterado_por,
                    expectativas: values.expectativas,

                    tipo_grupo: values.tipo_grupo,
                    nombre_institucion: values.nombre_institucion,
                    numero_muj: values.numero_muj,
                    numero_hom: values.numero_hom,
                    id_pais: values.id_pais ? values.id_pais : null,
                    id_estado: values.id_estado ? values.id_estado : null,
                    id_municipio: values.id_municipio
                      ? values.id_municipio
                      : null,
                    id_colonia: values.id_colonia ? values.id_colonia : null,
                    n_estado: values.n_estado,
                    n_municipio: values.n_municipio,
                    n_colonia: values.n_colonia,
                    codigo_postal: values.codigo_postal,
                    calle_num: Encrypt(values.calle_num),
                    grado_escolar: values.grado_escolar,

                    nombre: Encrypt(values.nombre),
                    ap_paterno: Encrypt(values.ap_paterno),
                    ap_materno: Encrypt(values.ap_materno),
                    correo_elec: Encrypt(values.correo_elec),
                    telefono: Encrypt(values.telefono),

                    tipo_vehiculo: values.tipo_vehiculos,
                    num_vehiculos: values.num_vehiculos,
                    placas: values.placas,
                    vehiculo: values.vehiculo,
                    usuario_logeado: usuario_logeado?.id_mediador,
                  };

                  await reservacionesPost(object);

                  //history('/');
                }
              } else if (result.isDenied) {
              }
            });
          }}
          enableReinitialize
          validationSchema={validaciones}
        >
          {({ handleChange, values, setFieldValue, handleBlur, errors }) => (
            <Form noValidate>
              <br />

              <small className="text-muted m-3 fs-5">
                {" "}
                * Campos Obligatorios{" "}
              </small>

              <h3>Datos de la actividad educativa </h3>
              <div className="row m-auto">
                <div className="col-md-9 col-sm-9 col-xs-12  m-auto p-2">
                  <FormRae.Group
                    controlId="id_tipo_actividad"
                    className=" m-auto  text-center"
                  >
                    <FormRae.Label>* Tipo de actividad</FormRae.Label>
                    <FormRae.Select
                      placeholder=""
                      onChange={(e) => {
                        setFieldValue("horario_uno", "");
                        setFieldValue("horario_dos", "");
                        setFieldValue("fecha_uno", "");
                        setFieldValue("fecha_dos", "");
                        setFieldValue("id_cuento", "");
                        setFieldValue("id_taller", "");
                        setFieldValue("numero_muj", "");
                        setFieldValue("numero_hom", "");

                        setFieldValue("vehiculo", false);
                        setFieldValue("tipo_vehiculo", false);
                        setFieldValue("num_vehiculos", "");
                        setFieldValue("placas", "");
                        handleChange(e);
                      }}
                      value={values.id_tipo_actividad || ""}
                    >
                      <option value="">
                        -- Selecciona un tipo de actividad --
                      </option>
                      {tipoActividad.map(
                        (tipoActividad: PropsActividad, index) => (
                          <option
                            value={tipoActividad.id_actividad}
                            key={index}
                          >
                            {" "}
                            {tipoActividad.nombre_actividad}{" "}
                          </option>
                        )
                      )}
                    </FormRae.Select>
                    <ErrorMessage
                      name="id_tipo_actividad"
                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                      component={"div"}
                    />
                  </FormRae.Group>
                </div>
              </div>

              {values.id_tipo_actividad == 6 ? (
                <div className="row m-auto">
                  <div className="col-md-9 col-sm-9 col-xs-12  m-auto p-2">
                    <FormRae.Group
                      controlId="modalidad"
                      className="m-auto text-center"
                    >
                      <FormRae.Label>* Modalidad</FormRae.Label>
                      <FormRae.Select
                        name="modalidad"
                        disabled
                        value="Presencial"
                      >
                        <option selected key="1" value="Presencial">
                          Presencial
                        </option>
                      </FormRae.Select>
                    </FormRae.Group>
                  </div>
                </div>
              ) : (
                <div className="row m-auto">
                  <div className="col-md-9 col-sm-9 col-xs-12  m-auto p-2">
                    <FormRae.Group
                      controlId="modalidad"
                      className="m-auto text-center"
                    >
                      <FormRae.Label>* Modalidad</FormRae.Label>
                      <FormRae.Select
                        name="modalidad"
                        onChange={handleChange}
                        value={values.modalidad || ""}
                      >
                        <option value="">-- Selecciona una modalidad --</option>
                        <option key="1" value="Presencial">
                          Presencial
                        </option>
                        <option key="2" value="Virtual">
                          Virtual
                        </option>
                      </FormRae.Select>

                      <ErrorMessage
                        name="modalidad"
                        className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                        component={"div"}
                      />
                    </FormRae.Group>
                  </div>
                </div>
              )}

              {values.id_tipo_actividad == 6 ? (
                <>
                  <div className="row m-auto d-flex">
                    <div className="col-md-6 col-sm-6 col-sm-12 col-xs-12  m-auto p-2">
                      <FormRae.Group
                        controlId="fecha_actividad_rally"
                        className=" p-1"
                      >
                        <FormRae.Label>* Fecha actividad</FormRae.Label>
                        <FormRae.Select
                          placeholder=""
                          name="fecha_actividad"
                          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            obtenerHorariosRallyGet(e.target.value);
                            handleChange(e);
                          }}
                          value={values.fecha_actividad || ""}
                        >
                          <option value="">
                            -- Seleccionar fecha de la actividad--
                          </option>
                          {diasRally.map((rally: PropsDiasRally, index) => (
                            <option value={rally.fecha_inicio} key={index}>
                              {" "}
                              {rally.fecha_inicio}{" "}
                            </option>
                          ))}
                        </FormRae.Select>
                        <ErrorMessage
                          name="fecha_actividad"
                          className="text-dark bg-dark rounded-2 bg-opacity-25 p-1 "
                          component={"div"}
                        />
                      </FormRae.Group>
                    </div>

                    <div className="col-md-6 col-sm-6 col-sm-12 col-xs-12  m-auto p-2">
                      <FormRae.Group
                        controlId="hora_actividad_rally"
                        className="p-1"
                      >
                        <FormRae.Label>* Horario actividad</FormRae.Label>
                        <FormRae.Control
                          type="text"
                          name="hora_actividad_rally"
                          onChange={handleChange}
                          value={
                            `${
                              horariosRally?.fecha_inicio
                                ? format(
                                    new Date(horariosRally?.horario_inicio!),
                                    "HH:mm"
                                  ) +
                                  " - " +
                                  format(
                                    new Date(horariosRally?.horario_fin!),
                                    "HH:mm"
                                  )
                                : ""
                            } ` || "ss"
                          }
                          placeholder="Horario actividad"
                          disabled
                        />
                      </FormRae.Group>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className=" mt-2 text-center">
                    <h6>
                      “Elige dos fechas tentativas para realizar tu actividad.
                      En función de lo programado, te confirmaremos una de ellas
                      o te haremos una propuesta.”
                    </h6>
                  </div>

                  <div className="row m-auto">
                    <div className="col-md-6 col-sm-6 col-sm-12 col-xs-12  m-auto p-2">
                      <FormRae.Group controlId="fecha_uno" className=" p-1">
                        <FormRae.Label>* Primer fecha</FormRae.Label>
                        <DatePicker
                          value={values.fecha_uno || ""}
                          name="fecha_uno"
                          onChange={(e: any) =>
                            setFieldValue(
                              "fecha_uno",
                              format(new Date(e), "yyyy-MM-dd")
                            )
                          }
                          minDate={new Date()}
                          maxDate={addMonths(new Date(), 12)}
                          filterDate={isWeekday}
                          highlightDates={highlightWithRanges}
                          showDisabledMonthNavigation
                          locale="es"
                          placeholderText="-- Selecciona una fecha --"
                          className="w-100 rounded-2  form-control "
                          dayClassName={(date: Date) => " rounded-4 "}
                          useWeekdaysShort
                          useShortMonthInDropdown
                          dateFormat={"yyyy-MM-dd"}
                        />
                        <ErrorMessage
                          name="fecha_uno"
                          className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                          component={"div"}
                        />
                      </FormRae.Group>
                    </div>
                    {values.id_tipo_actividad ? (
                      <>
                        {usuario_logeado ? (
                          <>
                            {values.id_tipo_actividad == 1 ? (
                              <>
                                <div className="col-md-6 col-sm-2 col-sm-12 col-xs-12  m-auto p-2">
                                  <FormRae.Group
                                    controlId="horario_uno"
                                    className=" p-1  "
                                  >
                                    <FormRae.Label>
                                      * Primer horario
                                    </FormRae.Label>
                                    <DatePicker
                                      value={values.horario_uno || ""}
                                      name="horario_uno"
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          "horario_uno",
                                          format(
                                            new Date(e),
                                            `HH:mm - ${format(
                                              addMinutes(new Date(e), 120),
                                              "HH:mm"
                                            )}`
                                          )
                                        );
                                      }}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        9
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        18
                                      )}
                                      timeCaption="Time"
                                      dateFormat="yyyy-MM-dd HH:mm"
                                      className=" rounded-2  form-control "
                                      placeholderText="-- Selcciona un horario --"
                                    />
                                    <ErrorMessage
                                      name="horario_uno"
                                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                      component={"div"}
                                    />
                                  </FormRae.Group>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {values.id_tipo_actividad == 2 ||
                            values.id_tipo_actividad == 3 ? (
                              <>
                                <div className="col-md-6 col-sm-2 col-sm-12 col-xs-12  m-auto p-2">
                                  <FormRae.Group
                                    controlId="horario_uno"
                                    className=" p-1  "
                                  >
                                    <FormRae.Label>
                                      * Primer horario
                                    </FormRae.Label>
                                    <DatePicker
                                      value={values.horario_uno || ""}
                                      name="horario_uno"
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          "horario_uno",
                                          format(
                                            new Date(e),
                                            `HH:mm - ${format(
                                              addMinutes(new Date(e), 180),
                                              "HH:mm"
                                            )}`
                                          )
                                        );
                                      }}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        9
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        18
                                      )}
                                      timeCaption="Time"
                                      dateFormat="yyyy-MM-dd HH:mm"
                                      className=" rounded-2  form-control "
                                      placeholderText="-- Selcciona un horario --"
                                    />
                                    <ErrorMessage
                                      name="horario_uno"
                                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                      component={"div"}
                                    />
                                  </FormRae.Group>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {values.id_tipo_actividad == 4 ||
                            values.id_tipo_actividad == 5 ? (
                              <>
                                <div className="col-md-6 col-sm-2 col-sm-12 col-xs-12  m-auto p-2">
                                  <FormRae.Group
                                    controlId="horario_uno"
                                    className=" p-1  "
                                  >
                                    <FormRae.Label>
                                      * Primer horario
                                    </FormRae.Label>
                                    <DatePicker
                                      value={values.horario_uno || ""}
                                      name="horario_uno"
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          "horario_uno",
                                          format(
                                            new Date(e),
                                            `HH:mm - ${format(
                                              addMinutes(new Date(e), 90),
                                              "HH:mm"
                                            )}`
                                          )
                                        );
                                      }}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        9
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        18
                                      )}
                                      timeCaption="Time"
                                      dateFormat="yyyy-MM-dd HH:mm"
                                      className=" rounded-2  form-control "
                                      placeholderText="-- Selcciona un horario --"
                                    />
                                    <ErrorMessage
                                      name="horario_uno"
                                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                      component={"div"}
                                    />
                                  </FormRae.Group>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <>
                            {values.id_tipo_actividad == 1 ? (
                              <>
                                <div className="col-md-6 col-sm-2 col-sm-12 col-xs-12  m-auto p-2">
                                  <FormRae.Group
                                    controlId="horario_uno"
                                    className=" p-1  "
                                  >
                                    <FormRae.Label>
                                      * Primer horario
                                    </FormRae.Label>
                                    <DatePicker
                                      value={values.horario_uno || ""}
                                      name="horario_uno"
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          "horario_uno",
                                          format(
                                            new Date(e),
                                            `HH:mm - ${format(
                                              addMinutes(new Date(e), 120),
                                              "HH:mm"
                                            )}`
                                          )
                                        );
                                      }}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        9
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        14
                                      )}
                                      timeCaption="Time"
                                      dateFormat="yyyy-MM-dd HH:mm"
                                      className=" rounded-2  form-control "
                                      placeholderText="-- Selcciona un horario --"
                                    />
                                    <ErrorMessage
                                      name="horario_uno"
                                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                      component={"div"}
                                    />
                                  </FormRae.Group>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {values.id_tipo_actividad == 2 ||
                            values.id_tipo_actividad == 3 ? (
                              <>
                                <div className="col-md-6 col-sm-2 col-sm-12 col-xs-12  m-auto p-2">
                                  <FormRae.Group
                                    controlId="horario_uno"
                                    className=" p-1  "
                                  >
                                    <FormRae.Label>
                                      * Primer horario
                                    </FormRae.Label>
                                    <DatePicker
                                      value={values.horario_uno || ""}
                                      name="horario_uno"
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          "horario_uno",
                                          format(
                                            new Date(e),
                                            `HH:mm - ${format(
                                              addMinutes(new Date(e), 180),
                                              "HH:mm"
                                            )}`
                                          )
                                        );
                                      }}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        9
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        14
                                      )}
                                      timeCaption="Time"
                                      dateFormat="yyyy-MM-dd HH:mm"
                                      className=" rounded-2  form-control "
                                      placeholderText="-- Selcciona un horario --"
                                    />
                                    <ErrorMessage
                                      name="horario_uno"
                                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                      component={"div"}
                                    />
                                  </FormRae.Group>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {values.id_tipo_actividad == 4 ||
                            values.id_tipo_actividad == 5 ? (
                              <>
                                <div className="col-md-6 col-sm-2 col-sm-12 col-xs-12  m-auto p-2">
                                  <FormRae.Group
                                    controlId="horario_uno"
                                    className=" p-1  "
                                  >
                                    <FormRae.Label>
                                      * Primer horario
                                    </FormRae.Label>
                                    <DatePicker
                                      value={values.horario_uno || ""}
                                      name="horario_uno"
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          "horario_uno",
                                          format(
                                            new Date(e),
                                            `HH:mm - ${format(
                                              addMinutes(new Date(e), 90),
                                              "HH:mm"
                                            )}`
                                          )
                                        );
                                      }}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        9
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        14
                                      )}
                                      timeCaption="Time"
                                      dateFormat="yyyy-MM-dd HH:mm"
                                      className=" rounded-2  form-control "
                                      placeholderText="-- Selcciona un horario --"
                                    />
                                    <ErrorMessage
                                      name="horario_uno"
                                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                      component={"div"}
                                    />
                                  </FormRae.Group>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="col-md-6 col-sm-2 col-sm-12 col-xs-12  m-auto p-2">
                          <FormRae.Group
                            controlId="horario_uno"
                            className=" p-1  "
                          >
                            <FormRae.Label>* Primer horario</FormRae.Label>
                            <DatePicker
                              value={values.horario_uno || ""}
                              name="horario_uno"
                              onChange={(e: any) => {
                                setFieldValue(
                                  "horario_uno",
                                  format(
                                    new Date(e),
                                    `HH:mm - ${format(
                                      addMinutes(new Date(e), 90),
                                      "HH:mm"
                                    )}`
                                  )
                                );
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              //timeIntervals={90}
                              minTime={setHours(setMinutes(new Date(), 0), 9)}
                              maxTime={setHours(setMinutes(new Date(), 30), 14)}
                              timeCaption="Time"
                              dateFormat="yyyy-MM-dd HH:mm"
                              className=" rounded-2  form-control "
                              placeholderText="-- Selcciona un horario --"
                            />
                            <ErrorMessage
                              name="horario_uno"
                              className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                              component={"div"}
                            />
                          </FormRae.Group>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="row m-auto">
                    <div className="col-md-6 col-sm-6 col-sm-12 col-xs-12  m-auto p-2">
                      <FormRae.Group controlId="fecha_dos" className=" p-1">
                        <FormRae.Label>* Segunda fecha</FormRae.Label>
                        <DatePicker
                          value={values.fecha_dos || ""}
                          name="fecha_dos"
                          onChange={(e: any) =>
                            setFieldValue(
                              "fecha_dos",
                              format(new Date(e), "yyyy-MM-dd")
                            )
                          }
                          minDate={new Date()}
                          maxDate={addMonths(new Date(), 12)}
                          filterDate={isWeekday}
                          highlightDates={highlightWithRanges}
                          showDisabledMonthNavigation
                          locale="es"
                          placeholderText="-- Selecciona una fecha --"
                          className="w-100 rounded-2  form-control "
                          dayClassName={(date: Date) => " rounded-4 "}
                          useWeekdaysShort
                          useShortMonthInDropdown
                          dateFormat={"yyyy-MM-dd"}
                        />
                        <ErrorMessage
                          name="fecha_dos"
                          className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                          component={"div"}
                        />
                      </FormRae.Group>
                    </div>
                    {values.id_tipo_actividad ? (
                      <>
                        {usuario_logeado ? (
                          <>
                            {values.id_tipo_actividad == 1 ? (
                              <>
                                <div className="col-md-6 col-sm-2 col-sm-12 col-xs-12  m-auto p-2">
                                  <FormRae.Group
                                    controlId="horario_dos"
                                    className=" p-1  "
                                  >
                                    <FormRae.Label>
                                      * Segundo horario dd
                                    </FormRae.Label>
                                    <DatePicker
                                      value={values.horario_dos || ""}
                                      name="horario_dos"
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          "horario_dos",
                                          format(
                                            new Date(e),
                                            `HH:mm - ${format(
                                              addMinutes(new Date(e), 120),
                                              "HH:mm"
                                            )}`
                                          )
                                        );
                                      }}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        9
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        18
                                      )}
                                      timeCaption="Time"
                                      dateFormat="yyyy-MM-dd HH:mm"
                                      className=" rounded-2  form-control "
                                      placeholderText="-- Selcciona un horario --"
                                    />
                                    <ErrorMessage
                                      name="horario_dos"
                                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                      component={"div"}
                                    />
                                  </FormRae.Group>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {values.id_tipo_actividad == 2 ||
                            values.id_tipo_actividad == 3 ? (
                              <>
                                <div className="col-md-6 col-sm-2 col-sm-12 col-xs-12  m-auto p-2">
                                  <FormRae.Group
                                    controlId="horario_dos"
                                    className=" p-1  "
                                  >
                                    <FormRae.Label>
                                      * Segundo horario
                                    </FormRae.Label>
                                    <DatePicker
                                      value={values.horario_dos || ""}
                                      name="horario_dos"
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          "horario_dos",
                                          format(
                                            new Date(e),
                                            `HH:mm - ${format(
                                              addMinutes(new Date(e), 180),
                                              "HH:mm"
                                            )}`
                                          )
                                        );
                                      }}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        9
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        18
                                      )}
                                      timeCaption="Time"
                                      dateFormat="yyyy-MM-dd HH:mm"
                                      className=" rounded-2  form-control "
                                      placeholderText="-- Selcciona un horario --"
                                    />
                                    <ErrorMessage
                                      name="horario_dos"
                                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                      component={"div"}
                                    />
                                  </FormRae.Group>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {values.id_tipo_actividad == 4 ||
                            values.id_tipo_actividad == 5 ? (
                              <>
                                <div className="col-md-6 col-sm-2 col-sm-12 col-xs-12  m-auto p-2">
                                  <FormRae.Group
                                    controlId="horario_dos"
                                    className=" p-1  "
                                  >
                                    <FormRae.Label>
                                      * Segundo horario
                                    </FormRae.Label>
                                    <DatePicker
                                      value={values.horario_dos || ""}
                                      name="horario_dos"
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          "horario_dos",
                                          format(
                                            new Date(e),
                                            `HH:mm - ${format(
                                              addMinutes(new Date(e), 90),
                                              "HH:mm"
                                            )}`
                                          )
                                        );
                                      }}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        9
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        18
                                      )}
                                      timeCaption="Time"
                                      dateFormat="yyyy-MM-dd HH:mm"
                                      className=" rounded-2  form-control "
                                      placeholderText="-- Selcciona un horario --"
                                    />
                                    <ErrorMessage
                                      name="horario_dos"
                                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                      component={"div"}
                                    />
                                  </FormRae.Group>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <>
                            {values.id_tipo_actividad == 1 ? (
                              <>
                                <div className="col-md-6 col-sm-2 col-sm-12 col-xs-12  m-auto p-2">
                                  <FormRae.Group
                                    controlId="horario_dos"
                                    className=" p-1  "
                                  >
                                    <FormRae.Label>
                                      * Segundo horario
                                    </FormRae.Label>
                                    <DatePicker
                                      value={values.horario_dos || ""}
                                      name="horario_dos"
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          "horario_dos",
                                          format(
                                            new Date(e),
                                            `HH:mm - ${format(
                                              addMinutes(new Date(e), 120),
                                              "HH:mm"
                                            )}`
                                          )
                                        );
                                      }}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        9
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        14
                                      )}
                                      timeCaption="Time"
                                      dateFormat="yyyy-MM-dd HH:mm"
                                      className=" rounded-2  form-control "
                                      placeholderText="-- Selcciona un horario --"
                                    />
                                    <ErrorMessage
                                      name="horario_dos"
                                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                      component={"div"}
                                    />
                                  </FormRae.Group>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {values.id_tipo_actividad == 2 ||
                            values.id_tipo_actividad == 3 ? (
                              <>
                                <div className="col-md-6 col-sm-2 col-sm-12 col-xs-12  m-auto p-2">
                                  <FormRae.Group
                                    controlId="horario_dos"
                                    className=" p-1  "
                                  >
                                    <FormRae.Label>
                                      * Segundo horario
                                    </FormRae.Label>
                                    <DatePicker
                                      value={values.horario_dos || ""}
                                      name="horario_dos"
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          "horario_dos",
                                          format(
                                            new Date(e),
                                            `HH:mm - ${format(
                                              addMinutes(new Date(e), 180),
                                              "HH:mm"
                                            )}`
                                          )
                                        );
                                      }}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        9
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        14
                                      )}
                                      timeCaption="Time"
                                      dateFormat="yyyy-MM-dd HH:mm"
                                      className=" rounded-2  form-control "
                                      placeholderText="-- Selcciona un horario --"
                                    />
                                    <ErrorMessage
                                      name="horario_dos"
                                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                      component={"div"}
                                    />
                                  </FormRae.Group>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {values.id_tipo_actividad == 4 ||
                            values.id_tipo_actividad == 5 ? (
                              <>
                                <div className="col-md-6 col-sm-2 col-sm-12 col-xs-12  m-auto p-2">
                                  <FormRae.Group
                                    controlId="horario_dos"
                                    className=" p-1  "
                                  >
                                    <FormRae.Label>
                                      * Segundo horario
                                    </FormRae.Label>
                                    <DatePicker
                                      value={values.horario_dos || ""}
                                      name="horario_dos"
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          "horario_dos",
                                          format(
                                            new Date(e),
                                            `HH:mm - ${format(
                                              addMinutes(new Date(e), 90),
                                              "HH:mm"
                                            )}`
                                          )
                                        );
                                      }}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      minTime={setHours(
                                        setMinutes(new Date(), 0),
                                        9
                                      )}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        14
                                      )}
                                      timeCaption="Time"
                                      dateFormat="yyyy-MM-dd HH:mm"
                                      className=" rounded-2  form-control "
                                      placeholderText="-- Selcciona un horario --"
                                    />
                                    <ErrorMessage
                                      name="horario_dos"
                                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                      component={"div"}
                                    />
                                  </FormRae.Group>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="col-md-6 col-sm-2 col-sm-12 col-xs-12  m-auto p-2">
                          <FormRae.Group
                            controlId="horario_dos"
                            className=" p-1  "
                          >
                            <FormRae.Label>* Segundo horario</FormRae.Label>
                            <DatePicker
                              value={values.horario_dos || ""}
                              name="horario_dos"
                              onChange={(e: any) => {
                                setFieldValue(
                                  "horario_dos",
                                  format(
                                    new Date(e),
                                    `HH:mm - ${format(
                                      addMinutes(new Date(e), 90),
                                      "HH:mm"
                                    )}`
                                  )
                                );
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              //timeIntervals={90}
                              minTime={setHours(setMinutes(new Date(), 0), 9)}
                              maxTime={setHours(setMinutes(new Date(), 30), 14)}
                              timeCaption="Time"
                              dateFormat="yyyy-MM-dd HH:mm"
                              className=" rounded-2  form-control "
                              placeholderText="-- Selcciona un horario --"
                            />
                            <ErrorMessage
                              name="horario_dos"
                              className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                              component={"div"}
                            />
                          </FormRae.Group>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}

              {values.id_tipo_actividad == 2 ||
              values.id_tipo_actividad == 3 ? (
                <div className="bg-secondary rounded-3 p-2 text-center m-lg-auto m-md-auto m-2 m-sm-auto col-md-10 col-sm-10 col-xs-12">
                  <FormRae.Label>
                    * ¿En qué momento desea tomar la actividad?
                  </FormRae.Label>
                  <br />
                  <div className="d-flex">
                    <FormRae.Group
                      controlId="momento_act_antes"
                      className="col-6 p-1"
                    >
                      <FormRae.Label> Antes del recorrido</FormRae.Label>
                      <FormRae.Check
                        type="radio"
                        name="momento_act"
                        onChange={handleChange}
                        value="Antes del recorrido"
                      />
                    </FormRae.Group>

                    <FormRae.Group
                      controlId="momento_act_desp"
                      className="col-6 p-1"
                    >
                      <FormRae.Label>Después del recorrido</FormRae.Label>
                      <FormRae.Check
                        type="radio"
                        name="momento_act"
                        onChange={handleChange}
                        value="Después del recorrido"
                      />
                    </FormRae.Group>
                  </div>

                  <ErrorMessage
                    name="momento_act"
                    className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                    component={"div"}
                  />
                </div>
              ) : (
                ""
              )}
              <br />

              {values.id_tipo_actividad == 5 ||
              values.id_tipo_actividad == 2 ? (
                <div className="bg-secondary rounded-3 p-2 text-center m-lg-auto m-md-auto m-2 m-sm-auto col-md-10 col-sm-10 col-xs-12">
                  <div className="d-flex">
                    <FormRae.Group controlId="id_cuento" className="col-12 p-1">
                      <FormRae.Label>* Tema del Cuentacuentos</FormRae.Label>
                      <FormRae.Select
                        name="id_cuento"
                        onChange={handleChange}
                        value={values.id_cuento || ""}
                      >
                        <option value="">
                          -- Selecciona el tema del cuentacuentos --
                        </option>
                        {cuentos.map((cuentos: PropsCuentos, index) => (
                          <option value={cuentos.id_cuento} key={index}>
                            {" "}
                            {cuentos.nombre_c}{" "}
                          </option>
                        ))}
                      </FormRae.Select>
                      <ErrorMessage
                        name="id_cuento"
                        className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                        component={"div"}
                      />
                    </FormRae.Group>
                  </div>
                </div>
              ) : (
                ""
              )}

              {values.id_tipo_actividad == 3 ||
              values.id_tipo_actividad == 4 ? (
                <div className="bg-secondary rounded-3 p-2 text-center m-lg-auto m-md-auto m-2 m-sm-auto col-md-10 col-sm-10 col-xs-12 ">
                  <div className="d-flex">
                    <FormRae.Group controlId="id_taller" className="col-12 p-1">
                      <FormRae.Label>* Tema del taller</FormRae.Label>
                      <FormRae.Select
                        name="id_taller"
                        onChange={handleChange}
                        value={values.id_taller || ""}
                      >
                        <option value="">
                          -- Selecciona el tema del taller --
                        </option>

                        {talleres.map((taller: PropsTalleres, index) => (
                          <option key={index} value={taller.id_taller}>
                            {" "}
                            {taller.nombre_t}{" "}
                          </option>
                        ))}
                      </FormRae.Select>

                      <ErrorMessage
                        name="id_taller"
                        className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                        component={"div"}
                      />
                    </FormRae.Group>
                  </div>
                  <br />
                </div>
              ) : (
                ""
              )}

              {values.id_tipo_actividad == 6 ? (
                <>
                  <FormRae.Label className="m-2">* Sexo</FormRae.Label>

                  <div className="d-flex- m-auto p-2">
                    <FormRae.Group controlId="sexo_hom" className="d-flex">
                      <FormRae.Check
                        type="radio"
                        name="sexo"
                        onChange={handleChange}
                        value="Mujer"
                        className="m-1"
                        formNoValidate
                      />
                      <FormRae.Label className="m-1"> Mujer </FormRae.Label>
                    </FormRae.Group>
                  </div>

                  <div className="  m-auto p-2">
                    <FormRae.Group controlId="sexo_muj" className="d-flex">
                      <FormRae.Check
                        type="radio"
                        name="sexo"
                        onChange={handleChange}
                        value="Hombre"
                        className="m-1"
                      />
                      <FormRae.Label className="m-1"> Hombre </FormRae.Label>
                    </FormRae.Group>
                  </div>

                  <ErrorMessage
                    name="sexo"
                    className="text-dark bg-dark rounded-2 bg-opacity-25 p-1 m-2"
                    component={"div"}
                  />
                </>
              ) : (
                <>
                  <div className=" mt-2 text-center">
                    <h6>
                      “Recuerda que el número máximo de personas por registro es
                      de 40. Si tu grupo es más grande, deberás realizar
                      registros separados.”
                    </h6>
                  </div>

                  {usuario_logeado ? (
                    <>
                      <div className="row m-auto">
                        <div className="col-md-6 col-sm-12 col-xs-12  m-auto p-2">
                          <FormRae.Group controlId="numero_muj">
                            <FormRae.Label>* Mujeres</FormRae.Label>
                            <FormRae.Control
                              type="text"
                              name="numero_muj"
                              onChange={(e) =>
                                setFieldValue(
                                  "numero_muj",
                                  e.target.value.replaceAll(/[A-Za-z]/g, "")
                                )
                              }
                              value={values.numero_muj}
                              placeholder="Ingresa el número de mujeres"
                              autoComplete="false"
                              maxLength={2}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              name="numero_muj"
                              className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                              component={"div"}
                            />
                          </FormRae.Group>
                        </div>

                        <div className="col-md-6 col-sm-12 col-xs-12  m-auto p-2">
                          <FormRae.Group controlId="numero_hom">
                            <FormRae.Label>* Hombres</FormRae.Label>
                            <FormRae.Control
                              type="text"
                              name="numero_hom"
                              onChange={(e) =>
                                setFieldValue(
                                  "numero_hom",
                                  e.target.value.replaceAll(/[A-Za-z]/g, "")
                                )
                              }
                              value={values.numero_hom}
                              placeholder="Ingresa el número de hombres"
                              maxLength={2}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              name="numero_hom"
                              className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                              component={"div"}
                            />
                          </FormRae.Group>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row m-auto">
                        <div className="col-md-6 col-sm-12 col-xs-12  m-auto p-2">
                          <FormRae.Group controlId="numero_muj">
                            <FormRae.Label>* Mujeres</FormRae.Label>
                            <FormRae.Select
                              placeholder=""
                              onChange={(e) =>
                                setFieldValue(
                                  "numero_muj",
                                  e.target.value.replaceAll(/[A-Za-z]/g, "")
                                )
                              }
                              value={values.numero_muj}
                            >
                              <option value="">
                                -- Selecciona el número de mujeres--
                              </option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                              <option value="24">24</option>
                              <option value="25">25</option>
                              <option value="26">26</option>
                              <option value="27">27</option>
                              <option value="28">28</option>
                              <option value="29">29</option>
                              <option value="30">30</option>
                              <option value="31">31</option>
                              <option value="32">32</option>
                              <option value="33">33</option>
                              <option value="34">34</option>
                              <option value="35">35</option>
                              <option value="36">36</option>
                              <option value="37">37</option>
                              <option value="38">38</option>
                              <option value="39">39</option>
                              <option value="40">40</option>
                            </FormRae.Select>
                            <ErrorMessage
                              name="numero_muj"
                              className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                              component={"div"}
                            />
                          </FormRae.Group>
                        </div>

                        <div className="col-md-6 col-sm-12 col-xs-12  m-auto p-2">
                          <FormRae.Group controlId="numero_hom">
                            <FormRae.Label>* Hombres</FormRae.Label>
                            <FormRae.Select
                              placeholder=""
                              onChange={(e) =>
                                setFieldValue(
                                  "numero_hom",
                                  e.target.value.replaceAll(/[A-Za-z]/g, "")
                                )
                              }
                              value={values.numero_hom}
                            >
                              <option value="">
                                -- Selecciona el número de hombres--
                              </option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                              <option value="21">21</option>
                              <option value="22">22</option>
                              <option value="23">23</option>
                              <option value="24">24</option>
                              <option value="25">25</option>
                              <option value="26">26</option>
                              <option value="27">27</option>
                              <option value="28">28</option>
                              <option value="29">29</option>
                              <option value="30">30</option>
                              <option value="31">31</option>
                              <option value="32">32</option>
                              <option value="33">33</option>
                              <option value="34">34</option>
                              <option value="35">35</option>
                              <option value="36">36</option>
                              <option value="37">37</option>
                              <option value="38">38</option>
                              <option value="39">39</option>
                              <option value="40">40</option>
                            </FormRae.Select>
                            <ErrorMessage
                              name="numero_hom"
                              className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                              component={"div"}
                            />
                          </FormRae.Group>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              <>
                <div className="text-center">
                  <h3>Información particular</h3>
                  <hr />
                </div>
                <div className="bg-secondary rounded-3 p-2 text-center m-lg-auto m-md-auto m-2 m-sm-auto col-md-10 col-sm-10 col-xs-12">
                  <FormRae.Label>* Tipo de visitante</FormRae.Label>

                  <div className="d-flex">
                    <FormRae.Group
                      controlId="tipo_grupo_part"
                      className="col-6 p-1"
                    >
                      <FormRae.Label>Particular</FormRae.Label>
                      <FormRae.Check
                        type="radio"
                        name="tipo_grupo"
                        onChange={(e) => {
                          setFieldValue("nombre_institucion", "");
                          handleChange(e);
                        }}
                        value="Particular"
                      />
                    </FormRae.Group>

                    <FormRae.Group
                      controlId="tipo_grupo_inst"
                      className="col-6 p-1"
                    >
                      <FormRae.Label>Institucional</FormRae.Label>
                      <FormRae.Check
                        type="radio"
                        name="tipo_grupo"
                        onChange={handleChange}
                        value="Institucional"
                      />
                    </FormRae.Group>
                  </div>
                  <ErrorMessage
                    name="tipo_grupo"
                    className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                    component={"div"}
                  />
                </div>{" "}
                <br />
              </>

              {values.tipo_grupo == "Institucional" ? (
                <>
                  <FormRae.Group
                    controlId="nombre_institucion"
                    className="mb-1 p-2"
                  >
                    <FormRae.Label>* Nombre de la institución</FormRae.Label>
                    <FormRae.Control
                      type="text"
                      name="nombre_institucion"
                      onChange={handleChange}
                      value={values.nombre_institucion || ""}
                      placeholder="Ingresa el nombre de tu institución"
                      maxLength={200}
                    />
                    <ErrorMessage
                      name="nombre_institucion"
                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                      component={"div"}
                    />
                  </FormRae.Group>
                </>
              ) : (
                <></>
              )}

              <div className="d-flex ">
                <FormRae.Group controlId="codigo_postal" className="col-12 p-1">
                  <FormRae.Label>* Código postal </FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="codigo_postal"
                    value={values.codigo_postal || ""}
                    onChange={(e) =>
                      setFieldValue(
                        "codigo_postal",
                        e.target.value.replaceAll(/[A-Za-z]/g, "")
                      )
                    }
                    placeholder="Ingresa tu código postal"
                    maxLength={5}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="codigo_postal"
                    className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                    component={"div"}
                  />
                </FormRae.Group>
              </div>

              <div className="row m-auto">
                <div className="col-md-6 col-sm-12 col-xs-12  m-auto p-2">
                  <FormRae.Group controlId="id_pais">
                    <FormRae.Label>* País</FormRae.Label>
                    <FormRae.Select
                      name="id_pais"
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        setFieldValue("id_estado", "");
                        setFieldValue("id_municipio", "");
                        setFieldValue("id_colonia", "");
                        setFieldValue("n_estado", "");
                        setFieldValue("n_colonia", "");
                        setFieldValue("n_municipio", "");

                        handleChange(e);
                      }}
                      value={values.id_pais || ""}
                    >
                      <option value="">-- Selecciona tu país --</option>
                      <option key="1" value="146">
                        {" "}
                        México{" "}
                      </option>
                      {paises.map((paises: PropsPaises, index) => (
                        <option key={index} value={paises.id_pais!}>
                          {" "}
                          {paises.nombre_pais!}{" "}
                        </option>
                      ))}
                    </FormRae.Select>
                  </FormRae.Group>
                  <ErrorMessage
                    name="id_pais"
                    className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                    component={"div"}
                  />
                </div>

                {values.id_pais == 146 ? (
                  <>
                    <div className="col-md-6 col-sm-12 col-xs-12 m-auto p-2">
                      <FormRae.Group controlId="id_estado">
                        <FormRae.Label>* Entidad federativa</FormRae.Label>
                        <FormRae.Select
                          name="id_estado"
                          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            setFieldValue("id_municipio", "");
                            setFieldValue("id_colonia", "");
                            handleChange(e);
                            municipiosGet(Number(e.target.value));
                          }}
                          value={values.id_estado || ""}
                        >
                          <option value="">
                            -- Selecciona tu entidad federativa --
                          </option>
                          {estados.map((estados: PropsEstados, index) => (
                            <option key={index} value={estados.id_estado}>
                              {" "}
                              {estados.nombre_estado}{" "}
                            </option>
                          ))}
                        </FormRae.Select>
                        <ErrorMessage
                          name="id_estado"
                          className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                          component={"div"}
                        />
                      </FormRae.Group>
                    </div>

                    <div className="col-md-6 col-sm-12 col-xs-12 m-auto p-2">
                      <FormRae.Group controlId="id_municipio">
                        <FormRae.Label>* Municipio o Alcaldía</FormRae.Label>
                        <FormRae.Select
                          name="id_municipio"
                          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            setFieldValue("id_colonia", "");
                            handleChange(e);
                            coloniasGet(Number(e.target.value));
                          }}
                          value={values.id_municipio}
                        >
                          <option value="">
                            -- Selecciona tu municipio o alcaldia --
                          </option>
                          {municipios.map(
                            (municipio: PropsMunicipios, index) => (
                              <option
                                value={municipio.id_municipio}
                                key={index}
                              >
                                {" "}
                                {municipio.nombre_municipio}{" "}
                              </option>
                            )
                          )}
                        </FormRae.Select>
                        <ErrorMessage
                          name="id_municipio"
                          className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                          component={"div"}
                        />
                      </FormRae.Group>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12  m-auto p-2">
                      <FormRae.Group controlId="id_colonia">
                        <FormRae.Label> * Colonia</FormRae.Label>
                        <FormRae.Select
                          name="id_colonia"
                          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            setFieldValue("id_colonia", e.target.value);
                          }}
                          value={values.id_colonia}
                        >
                          <option value="">-- Selecciona tu colonia --</option>
                          {colonias.map((colonia: PropsColonias, index) => (
                            <option value={colonia.id_colonia} key={index}>
                              {" "}
                              {colonia.nombre_colonia}{" "}
                            </option>
                          ))}
                        </FormRae.Select>
                        <ErrorMessage
                          name="id_colonia"
                          className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                          component={"div"}
                        />
                      </FormRae.Group>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-6 col-sm-12 col-xs-12 m-auto p-2">
                      <FormRae.Group controlId="n_estado">
                        <FormRae.Label>* Entidad federativa</FormRae.Label>
                        <FormRae.Control
                          name="n_estado"
                          onChange={handleChange}
                          value={values.n_estado || ""}
                          placeholder="Ingresa tu entidad federativa"
                        ></FormRae.Control>
                        <ErrorMessage
                          name="n_estado"
                          className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                          component={"div"}
                        />
                      </FormRae.Group>
                    </div>

                    <div className="col-md-6 col-sm-12 col-xs-12 m-auto p-2">
                      <FormRae.Group controlId="n_municipio">
                        <FormRae.Label>* Municipio o Alcaldía</FormRae.Label>
                        <FormRae.Control
                          name="n_municipio"
                          onChange={handleChange}
                          value={values.n_municipio || ""}
                          placeholder="Ingresa tu municipio o alcaldía"
                        ></FormRae.Control>
                        <ErrorMessage
                          name="n_municipio"
                          className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                          component={"div"}
                        />
                      </FormRae.Group>
                    </div>

                    <div className="col-md-6 col-sm-12 col-xs-12 m-auto p-2">
                      <FormRae.Group controlId="n_colonia">
                        <FormRae.Label>* Colonia </FormRae.Label>
                        <FormRae.Control
                          name="n_colonia"
                          onChange={handleChange}
                          value={values.n_colonia || ""}
                          placeholder="Ingresa tu colonia"
                        ></FormRae.Control>
                        <ErrorMessage
                          name="n_colonia"
                          className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                          component={"div"}
                        />
                      </FormRae.Group>
                    </div>
                  </>
                )}
              </div>
              <br />

              <div className="d-flex ">
                <FormRae.Group controlId="calle_num" className="col-12 p-1">
                  <FormRae.Label>* Calle y número</FormRae.Label>
                  <FormRae.Control
                    type="text"
                    name="calle_num"
                    onChange={handleChange}
                    value={values.calle_num}
                    placeholder="Ingresa tu calle y número interior o exterior"
                  />
                  <ErrorMessage
                    name="calle_num"
                    className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                    component={"div"}
                  />
                </FormRae.Group>
              </div>
              <br />

              {!rally ? (
                <FormRae.Label className="m-1">
                  * Grado escolar del grupo
                </FormRae.Label>
              ) : (
                <FormRae.Label className="m-1">* Grado escolar</FormRae.Label>
              )}

              <div className="row m-auto ">
                <div className="col-lg-3  col-md-4 col-xs-6 col-sm-6  m-auto p-2">
                  <FormRae.Group
                    controlId="grado_escolar_prescolar"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="Prescolar"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1"> Prescolar </FormRae.Label>
                  </FormRae.Group>
                </div>
                <div className="col-lg-3  col-md-4 col-xs-6 col-sm-6 m-auto p-2">
                  <FormRae.Group
                    controlId="grado_escolar_1ro_primaria"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="1° Primaria"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1"> 1° Primaria </FormRae.Label>
                  </FormRae.Group>
                </div>
                <div className="col-lg-3  col-md-4 col-xs-6 col-sm-6  m-auto p-2 ">
                  <FormRae.Group
                    controlId="grado_escolar_2do_primaria"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="2° Primaria"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1"> 2° Primaria </FormRae.Label>
                  </FormRae.Group>
                </div>
                <div className="col-lg-3  col-md-4 col-xs-6 col-sm-6  m-auto p-2">
                  <FormRae.Group
                    controlId="grado_escolar_3ro_primaria"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="3° Primaria"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1"> 3° Primaria </FormRae.Label>
                  </FormRae.Group>
                </div>
                <div className="col-lg-3  col-md-4 col-xs-6 col-sm-6  m-auto p-2">
                  <FormRae.Group
                    controlId="grado_escolar_4to_primaria"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="4° Primaria"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1"> 4° Primaria </FormRae.Label>
                  </FormRae.Group>
                </div>
                <div className="col-lg-3  col-md-4 col-xs-6 col-sm-6  m-auto p-2">
                  <FormRae.Group
                    controlId="grado_escolar_5to_primaria"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="5° Primaria"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1"> 5° Primaria </FormRae.Label>
                  </FormRae.Group>
                </div>
                <div className="col-lg-3  col-md-4 col-xs-6 col-sm-6  m-auto p-2">
                  <FormRae.Group
                    controlId="grado_escolar_6to_primaria"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="6° Primaria"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1"> 6° Primaria </FormRae.Label>
                  </FormRae.Group>
                </div>
                <div className="col-lg-3  col-md-4 col-xs-6 col-sm-6  m-auto p-2">
                  <FormRae.Group
                    controlId="grado_escolar_1ro_secundaria"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="1° Secundaria"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1">
                      {" "}
                      1° Secundaria{" "}
                    </FormRae.Label>
                  </FormRae.Group>
                </div>
                <div className="col-lg-3  col-md-4 col-xs-6 col-sm-6  m-auto p-2">
                  <FormRae.Group
                    controlId="grado_escolar_2do_secundaria"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="2° Secundaria"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1">
                      {" "}
                      2° Secundaria{" "}
                    </FormRae.Label>
                  </FormRae.Group>
                </div>
                <div className="col-lg-3  col-md-4 col-xs-6 col-sm-6  m-auto p-2">
                  <FormRae.Group
                    controlId="grado_escolar_3ro_secundaria"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="3° Secundaria"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1">
                      {" "}
                      3° Secundaria{" "}
                    </FormRae.Label>
                  </FormRae.Group>
                </div>

                <div className="col-lg-3  col-md-4 col-xs-6 col-sm-6  m-auto p-2">
                  <FormRae.Group
                    controlId="grado_escolar_1ro_prepa"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="1° Preparatoria"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1">
                      {" "}
                      1° Preparatoria{" "}
                    </FormRae.Label>
                  </FormRae.Group>
                </div>
                <div className="col-lg-3  col-md-4 col-xs-6 col-sm-6  m-auto p-2">
                  <FormRae.Group
                    controlId="grado_escolar_2do_prepa"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="2° Preparatoria"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1">
                      {" "}
                      2° Preparatoria{" "}
                    </FormRae.Label>
                  </FormRae.Group>
                </div>

                <div className="col-lg-3  col-md-4 col-xs-6 col-sm-6  m-auto p-2">
                  <FormRae.Group
                    controlId="grado_escolar_3ro_prepa"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="3° Preparatoria"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1">
                      {" "}
                      3° Preparatoria{" "}
                    </FormRae.Label>
                  </FormRae.Group>
                </div>
                <div className="col-lg-3  col-md-4 col-xs-6 col-sm-6  m-auto p-2">
                  <FormRae.Group controlId="lic" className="d-flex">
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="Licenciatura"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1">
                      {" "}
                      Licenciatura{" "}
                    </FormRae.Label>
                  </FormRae.Group>
                </div>
                <div className="col-lg-3  col-md-4 col-xs-6 col-sm-6  m-auto p-2">
                  <FormRae.Group controlId="posgrado" className="d-flex">
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="Posgrado"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1"> Posgrado </FormRae.Label>
                  </FormRae.Group>
                </div>
                <div className="col-lg-3  col-md-6 col-xs-6 col-sm-6  m-auto p-2">
                  <FormRae.Group controlId="hetero" className="d-flex">
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="Grupo heterogéneo"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1">
                      {" "}
                      Grupo heterogéneo{" "}
                    </FormRae.Label>
                  </FormRae.Group>
                </div>
                <div className="col-lg-12  col-md-6 col-xs-6 col-sm-6  m-auto p-2">
                  <FormRae.Group controlId="no_aplica" className="d-flex">
                    <FormRae.Check
                      type="radio"
                      name="grado_escolar"
                      onChange={handleChange}
                      value="NO APLICA"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1"> NO APLICA </FormRae.Label>
                  </FormRae.Group>
                </div>
                <ErrorMessage
                  name="grado_escolar"
                  className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                  component={"div"}
                />
              </div>

              <br />
              <div className="text-center">
                <h3> Datos del responsable </h3>
                <hr />
              </div>

              <FormRae.Group controlId="nombre" className="mb-1 p-2">
                <FormRae.Label>* Nombre(s)</FormRae.Label>
                <FormRae.Control
                  type="text"
                  name="nombre"
                  onChange={(e) =>
                    setFieldValue(
                      "nombre",
                      e.target.value.replaceAll(/[0-9]/g, "")
                    )
                  }
                  value={values.nombre}
                  placeholder="Ingresa tu nombre"
                  maxLength={300}
                />
                <ErrorMessage
                  name="nombre"
                  className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                  component={"div"}
                />
              </FormRae.Group>
              <FormRae.Group controlId="ap_paterno" className="mb-1 p-2">
                <FormRae.Label>* Primer apellido</FormRae.Label>
                <FormRae.Control
                  type="text"
                  name="ap_paterno"
                  onChange={(e) =>
                    setFieldValue(
                      "ap_paterno",
                      e.target.value.replaceAll(/[0-9]/g, "")
                    )
                  }
                  value={values.ap_paterno}
                  placeholder="Ingresa tu primer apellido"
                />
                <ErrorMessage
                  name="ap_paterno"
                  className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                  component={"div"}
                />
              </FormRae.Group>

              <FormRae.Group controlId="ap_materno" className="mb-1 p-2">
                <FormRae.Label>* Segundo apellido</FormRae.Label>
                <FormRae.Control
                  type="text"
                  name="ap_materno"
                  onChange={(e) =>
                    setFieldValue(
                      "ap_materno",
                      e.target.value.replaceAll(/[0-9]/g, "")
                    )
                  }
                  value={values.ap_materno}
                  placeholder="Ingresa tu segundo apellido"
                />
                <ErrorMessage
                  name="ap_materno"
                  className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                  component={"div"}
                />
              </FormRae.Group>

              <FormRae.Group controlId="correo_elec" className="mb-1 p-2">
                <FormRae.Label>* Correo electrónico</FormRae.Label>
                <FormRae.Control
                  type="text"
                  name="correo_elec"
                  onChange={handleChange}
                  value={values.correo_elec}
                  placeholder="Ingresa tu correo electrónico"
                />
                <ErrorMessage
                  name="correo_elec"
                  className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                  component={"div"}
                />
              </FormRae.Group>

              <FormRae.Group controlId="telefono" className="mb-1 p-2">
                <FormRae.Label>* Número de teléfono</FormRae.Label>
                <FormRae.Control
                  type="text"
                  name="telefono"
                  onChange={(e) =>
                    setFieldValue(
                      "telefono",
                      e.target.value.replaceAll(/[A-Za-z]/g, "")
                    )
                  }
                  value={values.telefono}
                  placeholder="Ingresa tu número de teléfono"
                  maxLength={10}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name="telefono"
                  className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                  component={"div"}
                />
              </FormRae.Group>
              <br />

              <div className="text-center">
                <h3> Datos generales </h3>
                <hr />
              </div>

              <div className="bg-secondary rounded-3 p-2 text-center m-lg-auto m-md-auto m-2 m-sm-auto col-md-10 col-sm-10 col-xs-12">
                <FormRae.Label>* ¿Visita por primera vez al AGN?</FormRae.Label>

                <div className="row  m-auto ">
                  <div className="col-6">
                    <FormRae.Group
                      controlId="prim_vez_si"
                      className="d-flex justify-content-center"
                    >
                      <FormRae.Check
                        type="radio"
                        name="prim_vez"
                        onChange={handleChange}
                        value="Si"
                        className="m-1"
                      />
                      <FormRae.Label className="m-1"> Sí </FormRae.Label>
                    </FormRae.Group>
                  </div>
                  <div className="col-6">
                    <FormRae.Group
                      controlId="prim_vez_no"
                      className="d-flex justify-content-center"
                    >
                      <FormRae.Check
                        type="radio"
                        name="prim_vez"
                        onChange={handleChange}
                        value="No"
                        className="m-1"
                      />
                      <FormRae.Label className="m-1"> No </FormRae.Label>
                    </FormRae.Group>
                  </div>
                  <ErrorMessage
                    name="prim_vez"
                    className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                    component={"div"}
                  />
                </div>
              </div>
              <br />

              {values.modalidad == "Presencial" ||
              values.id_tipo_actividad == 6 ? (
                <>
                  <div className="bg-secondary rounded-3 p-2 text-center m-lg-auto m-md-auto m-2 m-sm-auto col-md-10 col-sm-10 col-xs-12">
                    <FormRae.Label>
                      * Para visitas presenciales ¿Ingresará con vehículo?{" "}
                    </FormRae.Label>

                    <div className="row  m-auto mb-3">
                      <div className="col-6">
                        <FormRae.Group
                          controlId="vehiculo_si"
                          className="d-flex justify-content-center"
                        >
                          <FormRae.Check
                            type="radio"
                            name="ingresa_vehiculo"
                            onChange={handleChange}
                            value="Si"
                            className="m-1"
                          />
                          <FormRae.Label className="m-1"> Sí </FormRae.Label>
                        </FormRae.Group>
                      </div>
                      <div className="col-6">
                        <FormRae.Group
                          controlId="vehiculo_no"
                          className="d-flex justify-content-center"
                        >
                          <FormRae.Check
                            type="radio"
                            name="ingresa_vehiculo"
                            onChange={handleChange}
                            value="No"
                            className="m-1"
                          />
                          <FormRae.Label className="m-1"> No </FormRae.Label>
                        </FormRae.Group>
                      </div>
                      <ErrorMessage
                        name="ingresa_vehiculo"
                        className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                        component={"div"}
                      />
                    </div>
                    <br />
                    {values.ingresa_vehiculo == "Si" ? (
                      <>
                        <FormRae.Label>
                          Selecciona el tipo de vehículo{" "}
                        </FormRae.Label>

                        <div className="row m-auto">
                          <div className="col-md-6 col-sm-6 col-6 col-sm-6 m-auto p-2">
                            <FormRae.Group
                              controlId="tipo_vehiculo_aut"
                              className="d-flex justify-content-center"
                            >
                              <FormRae.Check
                                type="radio"
                                name="tipo_vehiculos"
                                onChange={handleChange}
                                value="Autobús"
                                className="m-1"
                              />
                              <FormRae.Label className="m-1">
                                {" "}
                                Autobús{" "}
                              </FormRae.Label>
                            </FormRae.Group>
                          </div>

                          <div className="col-md-6 col-sm-6 col-6 col-sm-6 m-auto p-2">
                            <FormRae.Group
                              controlId="tipo_vehiculo_car"
                              className="d-flex justify-content-center"
                            >
                              <FormRae.Check
                                type="radio"
                                name="tipo_vehiculos"
                                onChange={handleChange}
                                value="Automóvil"
                                className="m-1"
                              />
                              <FormRae.Label className="m-1">
                                {" "}
                                Automóvil{" "}
                              </FormRae.Label>
                            </FormRae.Group>
                          </div>
                          <ErrorMessage
                            name="tipo_vehiculos"
                            className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                            component={"div"}
                          />
                        </div>

                        <div className="row m-auto">
                          <div className="col-md-6 col-sm-6 col-12 col-sm-6 m-auto p-2">
                            {values.id_tipo_actividad == 6 ? (
                              <>
                                <FormRae.Group
                                  controlId="num_vehiculos"
                                  className="col-12 p-1"
                                >
                                  <FormRae.Label>
                                    Número de vehículos
                                  </FormRae.Label>
                                  <FormRae.Select
                                    name="num_vehiculos"
                                    onChange={handleChange}
                                    value={values.num_vehiculos || ""}
                                  >
                                    <option value="">
                                      -- Selecciona el número de vehículos --
                                    </option>
                                    <option value="1">1</option>
                                  </FormRae.Select>
                                  <ErrorMessage
                                    name="num_vehiculos"
                                    className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                    component={"div"}
                                  />
                                </FormRae.Group>
                              </>
                            ) : (
                              <>
                                <FormRae.Group
                                  controlId="num_vehiculos"
                                  className="col-12 p-1"
                                >
                                  <FormRae.Label>
                                    Número de vehículos
                                  </FormRae.Label>
                                  <FormRae.Select
                                    name="num_vehiculos"
                                    onChange={handleChange}
                                    value={values.num_vehiculos || ""}
                                  >
                                    <option value="">
                                      -- Selecciona el número de vehículos --
                                    </option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                  </FormRae.Select>
                                  <ErrorMessage
                                    name="num_vehiculos"
                                    className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                    component={"div"}
                                  />
                                </FormRae.Group>
                              </>
                            )}
                          </div>
                          <div className="col-md-6 col-sm-6 col-12 col-sm-6 m-auto p-2">
                            <FormRae.Group controlId="placas" className=" p-1">
                              <FormRae.Label>Placas</FormRae.Label>
                              <FormRae.Control
                                type="text"
                                name="placas"
                                onChange={(e) =>
                                  setFieldValue(
                                    "placas",
                                    e.target.value.toUpperCase()
                                  )
                                }
                                value={values.placas}
                                placeholder="Ingresa las placas de los vehículos"
                              />
                              <ErrorMessage
                                name="placas"
                                className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                                component={"div"}
                              />
                            </FormRae.Group>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>{" "}
                  <br />
                </>
              ) : (
                ""
              )}

              <FormRae.Group controlId="espectativas" className="m-3">
                <FormRae.Label>
                  Para brindarte una atención más personalizada, compártenos tus
                  intereses y expectativas{" "}
                </FormRae.Label>
                <FormRae.Control
                  as="textarea"
                  type="text"
                  name="expectativas"
                  onChange={handleChange}
                  value={values.expectativas}
                  style={{ height: "150px" }}
                  placeholder="Ingresa tus interes y expectativas"
                />
                <ErrorMessage
                  name="expectativas"
                  className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                  component={"div"}
                />
              </FormRae.Group>

              <FormRae.Group controlId="enterado_por" className="m-3">
                <FormRae.Label>
                  * ¿Cómo te enteraste de las actividades educativas que brinda
                  el AGN?
                </FormRae.Label>

                <div className="col-12">
                  <FormRae.Group
                    controlId="enterado_por_venido"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="enterado_por"
                      onChange={(e) => {
                        setFieldValue("descripcion_otro", "");
                        handleChange(e);
                      }}
                      value="Ya había venido al AGN"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1">
                      {" "}
                      Ya había venido al AGN{" "}
                    </FormRae.Label>
                  </FormRae.Group>
                </div>

                <div className="col-12">
                  <FormRae.Group
                    controlId="enterado_por_recomendacion"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="enterado_por"
                      onChange={(e) => {
                        setFieldValue("descripcion_otro", "");
                        handleChange(e);
                      }}
                      value="Por recomendación"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1">
                      {" "}
                      Por recomendación{" "}
                    </FormRae.Label>
                  </FormRae.Group>
                </div>

                <div className="col-12">
                  <FormRae.Group
                    controlId="enterado_por_redes"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="enterado_por"
                      onChange={(e) => {
                        setFieldValue("descripcion_otro", "");
                        handleChange(e);
                      }}
                      value="Por las redes sociales del AGN "
                      className="m-1"
                    />
                    <FormRae.Label className="m-1">
                      {" "}
                      Por las redes sociales del AGN{" "}
                    </FormRae.Label>
                  </FormRae.Group>
                </div>

                <div className="col-12 ">
                  <FormRae.Group
                    controlId="enterado_por_otro"
                    className="d-flex"
                  >
                    <FormRae.Check
                      type="radio"
                      name="enterado_por"
                      onChange={(e) => {
                        setFieldValue("descripcion_otro", "");
                        handleChange(e);
                      }}
                      value="Otro"
                      className="m-1"
                    />
                    <FormRae.Label className="m-1">
                      {" "}
                      Otro (especificar){" "}
                    </FormRae.Label>
                  </FormRae.Group>
                </div>
                <ErrorMessage
                  name="enterado_por"
                  className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                  component={"div"}
                />

                {values.enterado_por === "Otro" ? (
                  <>
                    <div className="col-12  p-2">
                      <FormRae.Group
                        controlId="descripcion_otro"
                        className="d-flex"
                      >
                        <FormRae.Control
                          type="text"
                          name="descripcion_otro"
                          onChange={handleChange}
                          value={values.descripcion_otro || ""}
                          className=""
                          placeholder="Especificar otro"
                        />
                      </FormRae.Group>
                      <ErrorMessage
                        name="descripcion_otro"
                        className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                        component={"div"}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </FormRae.Group>

              <br />
              {usuario_logeado ? (
                ""
              ) : (
                <>
                  <div className="bg-">
                    <ReCAPTCHA
                      ref={captcha}
                      sitekey={`6LcSFk8jAAAAAIjwdS1L-azNjxNC-Mth8ZHjxIGB`}
                      onChange={(e) => {
                        setFieldValue(
                          "captcha",
                          "6LcSFk8jAAAAAIjwdS1L-azNjxNC-Mth8ZHjxIGB"
                        );
                        handleChange(e);
                      }}
                    />
                  </div>

                  <FormRae.Group controlId="captcha" className="d-none">
                    <FormRae.Control
                      type="text"
                      name="captcha"
                      onChange={handleChange}
                      value={values.captcha || ""}
                      className=""
                      placeholder="captcha"
                    />
                  </FormRae.Group>
                </>
              )}

              <ErrorMessage
                name="captcha"
                className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                component={"div"}
              />
              <br />
              <div className="text-center">
                <Button
                  variant="danger"
                  type="submit"
                  onClick={() => {
                    {
                      JSON.stringify(errors) !== "{}" &&
                        Swal.fire({
                          title:
                            "Los campos marcados con * son obligatorios, por favor verifique",
                          icon: "error",
                          showDenyButton: false,
                          confirmButtonText: "Aceptar",
                          confirmButtonColor: "#B38E5D",
                          buttonsStyling: true,
                          color: "white",
                          background: "#13322B",
                        });
                    }
                  }}
                >
                  Reservar
                </Button>
              </div>
              <br />

              {JSON.stringify(errors) !== "{}" && (
                <div className="text-dark bg-dark rounded-2 bg-opacity-25 p-1">
                  Los campos marcados con * son obligatorios, por favor
                  verifique.
                </div>
              )}
            </Form>
          )}
        </Formik>

        <br />
      </div>
    </>
  );
};

export default FormDiasRally;
