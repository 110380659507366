import React, { useEffect } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { loginHeader } from '../Config/Login'
import Logo from "../Complementos/Img/Logo_128x50.png";
import Placa from "../Complementos/Img/pleca_verde.svg";

import "../index.scss"

import { Accordion } from 'react-bootstrap';

import { ImTwitter } from "react-icons/im";
import { ImFacebook } from "react-icons/im";

const LayoutPublic = () => {

  useEffect(() => {
    loginHeader({ tipo_usuario: 2 })
  }, [])
/*  m-auto text-lg-end text-sm-center */

  return (
    <div className='wrapper flex-grow-1 vh-100'>

      <div className="bg-primary d-flex w-100 vh-25"  >
        <div className='row w-100 p-0 m-0'>
          <div className='col-lg-2 col-md-2 col-sm-12  text-center p-3 m-0'>
            <a href="https://www.gob.mx/" >  
            <img
              src={Logo}
              alt="Responsive image"
              className="img-fluid "
              loading="lazy"
              style={{ height: "40px", minHeight: '40px', minWidth: '120px' }}
            />
            </a>
          </div>

          <div className="m-auto col-lg-10 col-md-10 col-sm-12  ">
            <h5 className="tit fw-bold  text-warning text-center">
              Sistema de Reservación de Actividades Educativas
            </h5>

          </div>
        </div>
      </div>

      <div className="" style={{ height: "auto", minHeight: "80%" }}>
        <Outlet />
      </div>

      <div className="bg-primary w-100 ">
        <br />
        <div className='bg-primary row w-100 m-0 p-2'>
          <div className='row col-lg-6 col-md-12 col-sm-12 col-xs-12 p-0 m-0'>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-center align-items-center  mt-4 mb-4 p-2">
              <img
                src={Logo}
                alt="Responsive image"
                className="img-fluid "
                loading="lazy"
                style={{ minHeight: "50px" }}
              />

            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-light " style={{ fontWeight: 'normal', fontSize: '0.8rem' }}>
              <h6>Enlaces</h6>
              <ul>
                <li><a href="https://datos.gob.mx/" className='text-decoration-none text-light'> Datos </a> </li>
                <li><a href="https://www.gob.mx/publicaciones" className='text-decoration-none text-light'> Publicaciones </a> </li>
                <li><a href="http://portaltransparencia.gob.mx/" className='text-decoration-none text-light'> Portal de Obligaciones de Transparencia </a> </li>
                <li><a href="https://www.infomex.org.mx/gobiernofederal/home.action" className='text-decoration-none text-light'> PNT </a> </li>
                <li><a href="http://www.inai.org.mx/" className='text-decoration-none text-light'> INAI  </a> </li>
                <li><a href="https://alertadores.funcionpublica.gob.mx/" className='text-decoration-none text-light'>  Alerta</a> </li>
                <li><a href="https://sidec.funcionpublica.gob.mx/#!/" className='text-decoration-none text-light'> Denuncia  </a> </li>
              </ul>
            </div>
          </div>

          <div className='row col-lg-6 col-md-12 col-sm-12 col-xs-12  p-0 m-0'>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-light" style={{ fontWeight: 'normal', fontSize: '0.8rem' }} >

              <h6>¿Qué es gob.mx?</h6>
              <p> Es el portal único de trámites, información y participación ciudadana.   </p>
              <Link to={"https://www.gob.mx/que-es-gobmx"} className='text-decoration-none text-light'> Leer más</Link>

              <ul>
                <li><a href="https://www.gob.mx/temas" className='text-decoration-none text-light'> Temas </a> </li>
                <li><a href="https://www.gob.mx/accesibilidad" className='text-decoration-none text-light'> Declaración de Accecibilidad </a> </li>
                <li><a href="https://www.gob.mx/privacidadintegral" className='text-decoration-none text-light'> Aviso de privacidad </a> </li>
                <li><a href="https://www.gob.mx/privacidadsimplificado" className='text-decoration-none text-light'> Aviso de privacidad simplificado   </a> </li>
                <li><a href="https://www.gob.mx/terminos" className='text-decoration-none text-light'> Términos y Condiciones   </a> </li>
                <li><a href="https://www.gob.mx/terminos#medidas-seguridad-informacion" className='text-decoration-none text-light'>  Política de seguridad </a> </li>
                <li><a href="http://www.ordenjuridico.gob.mx/" className='text-decoration-none text-light'> Marco jurídico  </a> </li>
                <li><a href="https://www.gob.mx/sitemap" className='text-decoration-none text-light'> Mapa de sitio </a> </li>
              </ul>
            </div>
            <div className="text-light  col-lg-6 col-md-12 col-sm-12 col-xs-12 " style={{ fontWeight: 'normal', fontSize: '0.8rem' }} >

              <h6>Contacto</h6>
              <p> Dudas e información a </p><h6 className='text-warning' style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>programaseducativos@agn.gob.mx </h6>

              Síguenos en <br />

              <a href="https://www.facebook.com/gobmexico" className='text-decoration-none text-light'> <ImFacebook size={'2em'} className='m-2' /> </a>
              <a href="https://twitter.com/GobiernoMX" className='text-decoration-none text-light'> <ImTwitter size={'2em'} className='m-2' /> </a>

            </div>
          </div>

        </div>

        <div className='col-12 '>
          <img
            src={Placa}
            alt="Responsive image"
            className="img-fluid w-100 h-100"
            loading="lazy"
            style={{ maxHeight: "100%" }}
          />
        </div>
      </div>

    </div>
  )
}

export default LayoutPublic