import { Form, Formik, ErrorMessage } from 'formik';
import { useState, useEffect, ChangeEvent } from 'react';
import { default as FormRae } from 'react-bootstrap/Form'
import { Button, Modal, Tabs } from 'react-bootstrap';
import TablaRae from '../../../Components/Tablas/TablaRae';
import { ReservacionesAtributos, ReservacionesRae } from '../Interfaces/InterfaceReservacionesConfirmadas';
import UseReservacionesConfirmadas from '../Hooks/UseReservacionesConfirmadas';
import format from 'date-fns/format';
import UseMediadores from '../../Reservaciones/Hooks/UseMediadores';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import { addMonths, getDay } from 'date-fns';
import Swal from 'sweetalert2';
import UseReservacionesRally from '../../ReservacionesRally/Hooks/UseReservacionesRally';
import Loading from '../../../Components/Loaders/Loading';
import UseCatalogos from '../../Publica/hooks/UseCatalogos';

interface Props {
    registro_Seleccionado: ReservacionesRae | undefined;
    returnSubmitSuccess: () => void,
    cerrarModalForm: () => void
}


const FormDatosGenerales = ({ cerrarModalForm, registro_Seleccionado, returnSubmitSuccess }: Props) => {

    const { reservacionConfirmadasGet, confirmacionReservacionPost, actualizarCalendario, enviarEmailConfirmacion, enviarEmailConfirmacionRally, confirmarRerserRally, cancelarReservacion } = UseReservacionesConfirmadas()
    const [mostraModal, setMostraModal] = useState(false);
    const { mediadoresGet, mediadores } = UseMediadores();
    const [loading, setLoading] = useState(false)


    const {
        horarioRally, obtenerHorariosRallyGet,
    } = UseReservacionesRally()

    const { obtenerDiasFestivosGet, diasFestivos, diasRally, obtenerDiasRallyGet } = UseCatalogos();

    const [reservacionSelect, setReservacionSelect] = useState<ReservacionesAtributos | any>({
        id_reservacion: '',
        folio_reservacion: '',

        modalidad: '',
        fecha_registro: '',
        fecha_uno: '',
        horario_uno_inicio: '',
        horario_uno_fin: '',
        fecha_dos: '',
        horario_dos_inicio: '',
        horario_dos_fin: '',
        num_grupo_rally: 0,
        momento_act: '',
        prim_vez: '',
        enterado_por: '',
        horario_actividad: '',

        cancelar_reservacion: '',
        estado: '',
        fecha_final: '',

        mediador: {
            id_mediador: '',
            nombre: '',
            ap_paterno: '',
            ap_materno: '',
            correo_electronico: '',
            estado: '',
        },

        cuento: {
            id_cuento: '',
            nombre_c: '',
        },

        taller: {
            id_taller: '',
            nombre_t: '',
        },

        idactividad: {
            id_actividad: '',
            nombre_actividad: '',
        },

    })
    const isWeekday = (date: any) => {
        const day = getDay(date);
        return day !== 0;
    };


    const Festivos = diasFestivos?.map((dias) => {
        const conversion = new Date(dias)
        return conversion
    });

    const Rally = diasRally!.map((dia) => {
        const dias = dia.fecha_inicio?.replaceAll("-", "/")
        const conversion = new Date(dias!)
        return conversion
    })

    let highlightWithRanges: any = [
        {
            "react-datepicker__day--highlighted-custom-3": Festivos,
        },
        {
            "react-datepicker__day--highlighted-custom-2": Rally,
        },
    ];


    interface PropsDiasRally {
        horario_fin?: string,
        horario_inicio?: Date | string | number,
        id_dia?: number,
        nombre_dia?: string,
        fecha_inicio?: string,
        fecha_fin?: string
    }

    const cerrarModal = () => {
        setMostraModal(false)
        if (registro_Seleccionado) {
            setReservacionSelect(false)
        }
    }


    useEffect(() => {
        if (registro_Seleccionado) {
            setReservacionSelect({
                id_reservacion: registro_Seleccionado.id_reservacion,
                folio_reservacion: registro_Seleccionado.folio_reservacion,
                nombre_actividad: registro_Seleccionado.idactividad.nombre_actividad,
                tipo_actividad: registro_Seleccionado.idactividad.id_actividad,

                modalidad: registro_Seleccionado.modalidad,
                fecha_registro: registro_Seleccionado.fecha_registro,
                num_grupo_rally: registro_Seleccionado.num_grupo_rally,

                nombre_t: registro_Seleccionado.taller ? registro_Seleccionado.taller.nombre_t : '',
                nombre_c: registro_Seleccionado.cuento ? registro_Seleccionado.cuento.nombre_c : '',

                momento_act: registro_Seleccionado.momento_act,
                prim_vez: registro_Seleccionado.prim_vez,
                enterado_por: registro_Seleccionado.enterado_por,

                id_mediador: registro_Seleccionado.mediador ? registro_Seleccionado.mediador.id_mediador : '',

                nombre: registro_Seleccionado.responsable ? registro_Seleccionado.responsable.nombre : '',
                ap_paterno: registro_Seleccionado.responsable ? registro_Seleccionado.responsable.ap_paterno : '',
                ap_materno: registro_Seleccionado.responsable ? registro_Seleccionado.responsable.ap_materno : '',
                correo_elect: registro_Seleccionado.responsable ? registro_Seleccionado.responsable.correo_elec : '',

                estado: registro_Seleccionado.estado,
                cancelar_cita: registro_Seleccionado.cancelar_reservacion == true ? 1 : 0,
                motivo_cancelacion: '',
                fecha_final: registro_Seleccionado.fecha_final,
                horario_final_inicio: format(new Date(registro_Seleccionado.horario_final_inicio!), "HH:mm"),
                horario_final_fin: format(new Date(registro_Seleccionado.horario_final_fin!), "HH:mm"),
            })
        }
    }, [registro_Seleccionado])


    useEffect(() => {
        mediadoresGet()
        obtenerDiasRallyGet()
        obtenerHorariosRallyGet(registro_Seleccionado!.fecha_final || undefined)
        obtenerDiasFestivosGet()
    }, [])

    const validaciones = Yup.object().shape({

        num_grupo_rally: Yup.string().nullable(),
        id_mediador: Yup.string().
            when('num_grupo_rally', {
                is: (value: string) => !value,
                then: Yup.string().required('Este campo es obligatorio')
            }),
        fecha_final: Yup.string().required('Este campo es obligatorio'),
        horario_final_inicio: Yup.string().required('Este campo es obligatorio'),
        horario_final_fin: Yup.string().required('Este campo es obligatorio'),
        cancelar_reservacion: Yup.boolean(),
        motivo_cancelacion: Yup.string().
            when('cancelar_reservacion', {
                is: (value: boolean) => value == true,
                then: Yup.string().required('Este campo es obligatorio')
            }),



    })


    interface PropsMediadores {
        id_mediador?: number,
        nombre: string,
        ap_paterno: string,
        ap_materno: string
    }


    return (
        <div>
            <Formik
                initialValues={reservacionSelect}
                onSubmit={async (values) => {

                    await Swal.fire({
                        title: '¿Estás seguro de guardar los cambios?',
                        showDenyButton: true,
                        confirmButtonText: 'Aceptar',
                        denyButtonText: `Cancelar`,
                        confirmButtonColor: '#13322B',
                        denyButtonColor: '#9D2449',
                    }).then(async (result) => {

                        if (result.isConfirmed) {

                            if (values.tipo_actividad == 6) {

                                const object = {
                                    id_mediador: values.id_mediador,
                                    id_reservacion: values.id_reservacion,
                                    cancelar_reservacion: values.cancelar_reservacion,
                                    motivo_cancelacion: values.motivo_cancelacion,
                                    fecha_final: values.fecha_final,
                                    horario_final_inicio: values.fecha_final + ' ' + values.horario_final_inicio,
                                    horario_final_fin: values.fecha_final + ' ' + values.horario_final_fin,
                                }

                                const email = {
                                    nombre: values.nombre,
                                    primer_apellido: values.ap_paterno,
                                    segundo_apellido: values.ap_materno,
                                    correo_electronico: values.correo_elect,

                                    fecha: values.fecha_final,
                                    horario_inicio: values.horario_final_inicio,
                                    horario_fin: values.horario_final_fin
                                }
                                
                               setLoading(true)
                                if (values.cancelar_reservacion == 1) {
                                    await cancelarReservacion(email)
                                } 

                                await confirmarRerserRally(object)
                                returnSubmitSuccess()
                                setLoading(false) 
                            } else {
                                const object = {

                                    title: values.folio_reservacion,
                                    responsable: values.nombre + ' ' + values.ap_paterno + ' ' + values.ap_materno,
                                    start: values.fecha_final + ' ' + values.horario_final_inicio,
                                    end: values.fecha_final + ' ' + values.horario_final_fin,
                                    habilitado: values.cancelar_reservacion,
                                    
                                    id_mediador: values.id_mediador,
                                    id_reservacion: values.id_reservacion,
                                    cancelar_reservacion: values.cancelar_reservacion,
                                    motivo_cancelacion: values.motivo_cancelacion,
                                    fecha_final: values.fecha_final,
                                    horario_final_inicio: values.fecha_final + ' ' + values.horario_final_inicio,
                                    horario_final_fin: values.fecha_final + ' ' + values.horario_final_fin,
                                }


                                const email = {
                                    nombre: values.nombre,
                                    primer_apellido: values.ap_paterno,
                                    segundo_apellido: values.ap_materno,
                                    correo_electronico: values.correo_elect,
                                    folio_reservacion:values.folio_reservacion,

                                    fecha: values.fecha_final,
                                    horario_inicio: values.horario_final_inicio,
                                    horario_fin: values.horario_final_fin
                                }

                                
                                setLoading(true)
                                if (values.cancelar_reservacion == 1) {
                                    await cancelarReservacion(email)
                                } else {
                                    await enviarEmailConfirmacion(email)
                                }

                                await confirmacionReservacionPost(object)
                                returnSubmitSuccess()
                                setLoading(false)
                            }
                        } else if (result.isDenied) {

                        }
                    })
                }}
                enableReinitialize
                validationSchema={validaciones}
            >

                {({ handleChange, values, setFieldValue }) => (
                    <Form>



                        <Modal
                            size="sm"
                            show={loading}
                            onHide={() => setLoading(false)}
                            backdrop="static"
                            centered
                            className='m-0 p-0'
                        >
                            <Modal.Body>
                                <Loading />
                            </Modal.Body>
                        </Modal>

                        <div className='row d-flex'>

                            <FormRae.Group controlId="id_reservacion" className="d-none">
                                <FormRae.Label>Folio Reservación</FormRae.Label>
                                <FormRae.Control
                                    type="text"
                                    name="id_reservacion"
                                    placeholder='Folio Reservación'
                                    value={values.id_reservacion || ''}
                                    disabled
                                />
                            </FormRae.Group>

                            <FormRae.Group controlId="folio_reservacion" className="mb-3 col-12">
                                <FormRae.Label>Folio Reservación</FormRae.Label>
                                <FormRae.Control
                                    type="text"
                                    name="folio_reservacion"
                                    placeholder='Folio Reservación'
                                    value={values.folio_reservacion || ''}
                                    disabled
                                />
                            </FormRae.Group>

                            <div className='row p-0 m-0'>
                                <div className="col-md-12 col-lg-6 p-2">
                                    <FormRae.Group controlId="nombre_actividad" className="">
                                        <FormRae.Label>Nombre de la actividad </FormRae.Label>
                                        <FormRae.Control
                                            type="text"
                                            name="nombre_actividad"
                                            placeholder='Nombre de la Actividad'
                                            value={values.nombre_actividad || false}
                                            disabled
                                        />
                                    </FormRae.Group>
                                </div>
                                <div className="col-md-12 col-lg-6 p-2">

                                    <FormRae.Group controlId="modalidad" className="">
                                        <FormRae.Label>Modalidad</FormRae.Label>
                                        <FormRae.Control
                                            type="text"
                                            name="modalidad"
                                            placeholder='Nombre de la modalidad'
                                            value={values.modalidad || ''}
                                            disabled
                                        />
                                    </FormRae.Group>
                                </div>
                            </div>


                            {values.nombre_c ? (<>
                                <FormRae.Group controlId="nombre_c" className="mb-3">
                                    <FormRae.Label>Nombre del cuentacuentos</FormRae.Label>
                                    <FormRae.Control
                                        type="text"
                                        name="nombre_c"
                                        placeholder='Nombre del Cuentacuentos'
                                        disabled
                                        value={values.nombre_c || false}
                                    />
                                </FormRae.Group>

                            </>) : ''}

                            {values.nombre_t ?

                                (<>
                                    <FormRae.Group controlId="nombre_t" className="mb-3">
                                        <FormRae.Label>Nombre del taller</FormRae.Label>
                                        <FormRae.Control
                                            type="text"
                                            name="nombre_t"
                                            placeholder='Nombre del taller'
                                            disabled
                                            value={values.nombre_t || false}
                                        />
                                    </FormRae.Group>

                                </>) : ''}


                            {values.num_grupo_rally === 'Grupo 1' ||
                                values.num_grupo_rally === 'Grupo 2' ||
                                values.num_grupo_rally === 'Grupo 3' ||
                                values.num_grupo_rally === 'Grupo 4' ?

                                (<>
                                    <FormRae.Group controlId="num_grupo_rally" className="mb-3">
                                        <FormRae.Label>Número grupo</FormRae.Label>
                                        <FormRae.Control
                                            type="text"
                                            name="num_grupo_rally"
                                            placeholder='Momento de la actividad'
                                            disabled
                                            value={values.num_grupo_rally || ''}
                                        />
                                    </FormRae.Group>

                                    <div className='col-lg-6 col-md-12 col-sm-12 '>
                                        <FormRae.Group controlId="fecha_final" >
                                            <FormRae.Label>Fecha actividad</FormRae.Label>

                                            <FormRae.Control
                                                name="fecha_final"
                                                placeholder='-- Fecha de la actividad --'
                                                value={values.fecha_final || ''}
                                                disabled
                                            />


                                        </FormRae.Group>
                                    </div>

                                    <div className='col-lg-6 col-md-12 col-sm-12'>
                                        <FormRae.Group controlId="horario_actividad" >
                                            <FormRae.Label>Horario actividad</FormRae.Label>
                                            <FormRae.Control
                                                name="horario_actividad"
                                                placeholder='-- Horario actividad --'
                                                value={`${horarioRally?.fecha_inicio ? format(new Date(horarioRally?.horario_inicio!), 'HH:mm') + ' - ' + format(new Date(horarioRally?.horario_fin!), 'HH:mm') : ''} ` || 'ss'}
                                                disabled
                                            >
                                            </FormRae.Control>
                                        </FormRae.Group>
                                    </div>


                                </>) :
                                (<>
                                    {values.momento_act ?
                                        (<>
                                            <FormRae.Group controlId="momento_act" className="mb-3">
                                                <FormRae.Label>Momento de la actividad</FormRae.Label>
                                                <FormRae.Control
                                                    type="text"
                                                    name="momento_act"
                                                    placeholder='Momento de la actividad'
                                                    disabled
                                                    value={values.momento_act || ''}
                                                />
                                            </FormRae.Group>
                                        </>) :
                                        ''}

                                    <FormRae.Group controlId="id_mediador" className="mb-3">
                                        <FormRae.Label>* Asignación del mediador</FormRae.Label>
                                        <FormRae.Select
                                            name="id_mediador"
                                            placeholder='-- Seleccionar un Mediador --'
                                            onChange={handleChange}
                                            value={values.id_mediador || undefined}
                                        >
                                            <option value="">-- Seleccionar un Mediador --</option>
                                            {
                                                mediadores.map((mediador: PropsMediadores, index) => (
                                                    <option value={mediador.id_mediador} key={index}  > {mediador.nombre} {mediador.ap_paterno} {mediador.ap_materno}  </option>
                                                ))
                                            }

                                        </FormRae.Select>
                                        <ErrorMessage name='id_mediador' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                                    </FormRae.Group>

                                    <div className='w-100'>
                                        <FormRae.Group controlId="fecha_final" className="mb-3 p-1">
                                            <FormRae.Label>* Fecha actividad</FormRae.Label>
                                            <DatePicker
                                                value={values.fecha_final || ''}
                                                name="fecha_final"
                                                onChange={(e: any) => setFieldValue("fecha_final", format(new Date(e), 'yyyy-MM-dd'))}
                                                minDate={new Date()}
                                                maxDate={addMonths(new Date(), 12)}
                                                filterDate={isWeekday}
                                                highlightDates={highlightWithRanges}
                                                showDisabledMonthNavigation
                                                locale="es"
                                                placeholderText='-- Selecciona una fecha --'
                                                className='w-100 rounded-2  form-control '
                                                dayClassName={(date: Date) => ' rounded-4 '}
                                                useWeekdaysShort
                                                useShortMonthInDropdown
                                                dateFormat={'yyyy-MM-dd'}
                                            />

                                            <ErrorMessage name='fecha_final' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                                        </FormRae.Group>
                                    </div>

                                    <div className='row p-0 m-0'>
                                        <div className="col-md-12 col-lg-6 ">
                                            <FormRae.Group controlId="horario_final_inicio" className="mb-3 p-1">
                                                <FormRae.Label>* Horario de inicio</FormRae.Label>
                                                <DatePicker
                                                    value={values.horario_final_inicio || ''}
                                                    name="horario_final_inicio"
                                                    onChange={(e: any) => setFieldValue("horario_final_inicio", format(new Date(e), 'HH:mm'))}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeCaption="Time"
                                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                                    className=' rounded-2  form-control '
                                                    placeholderText='-- Seleciona un horario -- '
                                                />
                                                <ErrorMessage name='horario_final_inicio' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                                            </FormRae.Group>
                                        </div>
                                        <div className="col-md-12 col-lg-6 ">
                                            <FormRae.Group controlId="horario_final_fin" className="mb-3 p-1">
                                                <FormRae.Label>* Horario de termino</FormRae.Label>
                                                <DatePicker
                                                    value={values.horario_final_fin || ''}
                                                    name="horario_final_fin"
                                                    onChange={(e: any) => setFieldValue("horario_final_fin", format(new Date(e), 'HH:mm'))}
                                                    showTimeSelect
                                                    showTimeSelectOnly

                                                    timeCaption="Time"
                                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                                    className=' rounded-2  form-control '
                                                    placeholderText='-- Seleciona un horario -- '
                                                />
                                                <ErrorMessage name='horario_final_fin' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                                            </FormRae.Group>
                                        </div>
                                    </div>

                                </>)

                            }




                            <FormRae.Group controlId="cancelar_reservacion" className="mb-3 p-2">
                                <FormRae.Label>Cancelar Reservación</FormRae.Label>
                                <FormRae.Check
                                    type="checkbox"
                                    name="cancelar_reservacion"
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("cancelar_reservacion", e.target.checked)}
                                    checked={values.cancelar_reservacion || ''}
                                />

                            </FormRae.Group>

                            {values.cancelar_reservacion == true ?
                                (<>
                                    <FormRae.Group controlId="motivo_cancelacion" className="mb-3">
                                        <FormRae.Label>* Motivo de cancelación</FormRae.Label>
                                        <FormRae.Control
                                            as="textarea"
                                            type="text"
                                            name="motivo_cancelacion"
                                            onChange={handleChange}
                                            value={values.motivo_cancelacion || ''}
                                            style={{ height: "200px" }}
                                            placeholder='Ingresa el motivo de la cancelación'
                                        />
                                        <ErrorMessage name='motivo_cancelacion' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                                    </FormRae.Group>

                                </>) : ''}

                        </div>

                        <TablaRae.ModalFooter>
                            <Button variant="primary"
                                type="submit"
                            >
                                Actualizar
                            </Button>
                            <Button variant="primary" onClick={cerrarModalForm} >
                                Cerrar
                            </Button>

                        </TablaRae.ModalFooter>

                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default FormDatosGenerales