import { ErrorMessage, Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'

import { default as FormRae } from 'react-bootstrap/Form'
import { ReservacionesRae, ReservacionesPropTabla } from '../Interfaces/InterfaceReservaciones';

import UseReservaciones from '../Hooks/UseReservaciones';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import CryptoJS from 'crypto-js';
import { useContext } from 'react';
import { ContextApp } from '../../../Context/StateApp';

interface Props {
    registro_Seleccionado: ReservacionesRae | undefined;
    returnSubmitSuccess: () => void,
    cerrarModalForm: () => void
}



const FormResponsable = ({ cerrarModalForm, registro_Seleccionado, returnSubmitSuccess }: Props) => {

    const { state: { usuario_logeado, logeado } } = useContext(ContextApp)

    const { actualizarReservacion } = UseReservaciones()
    const [mostraModal, setMostraModal] = useState(false);
    const [reservacionSelect, setReservacionSelect] = useState<ReservacionesPropTabla | any>({

        responsable: {
            id_responsable: '',
            nombre: '',
            ap_paterno: '',
            ap_materno: '',
            correo_elec: '',
            telefono: '',
        }

    })

    useEffect(() => {
        if (registro_Seleccionado) {
            setReservacionSelect({
                id_reservacion: registro_Seleccionado.id_reservacion,
                id_responsable: registro_Seleccionado.responsable!.id_responsable ? registro_Seleccionado.responsable!.id_responsable : '',
                nombre: registro_Seleccionado.responsable!.nombre ? registro_Seleccionado.responsable!.nombre : '',
                ap_paterno: registro_Seleccionado.responsable!.ap_paterno ? registro_Seleccionado.responsable!.ap_paterno : '',
                ap_materno: registro_Seleccionado.responsable!.ap_materno ? registro_Seleccionado.responsable!.ap_materno : '',
                correo_elec: registro_Seleccionado.responsable!.correo_elec ? registro_Seleccionado.responsable!.correo_elec : '',
                telefono: registro_Seleccionado.responsable!.telefono ? registro_Seleccionado.responsable!.telefono : '',
                usuario_logeado:usuario_logeado?.id_mediador
            })
        }

    }, [registro_Seleccionado])


    const validaciones = Yup.object().shape({
        nombre:
            Yup.string().
                matches(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+/, 'El formato del campo es invalido').
                max(300, 'Este campo solo permite 300 caracteres').
                required('Este campo es obligatorio'),
        ap_materno:
            Yup.string().
                matches(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+/, 'El formato del campo es invalido').
                max(300, 'Este campo solo permite 300 caracteres').
                required('Este campo es obligatorio'),
        ap_paterno:
            Yup.string().
                matches(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+/, 'El formato del campo es invalido').
                max(300, 'Este campo solo permite 300 caracteres').
                required('Este campo es obligatorio'),
        correo_elec:
            Yup.string().
                email('El formato del campo no es valido').
                max(300, 'Este campo solo permite 300 caracteres').
                required('Este campo es obligatorio'),
        telefono:
            Yup.string().
                matches(/^[0-9]+$/, 'Este campo solo permite números enteros').
                typeError('Este campo solo permite números enteros').
                required('Este campo es obligatorio').
                min(10, 'El minimo de caracteres para este campo es de 10'),
    })

     // Encrypt
   const Encrypt = (texto: string) => {
    const enEs = CryptoJS.AES.encrypt(texto,  process.env.REACT_APP_CLAVE_ENCRIPT!).toString();
    return enEs;
  }



    return (
        <div>


            <Formik
                initialValues={reservacionSelect}
                onSubmit={async (values) => {

                    const object = {
                        id_reservacion: values.id_reservacion,
                        id_responsable: values.id_responsable,
                        nombre: Encrypt(values.nombre),
                        ap_paterno: Encrypt(values.ap_paterno),
                        ap_materno: Encrypt(values.ap_materno),
                        correo_elec: Encrypt(values.correo_elec),
                        telefono: Encrypt(values.telefono),
                        usuario_logeado:values.usuario_logeado
                    }

                    actualizarReservacion(object)
                }}
                enableReinitialize
                validationSchema={validaciones}
            >
                {({ values, handleChange, setFieldValue }) => (
                    <Form>

                        <FormRae.Group controlId="nombre" className="">
                            <FormRae.Label>* Nombre</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="nombre"
                                value={values.nombre || ''}
                                onChange={(e) => setFieldValue("nombre", e.target.value.replaceAll(/[0-9]/g, ''))}
                            />
                        </FormRae.Group>
                        <ErrorMessage name='nombre' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />

                        <FormRae.Group controlId="ap_paterno" className="">
                            <FormRae.Label>* Primer apellido</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="ap_paterno"
                                value={values.ap_paterno || ''}
                                onChange={(e) => setFieldValue("ap_paterno", e.target.value.replaceAll(/[0-9]/g, ''))}
                            />
                        </FormRae.Group>
                        <ErrorMessage name='ap_paterno' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />

                        <FormRae.Group controlId="ap_materno" className="">
                            <FormRae.Label>* Segundo Apellido</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="ap_materno"
                                value={values.ap_materno || ''}
                                onChange={(e) => setFieldValue("ap_materno", e.target.value.replaceAll(/[0-9]/g, ''))}
                            />
                        </FormRae.Group>
                        <ErrorMessage name='ap_materno' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />

                        <FormRae.Group controlId="correo_elec" className="">
                            <FormRae.Label>* Correo electrónico</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="correo_elec"
                                value={values.correo_elec || ''}
                                onChange={handleChange}
                            />
                        </FormRae.Group>
                        <ErrorMessage name='correo_elec' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />

                        <FormRae.Group controlId="telefono" className="">
                            <FormRae.Label>* Teléfono</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="telefono"
                                value={values.telefono || ''}
                                onChange={(e) => setFieldValue("telefono", e.target.value.replaceAll(/[A-Za-z]/g, ''))}
                                maxLength={10}
                            />
                        </FormRae.Group>
                        <ErrorMessage name='telefono' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                        <br />
                        <Button variant="primary"
                            type="submit"
                        >
                            Actualizar
                        </Button>


                    </Form>
                )}
            </Formik>




        </div>
    )
}

export default FormResponsable