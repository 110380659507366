import React, { useEffect } from 'react'

interface Props {
  children: JSX.Element | JSX.Element[];
  title?: string;
}

const PaginaBase = ({ children, title }: Props) => {

  useEffect(() => {

    if (title) document.title = title

  }, [title])


  return (
    <div>

      {title == 'R. Confirmadas' ? (<>  <h1>Reservaciones confirmadas </h1></>) :
        (<>
          {title == 'R. Canceladas' ? (<>  <h1>Reservaciones canceladas </h1></>) :
            (<>
              {title && <h1>{title}</h1>}
            </>)}
        </>)}




      {
        children
      }
    </div>
  )
}

export default PaginaBase