import { ErrorMessage, Form, Formik } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { default as FormRae } from 'react-bootstrap/Form'
import TablaRae from '../../../Components/Tablas/TablaRae'
import useDiasRally from '../Hooks/UseDiasRally'
import { DiasRallyProps } from '../Interfaces/InterfaceCatalogos'


import * as Yup from 'yup'
import ReactDatePicker from 'react-datepicker'
import { addMonths, format, getDay } from 'date-fns';
import { AlertaRae } from '../../../Components/Alertas/AlertaToastify'

interface Props {
    registro_Seleccionado: DiasRallyProps,
    returnSubmitSuccess: () => void,
    cerrarModalForm: () => void
}



const FormDiasRally = ({ registro_Seleccionado, returnSubmitSuccess, cerrarModalForm }: Props) => {

    const { diasRallyPost } = useDiasRally()

    const [mostrarModal, setMostrarModal] = useState(false);

    const [diaSelect, setDiaSelect] = useState<DiasRallyProps | any>({
        id_dia: undefined,
        nombre_dia: '',
        fecha_inicio: '',
        fecha_fin: '',
        horario_inicio: '',
        horario_fin: '',
    })

    useEffect(() => {

        if (registro_Seleccionado) {
            setDiaSelect({
                id_dia: registro_Seleccionado.id_dia ? registro_Seleccionado.id_dia : undefined,
                nombre_dia: registro_Seleccionado.nombre_dia ? registro_Seleccionado.nombre_dia : '',
                fecha_inicio: registro_Seleccionado.fecha_inicio ? registro_Seleccionado.fecha_inicio : '',
                fecha_fin: registro_Seleccionado.fecha_fin ? registro_Seleccionado.fecha_fin : '',
                horario_inicio: registro_Seleccionado.horario_inicio ? format(new Date((registro_Seleccionado.horario_inicio!)), 'HH:mm') : '',
                horario_fin: registro_Seleccionado.horario_fin ? format(new Date(registro_Seleccionado.horario_fin!), 'HH:mm') : ''
            })
        }
    }, [registro_Seleccionado])


    const validaciones = Yup.object().shape({
        nombre_dia: Yup.string().required('Este campo es obligatorio').nullable(),
        fecha_inicio: Yup.string().required('Este campo es obligatorio'),
        fecha_fin: Yup.string().required('Este campo es obligatorio'),
        horario_inicio: Yup.string().required('Este campo es obligatorio'),
        horario_fin: Yup.string().required('Este campo es obligatorio'),
    })

    const isWeekday = (date: any) => {
        const day = getDay(date);
        return day !== 0;
    };

    return (
        <div>
            <Formik
                initialValues={diaSelect}
                onSubmit={
                    async (values) => {


                        const object = {
                            id_dia: values.id_dia,
                            nombre_dia: values.nombre_dia,
                            fecha_inicio: values.fecha_inicio,
                            fecha_fin: values.fecha_fin,
                            horario_inicio: values.fecha_inicio + ' ' + values.horario_inicio,
                            horario_fin: values.fecha_inicio + ' ' + values.horario_fin
                        }
                     

                            await diasRallyPost(object)
                            returnSubmitSuccess()
                        

                    }}
                enableReinitialize //Importante atributo para reiniciar el componente                                                                                                                                       
                validationSchema={validaciones}
            >

                {({ handleChange, values, setFieldValue }) => (
                    <Form  >
                        <FormRae.Group controlId="id_dia" className="mb-3">
                            <FormRae.Label>Número</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="id_dia"
                                placeholder='Número día'
                                onChange={handleChange}
                                disabled
                                value={values.id_dia || ''}

                            />
                        </FormRae.Group>

                        <FormRae.Group controlId="nombre_dia" className="mb-3">
                            <FormRae.Label>Descripción</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="nombre_dia"
                                placeholder='Escribe la descripción del día'
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("nombre_dia", e.target.value)}
                                value={values.nombre_dia || ''}

                            />
                            <ErrorMessage name='nombre_dia' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                        </FormRae.Group>

                        <FormRae.Group controlId="fecha_inicio" className="mb-3">
                            <FormRae.Label>Fecha de inicio</FormRae.Label>


                            <ReactDatePicker
                                value={values.fecha_inicio || ''}
                                name="fecha_inicio"
                                onChange={(e) => {
                                    setFieldValue("fecha_inicio", format(e!, 'yyyy-MM-dd'))
                                    setFieldValue("fecha_fin", format(e!, 'yyyy-MM-dd'))
                                }}
                                minDate={new Date()}
                                maxDate={addMonths(new Date(), 12)}
                                showDisabledMonthNavigation
                                locale="es"
                                placeholderText='-- Selecciona una fecha --'
                                className='w-100 rounded-2  form-control '
                                dayClassName={(date: Date) => ' rounded-4 '}
                                useWeekdaysShort
                                useShortMonthInDropdown
                                dateFormat={'yyyy-MM-dd'}
                                filterDate={isWeekday}
                            />


                            <ErrorMessage name='fecha_inicio' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                        </FormRae.Group>

                        <FormRae.Group controlId="fecha_fin" className="mb-3">
                            <FormRae.Label>Fecha de término</FormRae.Label>

                            <ReactDatePicker
                                value={values.fecha_fin || ''}
                                name="fecha_fin"
                                onChange={(e: any) => {
                                    setFieldValue("fecha_fin", format(new Date(e), 'yyyy-MM-dd'))
                                }}
                                minDate={new Date()}
                                maxDate={addMonths(new Date(), 12)}
                                showDisabledMonthNavigation
                                locale="es"
                                placeholderText='-- Selecciona una fecha --'
                                className='w-100 rounded-2  form-control '
                                dayClassName={(date: Date) => ' rounded-4 '}
                                useWeekdaysShort
                                useShortMonthInDropdown
                                dateFormat={'yyyy-MM-dd'}
                                disabled
                                filterDate={isWeekday}
                            />

                            <ErrorMessage name='fecha_fin' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                        </FormRae.Group>


                        <div className=" w-100">
                            <FormRae.Group controlId="horario_inicio" className="p-1" >
                                <FormRae.Label>Horario de inicio</FormRae.Label>
                                <ReactDatePicker
                                    value={values.horario_inicio || ''}
                                    name="horario_inicio"
                                    onChange={(e: Date) => {
                                        setFieldValue("horario_inicio", format(new Date(e), 'HH:mm:ss'))
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={60}
                                    timeCaption="Date"
                                    dateFormat="HH:mm:ss"
                                    className=' rounded-2  form-control '
                                    placeholderText='Ingresa el horario'
                                />
                                <ErrorMessage name='horario_inicio' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                            </FormRae.Group>
                        </div>

                        <div className=" w-100">
                            <FormRae.Group controlId="horario_fin" className="p-1" >
                                <FormRae.Label>Horario de término</FormRae.Label>
                                <ReactDatePicker
                                    value={values.horario_fin || ''}
                                    name="horario_fin"
                                    onChange={(e: any) => {
                                        setFieldValue("horario_fin", format(new Date(e), 'HH:mm:ss'))
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={60}
                                    timeCaption="Time"
                                    dateFormat="HH:mm:ss"
                                    className=' rounded-2  form-control'
                                    placeholderText='Ingresa el horario'
                                />
                                <ErrorMessage name='horario_fin' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                            </FormRae.Group>
                        </div>

                        <TablaRae.ModalFooter>

                            <Button variant="primary"
                                type="submit"
                            >
                                {registro_Seleccionado ? "Actualizar" : "Guardar"}
                            </Button>

                            <Button variant="primary" onClick={cerrarModalForm} >
                                Cerrar
                            </Button>

                        </TablaRae.ModalFooter>
                    </Form>


                )}
            </Formik>

        </div>
    )
}

export default FormDiasRally