import React, { useState } from 'react'
import clienteAxios from '../../../Config/Axios';
import { MediadoresAtributos } from '../Interfaces/InterfaceMediadores';

const UseMediadores = () => {

    const [mediadores, SetMediadores] = useState<MediadoresAtributos[]>([]);
   

    const mediadoresGet = async () => {
        const consultaPaises = await clienteAxios.get('/mediadores');
        SetMediadores(consultaPaises.data);

    }

    return {
        mediadoresGet, mediadores
    }
}
    export default UseMediadores


