import { format } from 'date-fns';
import React, { useState } from 'react'
import clienteAxios from '../../../Config/Axios';
import { ReservacionesAtributos, DiasAño, DiasFestivos, DiasRally, info_grupo } from '../Interfaces/InterfaceReservacionesForm';

const UseCatalogos = () => {

    interface PropsPaises {
        id_pais?: number,
        nombre_pais?: string
    }

    interface PropsEstados {
        id_pais?: number,
        id_estado?: number,
        nombre_estado?: string
    }

    interface PropsMunicipios {
        id_estado?: number,
        id_municipio?: number,
        nombre_municipio?: string
    }

    interface PropsColonias {
        id_municipio?: number,
        id_colonia?: number,
        nombre?: string,
    }

    const [paises, SetPaises] = useState<ReservacionesAtributos[]>([]);
    const [estados, SetEstados] = useState<PropsEstados[]>([]);
    const [municipios, SetMunicipios] = useState<PropsMunicipios[]>([]);
    const [colonias, SetColonias] = useState<PropsColonias[]>([]);

    const [diasAño, SetDiasAño] = useState<DiasAño[]>([])
    const [diasFestivos, SetDiasFestivos] = useState<[]>([])

    const [diasRally, SetDiasRally] = useState<DiasRally[]>([])
    const [horariosRally, SetHorariosRally] = useState<DiasRally | undefined>(undefined)

    const paisesGet = async () => {
        const consultaPaises = await clienteAxios.get('/paises');
        SetPaises(consultaPaises.data);
    }

    const estadosGet = async () => {
        const consultaEstados = await clienteAxios.get('/estados');

        SetEstados(consultaEstados.data);
    }

    const municipiosGet = async (id_estado: number) => {
        const consultaMunicipios = await clienteAxios.get<PropsMunicipios[]>(`municipios/${id_estado}`);
        SetMunicipios(consultaMunicipios.data);
    }

    const coloniasGet = async (id_municipio: number) => {
        const consultaColonias = await clienteAxios.get<PropsColonias[]>(`/colonias/${id_municipio}`);
        SetColonias(consultaColonias.data);
    }

    const obtenerAñoGet = async () => {
        const consultaDias = await clienteAxios.get('/reservaciones/dias');
        SetDiasAño(consultaDias.data);
    }

    const obtenerDiasFestivosGet = async () => {
        const diasFestivos = await clienteAxios.get('/diasfestivos');

        const arr = diasFestivos.data
       
        for (const i in arr) {
            arr[i] = arr[i].replaceAll("-", "/").split(".")[0].replace("T", " ")
        }
        console.log(arr);
        SetDiasFestivos(arr);
      
    }

    const obtenerDiasRallyGet = async () => {
        const diasRally = await clienteAxios.get('/diasrally/dias');
        SetDiasRally(diasRally.data);
    }

    const obtenerHorariosRallyGet = async (fecha_inicio: Date | string) => {
        const horariosRally = await clienteAxios.get<DiasRally>(`/diasrally/${fecha_inicio}`);
        const obj = horariosRally.data
        for (const object in obj) {
            const horario_inicio = new Date(obj.horario_inicio!.toString().split(".")[0])
            obj.horario_inicio = format(new Date(horario_inicio), 'yyyy-MM-dd HH:mm:ss')

            const horario_fin = new Date(obj.horario_fin!.toString().split(".")[0])
            obj.horario_fin = format(new Date(horario_fin), 'yyyy-MM-dd HH:mm:ss')
        }

        SetHorariosRally(horariosRally.data);
    }



    return {
        paises, paisesGet, estadosGet, estados,
        municipiosGet, municipios, SetMunicipios,
        coloniasGet, colonias, SetColonias,

        diasAño, obtenerAñoGet,
        diasFestivos, obtenerDiasFestivosGet,
        diasRally, obtenerDiasRallyGet,
        horariosRally, obtenerHorariosRallyGet

    }
}
export default UseCatalogos


