import { Form, Formik } from 'formik'
import React, { useState,useEffect } from 'react'

import { default as FormRae } from 'react-bootstrap/Form'
import { ReservacionesPropTabla, ReservacionesRae } from '../Interfaces/InterfaceReservacionesConfirmadas';

interface Props {
    registro_Seleccionado: ReservacionesRae | undefined;
    returnSubmitSuccess: () => void,
    cerrarModalForm: () => void
}



const FormResponsable = ({ cerrarModalForm, registro_Seleccionado, returnSubmitSuccess } : Props) => {


    const [mostraModal, setMostraModal] = useState(false);
    const [reservacionSelect, setReservacionSelect] = useState<ReservacionesPropTabla | any>({

        responsable: {
            id_responsable: '',
            nombre: '',
            ap_paterno: '',
            ap_materno: '',
            correo_elec: '',
            telefono: '',
        }

    })

    useEffect(() => {
        if (registro_Seleccionado) {
            setReservacionSelect({
                id_responsable: registro_Seleccionado.responsable.id_responsable,
                nombre: registro_Seleccionado.responsable.nombre,
                ap_paterno: registro_Seleccionado.responsable.ap_paterno,
                ap_materno: registro_Seleccionado.responsable.ap_materno,
                correo_elec: registro_Seleccionado.responsable.correo_elec,
                telefono: registro_Seleccionado.responsable.telefono,
               
            })
        }

    }, [registro_Seleccionado])

    return (
        <div>


            <Formik
                initialValues={reservacionSelect}
                onSubmit={async (values) => {
                    console.log(values)
                }}
                enableReinitialize
            >
                {({  values  }) => (
                    <Form>

                        <FormRae.Group controlId="nombre" className="mb-1">
                            <FormRae.Label>Nombre</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="nombre"
                                value={values.nombre || ''}
                                disabled
                            />
                        </FormRae.Group>

                        <FormRae.Group controlId="ap_paterno" className="mb-1">
                            <FormRae.Label>Apellido paterno</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="ap_paterno"
                                value={values.ap_paterno || ''}
                                disabled
                            />
                        </FormRae.Group>

                        <FormRae.Group controlId="ap_materno" className="mb-1">
                            <FormRae.Label>Apellido materno</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="ap_materno"
                                value={values.ap_materno || ''}
                                disabled
                            />
                        </FormRae.Group>

                        <FormRae.Group controlId="correo_elec" className="mb-1">
                            <FormRae.Label>Correo electrónico</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="correo_elec"
                                value={values.correo_elec || ''}
                                disabled
                            />
                        </FormRae.Group>

                        <FormRae.Group controlId="telefono" className="mb-1">
                            <FormRae.Label>Teléfono</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="telefono"
                                value={values.telefono || ''}
                                disabled
                            />
                        </FormRae.Group>
                        <br />



                    </Form>
                )}
            </Formik>




        </div>
    )
}

export default FormResponsable