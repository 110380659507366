import { format } from 'date-fns/esm'
import { ErrorMessage } from 'formik'
import React, { useState } from 'react'
import { AlertaRae } from '../../../Components/Alertas/AlertaToastify'
import clienteAxios from '../../../Config/Axios'
import { ResponseData } from '../../../InterfacesGlobales/InterfaceResponse'
import { DiasRallyProps } from '../Interfaces/InterfaceCatalogos'




const useDiasRally = () => {

    const [diasRally, setDiasRally] = useState<DiasRallyProps[]>([])


    //-------Peticiones para el sevidor 

    const diasRallyGet = async () => {
        const obtenerDiasRally = await clienteAxios.get<DiasRallyProps[]>('/diasrally');

        for (const i in obtenerDiasRally.data) {
            obtenerDiasRally.data[i].horario_inicio = obtenerDiasRally.data[i].horario_inicio ? obtenerDiasRally.data[i].horario_inicio!.replaceAll("-", "/").split(".")[0].replace("T", " ") : ''
            obtenerDiasRally.data[i].horario_fin = obtenerDiasRally.data[i].horario_fin ? obtenerDiasRally.data[i].horario_fin!.replaceAll("-", "/").split(".")[0].replace("T", " ") : ''
        }

        setDiasRally(obtenerDiasRally.data)
    }

    const diasRallyPost = async (values: DiasRallyProps) => {
        const res = await clienteAxios.post<ResponseData>("/diasrally", values).catch(err => err.response)
        AlertaRae(res?.data.styles, res?.data.msg)
    }


    return {
        diasRally, diasRallyGet, diasRallyPost
    }
}

export default useDiasRally