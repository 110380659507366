import { createContext, useReducer } from "react"

import { UsuarioLogeado } from '../Modulos/Login/Interfaces/InterfaceLogin';
import { ReducerApp } from "./ReducerApp";


export interface InitialState {
  usuario_logeado?: UsuarioLogeado,
  logeado: boolean,
  cargando: boolean,
}

interface ContextProvider {
  state: InitialState,
  iniciarSession: (data: UsuarioLogeado) => void,
  cerrarSesion: () => void
}

export const ContextApp = createContext({} as ContextProvider)

const state_initial: InitialState = {
  usuario_logeado: undefined,
  logeado:false,
  cargando:true
}


const ProviderApp = ({ children }: any) => {

  const [state, dispatch] = useReducer(ReducerApp, state_initial);

  const iniciarSession = (data: UsuarioLogeado) => {
    if ( data ) {
      dispatch({
        type:"INICIAR_SESSION",
        payload:data
      })
    }
   
  }
  const cerrarSesion = () => {
    dispatch({
      type:"CERRAR_SESION"
    })
  }

  return (
    <ContextApp.Provider
      value={{
        state,
        iniciarSession,
        cerrarSesion
      }}
    >

      {children}

    </ContextApp.Provider>
  )
}

export default ProviderApp