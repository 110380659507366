import React, { useEffect, useState } from 'react'
import UseCuentos from '../Hooks/UseCuentos'
import { CuentacuentosProps, CuentacuentosPropTabla } from '../Interfaces/InterfaceCuentacuentos';
import { TableColumn } from 'react-data-table-component';
import { Button, Modal } from 'react-bootstrap';
import TablaRae from '../../../Components/Tablas/TablaRae';
import FormCuentacuentos from './FormCuentacuentos';
import { BiMessageSquareEdit } from "react-icons/bi";

const TablaCuentacuentos = () => {

    const { cuentacuentosGetAdmin, cuentosAdmin } = UseCuentos()
    const [mostrarModal, setMostrarModal] = useState(false)
    const [registroSeleccionado, setRegistroSeleccionado] = useState<CuentacuentosPropTabla | any>({
        id_cuento: '',
        nombre_c: '',
        estado: '',
    })

    useEffect(() => {
        cuentacuentosGetAdmin()
        setRegistroSeleccionado(false)
    }, [])

    const cerrarModal = () => {
        setMostrarModal(false)
        if (registroSeleccionado) {
            setRegistroSeleccionado(false)
        }
    }


    const columns: TableColumn<CuentacuentosPropTabla>[] = [
        {
            cell: (row) => (
                <div>
                    <Button variant='warning' onClick={() => {
                       
                        setRegistroSeleccionado(row)
                        setMostrarModal(true)
                    }} >
                        <BiMessageSquareEdit size={'1.3rem'} className="text-light"/>
                    </Button>
                </div>
            ),

        },
        {
            name: "Número",
            selector: (row) => row.id_cuento!,
            grow:3,
            center: true,
        },
        {
            name: "Nombre",
            selector: (row) => row.nombre_c,
            grow:8,
        },
        {
            name: "Estado",
            grow:1,
            cell: (row) => (
                <div>
                    {row.estado === 1 ? (
                        <div className="text-primary">
                            <h6> Habilitado </h6>
                        </div>
                    ) :
                        (
                            <div className="text-danger">
                                <h6> No habilitado </h6>
                            </div>
                        )
                    }
                </div>
            )
        }
    ]


    return (
        <div>
            <TablaRae
                columns={columns}
                data={cuentosAdmin}
                onclicNuevo={() => { setMostrarModal(true) }}
                closeModal={cerrarModal}
                show_modal={mostrarModal}
                paginationPerPage={15}
                
            >

                <TablaRae.ModalHeader>{registroSeleccionado ? "Actualizar Cuentacuentos" : "Nuevo Cuentacuentos"} </TablaRae.ModalHeader>
                <TablaRae.ModalBody>    
                    <FormCuentacuentos 
                    registro_Seleccionado={registroSeleccionado}
                     returnSubmitSuccess={() => {
                        cuentacuentosGetAdmin()
                        cerrarModal()
                    }}
                    cerrarModalForm={cerrarModal}

                    />
                </TablaRae.ModalBody>

            </TablaRae>



        </div>
    )
}

export default TablaCuentacuentos