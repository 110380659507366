import TablaDiaFestivo from "../Components/TablaDiaFestivo"
import TablaDiasRally from "../Components/TablaDiasRally"



export const PageCatalogos = () => {

  return (
    <>
      <hr className="my-1 gob" />
      <div className="w-100 row m-auto">
        <div className="col-lg-6 col-md-12 col-sm-12 p-2 ">
          <h4>Días festivos y de excepción</h4>
          <TablaDiaFestivo />
        </div>

        <div className="col-lg-6 col-md-12 col-sm-12 p-2 ">
          <h4>Días de rally</h4>
          <TablaDiasRally />
        </div>
      </div>
    </>
  )
}
