import React, { useState } from 'react'
import { ReservacionesRae } from '../Interfaces/InterfaceReservacionesConfirmadas'
import clienteAxios from '../../../Config/Axios';
import { ConfirmacionReservacion } from '../Interfaces/InterfaceReservacionesConfirmadas';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { ResponseDataSwet } from '../../../InterfacesGlobales/InterfaceResponseSweet';
import CryptoJS from 'crypto-js';

const UseCitasConfirmadas = () => {

    const [reservacionesConfirmadas, setReservacionesConfirmadas] = useState<ReservacionesRae[]>([])
    


    // Decrypt
    const Decrypt = (texto: string) => {
        const enDes = CryptoJS.AES.decrypt(texto, process.env.REACT_APP_CLAVE_ENCRIPT!);
        const enDeci = CryptoJS.enc.Utf8.stringify(enDes);
        return enDeci;
    }


    const reservacionConfirmadasGet = async () => {
        const obtenerReservaciones = await clienteAxios.get<ReservacionesRae[]>('/reservaciones/reservacionesconfirmadas')
        const arreglo = obtenerReservaciones.data;
    


        for (const i in arreglo) {
            obtenerReservaciones.data[i].horario_final_inicio = obtenerReservaciones.data[i].horario_final_inicio!.replaceAll("-", "/").split(".")[0].replace("T", " ")
            obtenerReservaciones.data[i].horario_final_fin = obtenerReservaciones.data[i].horario_final_fin!.replaceAll("-", "/").split(".")[0].replace("T", " ")

            obtenerReservaciones.data[i].horario_inicio_r = obtenerReservaciones.data[i].horario_final_inicio!.split(" ")[1]
            obtenerReservaciones.data[i].horario_fin_r  =  obtenerReservaciones.data[i].horario_final_fin!.split(" ")[1]

            if (obtenerReservaciones.data[i].mediador) {
                obtenerReservaciones.data[i].mediador!.nombre = obtenerReservaciones.data[i].mediador.nombre +' '+ obtenerReservaciones.data[i].mediador.ap_paterno +' '+ obtenerReservaciones.data[i].mediador.ap_materno 
            }
            
            arreglo[i].grupo!.n_estado = arreglo[i].grupo!.n_estado ? arreglo[i].grupo!.n_estado : arreglo[i].grupo!.estado.nombre_estado
            arreglo[i].grupo!.n_municipio = arreglo[i].grupo!.n_municipio ? arreglo[i].grupo!.n_municipio : arreglo[i].grupo!.municipio.nombre_municipio
            arreglo[i].grupo!.n_colonia = arreglo[i].grupo!.n_colonia ? arreglo[i].grupo!.n_colonia : arreglo[i].grupo!.colonia.nombre_colonia

            arreglo[i].grupo!.calle_num = Decrypt(arreglo[i].grupo!.calle_num!)

            arreglo[i].responsable!.nombre = Decrypt(arreglo[i].responsable?.nombre!)
            arreglo[i].responsable!.ap_paterno = Decrypt(arreglo[i].responsable?.ap_paterno!)
            arreglo[i].responsable!.ap_materno = Decrypt(arreglo[i].responsable?.ap_materno!)
            arreglo[i].responsable!.correo_elec = Decrypt(arreglo[i].responsable?.correo_elec!)
            arreglo[i].responsable!.telefono = Decrypt(arreglo[i].responsable?.telefono!)
        }

       
      
        setReservacionesConfirmadas(arreglo)

    }


    const confirmacionReservacionPost = async (values: ConfirmacionReservacion) => {
        const citaPost = await clienteAxios.post<ResponseDataSwet>("/reservaciones/confirmareservacion", values).catch(err => err.response)

        await Swal.fire({
            title: citaPost?.data.msg,
            icon: citaPost?.data.styles,
            showDenyButton: false,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#B38E5D',
            buttonsStyling: true,
            color: 'white',
            background: '#13322B'
        })
    }


    const confirmarRerserRally = async (values: ConfirmacionReservacion) => {
        const reservacionesPost = await clienteAxios.post<ResponseDataSwet>("/reservaciones/confirmarally", values).catch(err => err.response)
        await Swal.fire({
            title: reservacionesPost?.data.msg,
            icon: reservacionesPost?.data.styles,
            showDenyButton: false,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#B38E5D',
            buttonsStyling: true,
            color: 'white',
            background: '#13322B'
        })
    }

    const enviarEmailConfirmacionRally = async (values: ReservacionesRae, num_grupo_rally: string | number) => {
        /* const emailPost = await clienteAxios.post("/reservaciones/emailconfirmacionrally", {
             folio_reservacion:values.folio_reservacion,
             correo_elect: values.responsable.correo_elec,
             nombre: values.responsable.nombre,
             ap_paterno: values.responsable.ap_paterno,
             ap_materno: values.responsable.ap_materno,
             fecha_actividad: values.fecha_actividad,
             horario_inicio: values.horario_inicio,
             horario_fin: values.horario_fin,
             num_grupo_rally:num_grupo_rally
        }).catch(err => err.response) */

    }


    const actualizarCalendario = async (values: ConfirmacionReservacion) => {
        /*  const actualizarCalendario = await clienteAxios.post<ConfirmacionReservacion>("/calendario", values).catch(err => console.log(err))
         console.log(actualizarCalendario) */
    }

    interface EmailProps {
        id_reservacion?: number,
        id_mediador?: number,
        estado?: number,
        fecha_final?: string
        horario_final_inicio?: string | Date,
        horario_final_fin?: string | Date,

        nombre?: string,
        ap_paterno?: string,
        ap_materno?: string,
        correo_elec?: string,
        telefono?: number,

    }

    const enviarEmailConfirmacion = async (values: EmailProps) => {
        const emailPost = await clienteAxios.post("/reservaciones/emailconfirmacion", values).catch(err => console.log(err))

    }

    const cancelarReservacion = async (values: EmailProps) => {
        const cancelarReser = await clienteAxios.post("/reservaciones/cancelareservacion", values).catch(err => console.log(err))

    }




    return {
        reservacionesConfirmadas, reservacionConfirmadasGet, confirmacionReservacionPost,
        actualizarCalendario, enviarEmailConfirmacion, enviarEmailConfirmacionRally, confirmarRerserRally,
        cancelarReservacion,
    }
}

export default UseCitasConfirmadas