
import { AxiosError } from "axios";
import Axios from "../../../Config/Axios"
import { ResponseData } from "../../../InterfacesGlobales/InterfaceResponse";
import { LoginForm, UsuarioLogeado } from '../Interfaces/InterfaceLogin';
import { AlertaRae } from '../../../Components/Alertas/AlertaToastify';


const useLogin = () => {

  const iniciarSesion = async (datos: LoginForm): Promise<ResponseData<{ token: string }> | undefined> => {

    const res = await Axios.post<ResponseData<{ token: string }>>('/usuarios/iniciarsession', datos).catch((error: AxiosError<ResponseData>) => error.response)

    if (res?.status === 200) {

      return res.data
    } else {

      AlertaRae(res?.data.styles, res?.data.msg)
      return
    }


  }


  const obtenerPersonalLogeado = async () : Promise<UsuarioLogeado  | undefined> => {
    const res = await Axios.get<ResponseData<UsuarioLogeado>>('/usuarios/iniciarsession').catch((error: AxiosError<ResponseData>) => error.response)
    if (res) {
      return res.data.extras 
    } else {
      return
    }
   
  }


  return { 
    iniciarSesion,
    obtenerPersonalLogeado
  }
}

export default useLogin