import React from "react";
import Button from "react-bootstrap/Button";
import {
    PaginationChangePage,
    PaginationChangeRowsPerPage,
} from "react-data-table-component/dist/src/DataTable/types";
import ReactPaginate from "react-paginate";



import { BiChevronsLeft,BiChevronsRight } from "react-icons/bi";

interface Props {
    rowsPerPage: number;
    rowCount: number;
    currentPage: number;
    onChangePage: PaginationChangePage;
    onChangeRowsPerPage: PaginationChangeRowsPerPage;
}

function getNumberOfPages(rowCount: number, rowsPerPage: number) {
    return Math.ceil(rowCount / rowsPerPage);
}

const PaginacionDataTableAGN = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage,
    currentPage
}: Props) => {
    const handlePageNumber = (value: number) => {
        if (value) {
            onChangePage(Number(value), rowCount);
        }
    };

    const pages = getNumberOfPages(rowCount, rowsPerPage);

    return (
        <nav>
            <ul>
                <ReactPaginate
                    previousLabel={<BiChevronsLeft size={'1.5rem'} className='text-light'/>}
                    nextLabel={<BiChevronsRight size={'1.5rem'} className='text-light'/>}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={({ selected }) => {
                        handlePageNumber(selected + 1);
                    }}
                    containerClassName={
                        "pagination d-flex justify-content-center align-items-center  w-100"
                    }
                    activeClassName={" bg-warning rounded text-color-danger"}
                    pageLinkClassName={"btn border border-warning"}
                    pageClassName={"m-1"}
                    previousLinkClassName={"btn btn-warning"}
                    nextLinkClassName={"btn btn-warning"}
                />
            </ul>
        </nav>
    );
};

export default PaginacionDataTableAGN;
