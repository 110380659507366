import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { EventosAtributos } from '../Interfaces/InterfaceCalendario';
import UseCalendario from '../Hooks/UseCalendario';
import { default as FormRae } from 'react-bootstrap/Form'
import { Form, Formik } from 'formik';
import TablaRae from '../../../Components/Tablas/TablaRae';
import { ChangeEvent } from 'react';
import { format } from 'date-fns';

interface Props {
    registro_Seleccionado: EventosAtributos,
    returnSubmitSuccess: () => void,
}

const FormCalendario = ({ registro_Seleccionado, returnSubmitSuccess }: Props) => {

    const { eventos, ReservacionesAsignadasRally, reservacionesAsignadas } = UseCalendario()

    const [eventoSelect, setEventoSelect] = useState<EventosAtributos | any>({
        id_evento: '',
        title: '',
        allDay: 0,
        descripcion: '',
        notes: '',
        start: '',
        end: '',
        habilitado: '',
        responsable: '',
    })

    useEffect(() => {
        if (registro_Seleccionado) {

            setEventoSelect({
                id_evento: registro_Seleccionado.id_evento,
                title: registro_Seleccionado.title ? registro_Seleccionado.title.split(" ",)[0] + ' ' + registro_Seleccionado.title.replace("-->", "").split(" ",)[1] : '',
                allDay: registro_Seleccionado.allDay,
                descripcion: registro_Seleccionado.descripcion,
                notes: registro_Seleccionado.notes,
                start: format(new Date(registro_Seleccionado.start), 'HH:mm aa'),
                end: format(new Date(registro_Seleccionado.end), 'HH:mm aa'),
                habilitado: registro_Seleccionado.habilitado,
                responsable: registro_Seleccionado.responsable,
            })

            const object = {
                num_grupo_rally: registro_Seleccionado.title,
                fecha_final: format(new Date(registro_Seleccionado.start), 'yyyy-MM-dd')
            }

            ReservacionesAsignadasRally(object)
        }
    }, [registro_Seleccionado])

    if (registro_Seleccionado.title === 'Grupo 1' || registro_Seleccionado.title === 'Grupo 2' || registro_Seleccionado.title === 'Grupo 3' || registro_Seleccionado.title === 'Grupo 4') {

        var ActividadRally = true

    } else {
        var ActividadRally = false

    }

 

    interface propsAsignadas {
        folio_reservacion: string,
        num_grupo_rally: string,
        fecha_final: string | Date
    }


    return (

        <div>

            <Formik
                initialValues={eventoSelect}
                onSubmit={async (values) => {
                    /* await talleresPost(values)
                    returnSubmitSuccess() */
                    console.log(values)
                }}
                enableReinitialize
            >
                {({ handleChange, values, setFieldValue }) => (
                    <Form>

                        <FormRae.Group controlId="title" className="mb-3">
                            {ActividadRally ? (<>  <FormRae.Label>Número grupo</FormRae.Label> </>) : (<> <FormRae.Label>Folio Reservación</FormRae.Label> </>)}
                            <FormRae.Control
                                type="text"
                                name="title"
                                placeholder='Número Grupo / Folio Reservación'
                                onChange={handleChange}
                                value={values.title || ''}
                                disabled
                            />
                        </FormRae.Group>

                        <FormRae.Group controlId="descripcion" className="mb-3">
                            <FormRae.Label>Nombre actividad</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="descripcion"
                                placeholder='Nombre actividad'
                                onChange={handleChange}
                                value={values.descripcion || ''}
                                disabled
                            />
                        </FormRae.Group>

                        {ActividadRally ? (<>
                            <FormRae.Label>Reservaciones del grupo</FormRae.Label>
                            <div className=' rounded-2  border border-5 border-warning overflow-scroll p-2 opacity-75 ' style={{ height: '150px' }} >
                                {
                                    reservacionesAsignadas.map((reservacion: propsAsignadas, index) =>
                                    (
                                        <p className='fw-normal' key={index}> {reservacion.folio_reservacion}  </p>
                                    ))
                                }

                            </div>
                        </>) : (<>

                            <FormRae.Group controlId="notes" className="mb-3">
                                <FormRae.Label>Nombre mediador</FormRae.Label>
                                <FormRae.Control
                                    type="text"
                                    name="notes"
                                    placeholder='Nombre mediador'
                                    onChange={handleChange}
                                    value={values.notes || ''}
                                    disabled
                                />
                            </FormRae.Group>



                        </>)}

                        <FormRae.Group controlId="start" className="mt-2 mb-3">
                            <FormRae.Label>Horario de inicio</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="start"
                                placeholder='Horario inicio'
                                onChange={handleChange}
                                value={values.start || ''}
                                disabled
                            />
                        </FormRae.Group>

                        <FormRae.Group controlId="end" className="mb-3">
                            <FormRae.Label>Horario de término</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="end"
                                placeholder='Horario fin'
                                onChange={handleChange}
                                value={values.end || ''}
                                disabled
                            />
                        </FormRae.Group>

                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default FormCalendario