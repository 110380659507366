import { ErrorMessage, Form, Formik } from 'formik'
import { default as FormRae } from 'react-bootstrap/Form'
import type { IntFormUsuarioPassword } from '../Interfaces/InterfaceFormPassword'
import { Button } from 'react-bootstrap'
import { MediadorRae } from '../Interfaces/InterfaceMediadores'
import UseUsuarios from '../Hooks/UseUsuarios'
import * as Yup from 'yup'

interface Props {
    registro_Seleccionado?: MediadorRae | undefined
}

const FormUsuarioPassword = ({ registro_Seleccionado }: Props) => {
    const { cambiarContraseñaUsuario } = UseUsuarios()
    const initialValues: IntFormUsuarioPassword = {
        id_mediador: registro_Seleccionado
            ? registro_Seleccionado.id_mediador
            : 0,
        password: '',
        confirm_password: ''
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
                await cambiarContraseñaUsuario(values)
            }}
            validationSchema={Yup.object().shape({
                password: Yup.string().required('Este campo es obligatorio'),
                confirm_password: Yup.string()
                    .required('Este campo es obligatorio')
                    .oneOf(
                        [Yup.ref('password'), null],
                        'Las contraseñas no coinciden'
                    )
            })}
        >
            {({ values, handleChange }) => (
                <Form>
                    <FormRae.Group controlId="id_mediador">
                        <FormRae.Label>ID Usuario</FormRae.Label>
                        <FormRae.Control
                            type="text"
                            name="id_mediador"
                            value={values.id_mediador || ''}
                            disabled
                            placeholder="Número usuario"
                        />
                    </FormRae.Group>
                    <FormRae.Group controlId="password">
                        <FormRae.Label>Contraseña</FormRae.Label>
                        <FormRae.Control
                            type="password"
                            name="password"
                            onChange={handleChange}
                            placeholder="Contraseña"
                        />
                        <ErrorMessage
                            name="password"
                            className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                            component={'div'}
                        />
                    </FormRae.Group>
                    <FormRae.Group controlId="confirm_password">
                        <FormRae.Label>Confirmar contraseña</FormRae.Label>
                        <FormRae.Control
                            type="password"
                            name="confirm_password"
                            onChange={handleChange}
                            placeholder="Confirmar contraseña"
                        />
                        <ErrorMessage
                            name="confirm_password"
                            className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                            component={'div'}
                        />
                    </FormRae.Group>

                    <Button
                        variant="primary"
                        type="submit"
                        className="border border-light mt-3"
                    >
                        Actualizar
                    </Button>
                </Form>
            )}
        </Formik>
    )
}

export default FormUsuarioPassword
