import React, { useState } from 'react'
import { AlertaRae } from '../../../Components/Alertas/AlertaToastify'
import clienteAxios from '../../../Config/Axios'
import { ResponseData } from '../../../InterfacesGlobales/InterfaceResponse'    
import { CuentacuentosProps, CuentacuentosPropTabla} from '../Interfaces/InterfaceCuentacuentos'




const UseCuentos = () => {
    
    const [cuentos, setCuentos] = useState<CuentacuentosProps[]>([])

    const [cuentosAdmin, setCuentosAdmin] = useState<CuentacuentosProps[]>([])


    //-------Peticiones para el sevidor 
    const cuentacuentosGet = async () => {
        const obtenerCuentacuentos = await clienteAxios.get<CuentacuentosProps[]>('/cuentacuentos/cuentoshabilitados');
        setCuentos( obtenerCuentacuentos.data)
    }

    const cuentacuentosGetAdmin = async () => {
        const obtenerCuentacuentos = await clienteAxios.get<CuentacuentosProps[]>('/cuentacuentos');
        setCuentosAdmin( obtenerCuentacuentos.data)
    }

    const cuentacuentosPost = async (values: CuentacuentosPropTabla) => {
        const respuesta = await clienteAxios.post<ResponseData>("/cuentacuentos", values).catch(err => console.log(err))
        AlertaRae(respuesta?.data.styles, respuesta?.data.msg)
    } 
      
    return {
        cuentos, cuentacuentosGet, cuentacuentosPost, cuentacuentosGetAdmin, cuentosAdmin
    }
}

export default UseCuentos;