import { ErrorMessage, Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';

import { default as FormRae } from 'react-bootstrap/Form'
import UseReservacionesRally from '../Hooks/UseReservacionesRally';
import { ReservacionesPropTabla, ReservacionesRae } from '../Interfaces/InterfaceReservacionesRally';
import { useContext } from 'react';
import { ContextApp } from '../../../Context/StateApp';

interface Props {
    registro_Seleccionado: ReservacionesRae | undefined;
    returnSubmitSuccess: () => void,
    cerrarModalForm: () => void
}



const FormVehiculos = ({ cerrarModalForm, registro_Seleccionado, returnSubmitSuccess }: Props) => {
    const { state: { usuario_logeado, logeado } } = useContext(ContextApp)

    const { actualizarReservacion } = UseReservacionesRally()
    const [mostraModal, setMostraModal] = useState(false);
    const [reservacionSelect, setReservacionSelect] = useState<ReservacionesPropTabla | any>({

        vehiculos: {
            id_vehiculo: '',
            tipo_vehiculo: '',
            num_vehiculos: '',
            placas: '',
            usuario_logeado:usuario_logeado?.id_mediador
        }

    })

    useEffect(() => {
        if (registro_Seleccionado) {
            setReservacionSelect({
                id_reservacion: registro_Seleccionado.id_reservacion,
                id_vehiculo: registro_Seleccionado.vehiculos!.id_vehiculo,
                tipo_vehiculo: registro_Seleccionado.vehiculos!.tipo_vehiculo,
                num_vehiculos: registro_Seleccionado.vehiculos!.num_vehiculos,
                placas: registro_Seleccionado.vehiculos!.placas,
                usuario_logeado:usuario_logeado?.id_mediador
            })
        }

    }, [registro_Seleccionado])

    return (
        <div>


            <Formik
                initialValues={reservacionSelect}
                onSubmit={async (values) => {
                    actualizarReservacion(values)
                }}
                enableReinitialize
            >
                {({ values, handleChange, setFieldValue }) => (
                    <Form>

                        <FormRae.Group controlId="tipo_vehiculo" className="w-100">
                            <FormRae.Label>Tipo vehículo</FormRae.Label>
                            <FormRae.Select
                                name="tipo_vehiculo"
                                as="select"
                                value={values.tipo_vehiculo || ''}
                                onChange={handleChange}
                            >
                                <option value="">-- Selecciona el tipo de vehículo--</option>
                                <option key="Autobús" value="Autobús">Autobús</option>
                                <option key="Automóvil" value="Automóvil">Automóvil</option>
                            </FormRae.Select>
                        </FormRae.Group>

                        <FormRae.Group controlId="num_vehiculos" className="col-12 p-1">
                            <FormRae.Label>Número de vehículos</FormRae.Label>
                            <FormRae.Select
                                name="num_vehiculos"
                                onChange={handleChange}
                                value={values.num_vehiculos || ''}
                            >
                                <option value="">-- Selecciona el número de vehículos --</option>
                                <option value="1">1</option>
                            </FormRae.Select>
                            <ErrorMessage name='num_vehiculos' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                        </FormRae.Group>

                        <FormRae.Group controlId="placas" className="mb-1">
                            <FormRae.Label>Números de las placas</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="placas"
                                value={values.placas || ''}
                                onChange={(e) => setFieldValue("placas", e.target.value.toUpperCase())}
                            />
                        </FormRae.Group>
                        <br />
                        <Button variant="primary"
                            type="submit"
                        >
                            Actualizar
                        </Button>

                    </Form>
                )}
            </Formik>




        </div>
    )
}

export default FormVehiculos