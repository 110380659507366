import React, { useEffect, useState } from 'react'

import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import enES from 'date-fns/locale/es'
import { addHours, format, parse, startOfWeek, getDay, compareAsc } from 'date-fns'
import UseCalendario from '../Hooks/UseCalendario';
import { EventosAtributos } from '../Interfaces/InterfaceCalendario'
import FormCalendario from '../Components/FormCalendario';
import TablaRae from '../../../Components/Tablas/TablaRae';
import ModalCalendario from '../../../Components/Modales/ModalCalendario';
//import { EventoModificado } from '../Helpers/CustomEvent';



const PageCalendario = () => {

  const { eventos, calendario, ReservacionesAsignadasRally } = UseCalendario()
  const [mostrarModal, setMostrarModal] = useState(false)
  const [registroSeleccionado, setRegistroSeleccionado] = useState<EventosAtributos | any>({
    id_evento: '',
    title: '',
    allDay: '',
    descripcion: '',
    notes: '',
    start: '',
    end: '',
    habilitado: '',
    mostrarModal: '',
  })


  useEffect(() => {
    eventos()
    setRegistroSeleccionado(false)
  }, [])

  const cerrarModal = () => {
    setMostrarModal(false)
    if (registroSeleccionado) {
      setRegistroSeleccionado(false)
    }
  }

  const fecha = new Date()

  const locales = {
    'es': enES,
  }

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  })

  const messages = () => {
    return {
      allDay: 'Todo el día',
      previous: '<',
      next: '>',
      today: 'Hoy',
      month: 'Mes',
      week: 'Semana',
      day: 'Día',
      agenda: 'Agenda',
      date: 'Fecha',
      time: 'Hora',
      event: 'Reservaciones',
      noEventsInRange: 'No hay eventos en este rango',
      showMore: (total: any) => `+ Ver más (${total})`
    }
  };


  return (
    <div>
      <hr className="my-1 gob" />
      <br />
      <Calendar

        localizer={localizer}
        events={calendario}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '700px' }}
        className=""
        culture='es'
        messages={messages()}
        defaultView='agenda'
      /*   components={{
          event: 
        }} */
        onSelectEvent={(event) => {
          
         
          const objeto = Object.assign({}, event);

          if (!objeto.allDay) {
            objeto.start = format(new Date(event.start), "yyyy-MM-dd'T'HH:mm:ss")
            objeto.end = format(new Date(event.end), "yyyy-MM-dd'T'HH:mm:ss")
          } else {
            
            objeto.start = format(new Date(objeto.start), "yyyy-MM-dd")
            objeto.end = format(new Date(objeto.end), "yyyy-MM-dd")
          }
          setRegistroSeleccionado(objeto)
          setMostrarModal(true)

          const buscarGrupos = {
            num_grupo_rally: event.title,
            fecha_final: format(new Date(event.start), 'yyyy-MM-dd')
          }

          ReservacionesAsignadasRally(buscarGrupos)
        }}

        dayPropGetter={date => {
          if (fecha) {
            if ((date.getDate() === fecha.getDate()) && (date.getMonth() === fecha.getMonth()) && (date.getFullYear() === fecha.getFullYear()))
              return {
                style: {
                  backgroundColor: 'rgba(var(--bs-info-rgb), .25)',
                },
              }
            else return {}
          } else {
            return {}
          }
        }}

        eventPropGetter={(event, start, end, isSelected) => {

          if (event.title === 'Grupo 1' || event.title === 'Grupo 2' || event.title === 'Grupo 3' || event.title === 'Grupo 4') {
            return {
              style: {
                backgroundColor: 'rgba(var(--bs-danger-rgb))',
              },
            }
          }

          return {
            style: {
              backgroundColor: 'rgba(var(--bs-warning-rgb))',
            },
          }
        }}
      />

      <ModalCalendario
        size='lg'
        show_modal={mostrarModal}
        closeModal={cerrarModal}
      >
        <ModalCalendario.ModalHeader >
          <h2 style={{ color: "white" }}>Información Reservación</h2>
        </ModalCalendario.ModalHeader>

        <ModalCalendario.ModalBody >
          <FormCalendario registro_Seleccionado={registroSeleccionado} returnSubmitSuccess={() => {
            eventos()
            cerrarModal()
          }} />

        </ModalCalendario.ModalBody>
      </ModalCalendario>


    </div >
  )
}

export default PageCalendario