
import { ErrorMessage, Form, Formik } from 'formik'
import { useState, useEffect, ChangeEvent, useContext } from 'react';
import { Button, Modal, Tabs } from 'react-bootstrap';
import { format, getDay } from 'date-fns/esm';
import DatePicker from 'react-datepicker';
import { addMonths } from 'date-fns';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import { ReservacionesRae, ReservacionesPropTabla } from '../Interfaces/InterfaceReservacionesRally';
import ModalGrande from '../../../Components/Modales/ModalGrande';
import { default as FormRae } from 'react-bootstrap/Form'
import TablaRae from '../../../Components/Tablas/TablaRae';
import { ContextApp } from '../../../Context/StateApp';
import Loading from '../../../Components/Loaders/Loading';


import UseMediadores from '../Hooks/UseMediadores';
import UseReservacionesRally from '../Hooks/UseReservacionesRally';





interface Props {
    registro_Seleccionado: ReservacionesRae | undefined;
    returnSubmitSuccess: () => void,
    cerrarModalForm: () => void
}


const FormDatosGenerales = ({ cerrarModalForm, registro_Seleccionado, returnSubmitSuccess }: Props) => {

    const { state: { usuario_logeado, logeado } } = useContext(ContextApp)
    
    const [mostraModal, setMostraModal] = useState(false);
    const { mediadoresGet, mediadores } = UseMediadores();
    const {cancelarReservacion, cancelarReservacionEmail} = UseReservacionesRally()
   

    const [loading, setLoading] = useState(false)

    const [reservacionSelect, setReservacionSelect] = useState<ReservacionesPropTabla | any>({
        id_reservacion: '',
        folio_reservacion: '',

        modalidad: '',
        fecha_registro: '',
        fecha_actividad: '',
        horario_inicio: '',
        horario_fin: '',

        momento_act: '',
        prim_vez: '',
        enterado_por: '',
        id_guia: '',
        estado: '',
        fecha_final: '',

        cuento: {
            id_cuento: '',
            nombre_c: '',
        },

        taller: {
            id_taller: '',
            nombre_t: '',
        },

        idactividad: {
            id_actividad: '',
            nombre_actividad: '',
        },

        cancelar_reservacion_dos: false,
        motivo_cancelacion_dos: ''


    })
    const isWeekday = (date: any) => {
        const day = getDay(date);
        return day !== 0 && day !== 6;
    };
    const cerrarModal = () => {
        setMostraModal(false)
        if (registro_Seleccionado) {
            setReservacionSelect(false)
        }
    }
    useEffect(() => {
        mediadoresGet()
    }, [])

    useEffect(() => {
        if (registro_Seleccionado) {

            setReservacionSelect({
                id_reservacion: registro_Seleccionado.id_reservacion,
                folio_reservacion: registro_Seleccionado.folio_reservacion,
                nombre_actividad: registro_Seleccionado.idactividad!.nombre_actividad,
                modalidad: registro_Seleccionado.modalidad,

                fecha_actividad: registro_Seleccionado.fecha_actividad,
                horario_inicio: format(new Date(registro_Seleccionado.horario_inicio!.toString().split(".")[0]), "HH:mm"),
                horario_fin: format(new Date(registro_Seleccionado.horario_fin!.toString().split(".")[0]), "HH:mm"),

                momento_act: registro_Seleccionado.momento_act,
                prim_vez: registro_Seleccionado.prim_vez,
                enterado_por: registro_Seleccionado.enterado_por,

                id_responsable: registro_Seleccionado.responsable!.id_responsable,
                nombre: registro_Seleccionado.responsable!.nombre,
                ap_paterno: registro_Seleccionado.responsable!.ap_paterno,
                ap_materno: registro_Seleccionado.responsable!.ap_materno,
                correo_elec: registro_Seleccionado.responsable!.correo_elec,
                telefono: registro_Seleccionado.responsable!.telefono,

                id_guia: '',
                fecha_final: '',
                estado: 1,

                cancelar_reservacion_dos: false,
                motivo_cancelacion_dos: ''
            })
        }

    }, [registro_Seleccionado])

    const validaciones = Yup.object().shape({
        cancelar_reservacion_dos: Yup.boolean().required("Este campo es obligatorio")
            .oneOf([true], "Este campo es obligatorio"),
        motivo_cancelacion_dos: Yup.string().
            when('cancelar_reservacion_dos', {
                is: (value: boolean) => value === true,
                then: Yup.string().required('Este campo es obligatorio')
            }),

    })

    interface PropsMediadores {
        id_mediador?: number,
        nombre: string,
        ap_paterno: string,
        ap_materno: string
    }


    return (
        <div>
            <Formik
                initialValues={reservacionSelect}
                onSubmit={async (values) => {
                    await Swal.fire({
                        title: '¿Estás seguro de cancelar?',
                        showDenyButton: true,
                        confirmButtonText: 'Aceptar',
                        denyButtonText: `Cancelar`,
                        confirmButtonColor: '#13322B',
                        denyButtonColor: '#9D2449',
                    }).then(async (result) => {

                        if (result.isConfirmed) {

                            const object = {
                                id_reservacion: values.id_reservacion,
                                cancelar_reservacion_dos: values.cancelar_reservacion_dos,
                                motivo_cancelacion_dos: values.motivo_cancelacion_dos
                            }

                            const email = {
                                nombre: values.nombre,
                                primer_apellido: values.ap_paterno,
                                segundo_apellido: values.ap_materno,
                                correo_electronico: values.correo_elec,
                                folio_reservacion: values.folio_reservacion,
                            }

                            setLoading(true)
                              await cancelarReservacionEmail(email)
                              await cancelarReservacion(object)
                              returnSubmitSuccess() 
                            setLoading(false)

                        } else if (result.isDenied) {

                        }
                    })
                }}
                enableReinitialize
                validationSchema={validaciones}
            >
                {({ handleChange, values, setFieldValue }) => (


                    <Form>

                        <Modal
                            size="sm"
                            show={loading}
                            onHide={() => setLoading(false)}
                            backdrop="static"
                            centered
                            className='m-0 p-0'
                        >
                            <Modal.Body>
                                <Loading />
                            </Modal.Body>
                        </Modal>

                        <div className='row d-flex'>
                            <FormRae.Group controlId="id_reservacion" className="d-none">
                                <FormRae.Label>Folio reservación</FormRae.Label>
                                <FormRae.Control
                                    type="text"
                                    name="id_reservacion"
                                    placeholder='Folio Reservación'
                                    value={values.id_reservacion || ''}
                                    disabled
                                />
                            </FormRae.Group>

                            <FormRae.Group controlId="folio_reservacion" className="mb-3 col-12">
                                <FormRae.Label>Folio de reservación</FormRae.Label>
                                <FormRae.Control
                                    type="text"
                                    name="folio_reservacion"
                                    placeholder='Folio Reservación'
                                    value={values.folio_reservacion || ''}
                                    disabled
                                />
                            </FormRae.Group>

                            <FormRae.Group controlId="nombre_actividad" className="mb-3 col-6">
                                <FormRae.Label>Nombre de la actividad </FormRae.Label>
                                <FormRae.Control
                                    type="text"
                                    name="nombre_actividad"
                                    placeholder='Nombre Actividad'
                                    value={values.nombre_actividad || ''}
                                    disabled
                                />
                            </FormRae.Group>

                            <FormRae.Group controlId="modalidad" className="mb-3 col-6">
                                <FormRae.Label>Modalidad</FormRae.Label>
                                <FormRae.Control
                                    type="text"
                                    name="modalidad"
                                    placeholder='Modalidad Cita'
                                    value={values.modalidad || ''}
                                    disabled
                                />
                            </FormRae.Group>

                            <FormRae.Group controlId="fecha_uno" className="mb-3 col-12">
                                <FormRae.Label>Fecha de la actividad</FormRae.Label>
                                <FormRae.Control
                                    type="text"
                                    name="fecha_uno"

                                    disabled
                                    value={values.fecha_actividad || ''}
                                />
                            </FormRae.Group>

                            <FormRae.Group controlId="horario_inicio" className="mb-3 ">
                                <FormRae.Label>Horario de inicio</FormRae.Label>
                                <DatePicker
                                    value={values.horario_inicio || ''}
                                    name="horario_inicio"
                                    onChange={(e: any) => setFieldValue("horario_inicio", format(new Date(e), 'HH:mm'))}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeCaption="Time"
                                    dateFormat="HH:mm"
                                    className=' rounded-2  form-control '
                                    placeholderText='-- Seleciona un horario -- '
                                    disabled
                                />
                                <ErrorMessage name='horario_final_inicio' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                            </FormRae.Group>

                            <FormRae.Group controlId="horario_fin" className="mb-3 ">
                                <FormRae.Label>Horario de término</FormRae.Label>
                                <DatePicker
                                    value={values.horario_fin || ''}
                                    name="horario_fin"
                                    onChange={(e: any) => setFieldValue("horario_fin", format(new Date(e), 'HH:mm'))}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeCaption="Time"
                                    dateFormat="HH:mm:ss"
                                    className=' rounded-2  form-control '
                                    placeholderText='-- Seleciona un horario -- '
                                    disabled
                                />
                                <ErrorMessage name='horario_final_inicio' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                            </FormRae.Group>

                            <FormRae.Group controlId="cancelar_reservacion" className="mb-3 pr-3">
                                <FormRae.Label>Cancelar Reservación</FormRae.Label>
                                <FormRae.Check
                                    type="checkbox"
                                    name="cancelar_reservacion_dos"
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("cancelar_reservacion_dos", e.target.checked)}
                                    checked={values.cancelar_reservacion_dos || false}

                                />

                            </FormRae.Group>

                            <ErrorMessage name='cancelar_reservacion_dos' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />

                            {values.cancelar_reservacion_dos === true ?
                                (<>
                                    <FormRae.Group controlId="motivo_cancelacion" className="mb-3">
                                        <FormRae.Label>* Motivo de cancelación</FormRae.Label>
                                        <FormRae.Control
                                            as="textarea"
                                            type="text"
                                            name="motivo_cancelacion_dos"
                                            onChange={handleChange}
                                            value={values.motivo_cancelacion_dos || ''}
                                            style={{ height: "200px" }}
                                            placeholder='Ingresa el motivo de la cancelación'
                                        />
                                        <ErrorMessage name='motivo_cancelacion_dos' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                                    </FormRae.Group>

                                </>) : ''}

                        </div>

                        <TablaRae.ModalFooter>
                            <Button variant="primary"
                                type="submit"
                            >
                                Cancelar Reservación
                            </Button>
                            <Button variant="primary" onClick={cerrarModalForm} >
                                Cerrar
                            </Button>

                        </TablaRae.ModalFooter>




                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default FormDatosGenerales