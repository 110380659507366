import React from 'react'
import TablaContactos from '../Components/TablaContactos';

const PageContactos = () => {
  return (
    <div>
        <hr className="my-1 gob" />
       <TablaContactos />
    </div>
  )
}

export default PageContactos