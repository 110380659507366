import React, { useState } from 'react'
import { AlertaRae } from '../../../Components/Alertas/AlertaToastify'
import clienteAxios from '../../../Config/Axios'
import { ResponseData } from '../../../InterfacesGlobales/InterfaceResponse'
import { TalleresProps, TalleresPropTabla } from '../Interfaces/InterfaceTalleres'


import CryptoJS from 'crypto-js';


const UseTalleres = () => {


    const [talleres, setTalleres] = useState<TalleresProps[]>([])
    const [talleresAdmin, setTalleresAdmin] = useState<TalleresProps[]>([])

    //-------Peticiones para el sevidor 

    const talleresGet = async () => {
        const obtenerTalleres = await clienteAxios.get<TalleresPropTabla[]>('/talleres/tallereshabilitados');


        setTalleres(obtenerTalleres.data)
    }

    const talleresGetAdmin = async () => {
        const obtenerTalleres = await clienteAxios.get<TalleresPropTabla[]>('/talleres');

      


     
 
        setTalleresAdmin(obtenerTalleres.data)
    }

    const talleresPost = async (values: TalleresPropTabla) => {
        const res = await clienteAxios.post<ResponseData>("/talleres", values).catch(err => console.log(err))
        
        AlertaRae(res?.data.styles, res?.data.msg)
    }




    return {
        talleres, talleresGet, talleresPost, talleresGetAdmin, talleresAdmin
    }
}

export default UseTalleres