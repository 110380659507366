import { ErrorMessage, Form, Formik } from 'formik'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { default as FormRae } from 'react-bootstrap/Form'
import { CuentacuentosProps, CuentacuentosPropTabla } from '../Interfaces/InterfaceCuentacuentos';
import TablaRae from '../../../Components/Tablas/TablaRae';
import { Button } from 'react-bootstrap';
import UseCuentos from '../Hooks/UseCuentos';
import * as Yup from 'yup'
import { ContextApp } from '../../../Context/StateApp';

interface Props {
    registro_Seleccionado: CuentacuentosPropTabla;
    returnSubmitSuccess: () => void,
    cerrarModalForm: () => void,
}


const FormCuentacuentos = ({ registro_Seleccionado, returnSubmitSuccess, cerrarModalForm }: Props) => {

    const { cuentacuentosPost } = UseCuentos()
    const {state:{ usuario_logeado, logeado }} = useContext(ContextApp)
    const [cuentoSelect, setCuentoSelect] = useState<CuentacuentosPropTabla | any>({
        id_cuento: '',
        nombre_c: '',
        estado: '',
        usuario_logeado:usuario_logeado?.id_mediador,
    })

    useEffect(() => {
        if (registro_Seleccionado) {
            setCuentoSelect({
                id_cuento: registro_Seleccionado.id_cuento,
                nombre_c: registro_Seleccionado.nombre_c,
                estado: registro_Seleccionado.estado === 1 ? true : false,  
                usuario_logeado:usuario_logeado?.id_mediador,
            })
        }
    }, [registro_Seleccionado])

    const validaciones = Yup.object().shape({
        nombre_c: Yup.string().required('Este campo es obligatorio'),    
    })
    

    return (
        <div>

            <Formik
                initialValues={cuentoSelect}
                onSubmit={
                    async (values) => {
                        await cuentacuentosPost(values)
                        returnSubmitSuccess()
                    }}
                enableReinitialize //Importante atributo para reiniciar el componente
                validationSchema={validaciones}
            >

                {({ handleChange, values, setFieldValue }) => (
                    <Form  >
                        <FormRae.Group controlId="id_cuento" className="mb-3">
                            <FormRae.Label>Número</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="id_cuento"
                                disabled
                                value={values.id_cuento || ''}
                                placeholder="Número cuentacuentos"

                            />
                        </FormRae.Group>

                        <FormRae.Group controlId="nombre_c" className="mb-3">
                            <FormRae.Label>Nombre</FormRae.Label>
                            <FormRae.Control
                                type="text"
                                name="nombre_c"
                                onChange={handleChange}
                                value={values.nombre_c || ''}
                                placeholder="Escribe el nombre del cuentacuentos"
                            />
                            <ErrorMessage name='nombre_c' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                        </FormRae.Group>

                        <FormRae.Group controlId="estado" className="mb-3">
                            <FormRae.Label>Estado</FormRae.Label>
                            <FormRae.Check
                                type="checkbox"
                                name="estado"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("estado", e.target.checked)}
                                checked={values.estado || '' }
                            />
                        </FormRae.Group>
                        
                        <TablaRae.ModalFooter>
                            <Button variant="primary"
                                type="submit"
                            >
                                {registro_Seleccionado ? "Actualizar" : "Guardar"}
                            </Button>
                            <Button variant="primary" onClick={cerrarModalForm} >
                                Cerrar
                            </Button>

                        </TablaRae.ModalFooter>
                    </Form>


                )}
            </Formik>
        </div >
    )
}

export default FormCuentacuentos