import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import PaginaBase from '../Components/PaginaBase'
import paginas, { Pagina } from './Paginas';


const index = () => {

    const renderRoutesRecursive = (routes: Pagina[]) =>{
        
        return routes.map((route, index) =>
            <Route
                key={index}
                path={route.path}
                element={<PaginaBase title={route.title} children={route.element} />}
                index={route.index}
                
            >
                {route.childrens && renderRoutesRecursive(route.childrens)}
            </Route>
        );
    }
    return (
        <BrowserRouter >
            <Routes>            
            {renderRoutesRecursive(paginas)}
            </Routes>
        </BrowserRouter>


    )
}

export default index