import React from 'react'
import TablaReservacionesConfirmadas from '../Components/TablaReservacionesConfirmadas'

const PageCitasConfirmadas = () => {
  return (
    <div>
       <hr className="my-1 gob" />
       <br />
      <TablaReservacionesConfirmadas/>
    </div>
  )
}

export default PageCitasConfirmadas