import React, { useEffect, useState } from "react";
import UseReservacionesConfirmadas from "../Hooks/UseReservacionesConfirmadas";
import { ReservacionesPropTabla } from "../Interfaces/InterfaceReservacionesConfirmadas";
import { TableColumn } from "react-data-table-component";
import { Button } from "react-bootstrap";
import TablaRae from "../../../Components/Tablas/TablaRae";
import ModalGrande from "../../../Components/Modales/ModalGrande";
import FormReservacionConfirmada from "./FormReservacionesConfirmadas";
import format from "date-fns/format";
//import  ReactHTMLTableToExcel from "react-html-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";

import { BiMessageSquareEdit } from "react-icons/bi";
import index from "../../../Routing/index";
const TablaReservacionesConfirmadas = () => {
  const { reservacionesConfirmadas, reservacionConfirmadasGet } =
    UseReservacionesConfirmadas();
  const [mostrarModal, setMostrarModal] = useState(false);

  const [registroSeleccionado, setRegistroSeleccionado] = useState<
    ReservacionesPropTabla | any
  >({
    id_reservacion: "",
    folio_reservacion: "",

    modalidad: "",
    fecha_registro: "",
    fecha_final: "",
    horario_final_inicio: "",
    horario_final_fin: "",

    momento_act: "",
    prim_vez: "",
    enterado_por: "",
    cancelar_reservacion: "",
    estado: "",

    mediador: {
      id_mediador: "",
      nombre: "",
      ap_paterno: "",
      ap_materno: "",
      correo_electronico: "",
      estado: "",
    },

    taller: {
      id_taller: "",
      nombre_t: "",
    },

    cuento: {
      id_cuento: "",
      nombre_c: "",
    },

    grupo: {
      tipo_grupo: "",
      numero_hom: "",
      numero_muj: "",
      codigo_postal: "",
      pais: {
        id_pais: "",
        nombre_pais: "",
      },
      estato: {
        id_estado: "",
        nombre_estado: "",
      },
      municipio: {
        id_municipio: "",
        nombre_municipio: "",
      },
      colonia: {
        id_colonia: "",
        nombre: "",
      },
      calle_num: "",
      grado_escolar: "",
    },

    responsable: {
      nombre: "",
      ap_paterno: "",
      ap_materno: "",
      correo_elec: "",
      telefono: "",
    },

    vehiculos: {
      tipo_vehiculo: "",
      num_vehiculos: "",
      placas: "",
    },

    idactividad: {
      id_actividad: "",
      nombre_actividad: "",
    },
  });

  useEffect(() => {
    reservacionConfirmadasGet();

    setRegistroSeleccionado(false);
  }, []);

  const cerrarModal = () => {
    setMostrarModal(false);
    if (registroSeleccionado) {
      setRegistroSeleccionado(false);
    }
  };

  console.log("holas");

  const columns: TableColumn<ReservacionesPropTabla | any>[] = [
    {
      cell: (row) => (
        <div>
          <Button
            variant="warning"
            onClick={() => {
              setRegistroSeleccionado(row);

              setMostrarModal(true);
            }}
          >
            <BiMessageSquareEdit size={"1.3rem"} className="text-light" />
          </Button>
        </div>
      ),
      grow: 1,
    },

    {
      name: "Folio Reservación",
      selector: (row) => row.folio_reservacion,
      grow: 2,
    },
    {
      name: "Fecha",
      selector: (row) => row.fecha_final,
      grow: 2,
    },
    {
      name: "Horario de inicio",
      selector: (row) =>
        format(new Date(row.horario_final_inicio!), "HH:mm aa"),
      grow: 2,
    },
    {
      name: "Horario de término",
      selector: (row) => format(new Date(row.horario_final_fin!), "HH:mm aa"),
      grow: 2,
    },
  ];

  const headers = [
    { label: "Folio", key: "folio_reservacion" },
    { label: "Nombre Actividad", key: "idactividad.nombre_actividad" },
    { label: "Modalidad", key: "modalidad" },
    { label: "Momento Actividad", key: "momento_act" },
    { label: "Tema del cuentacuentos", key: "cuento.nombre_c" },
    { label: "Tema del taller", key: "taller.nombre_t" },

    { label: "Tipo grupo", key: "grupo.tipo_grupo" },

    { label: "Nombre Institución", key: "grupo.nombre_institucion" },
    { label: "Número de hombres", key: "grupo.numero_hom" },
    { label: "Número de mujeres", key: "grupo.numero_muj" },
    { label: "Sexo", key: "grupo.sexo" },

    { label: "País", key: "grupo.pais.nombre_pais" },

    { label: "Estado", key: "grupo.n_estado" },
    { label: "Municipio ", key: "grupo.n_municipio" },
    { label: "Colonia ", key: "grupo.n_colonia" },

    { label: "Código postal", key: "grupo.codigo_postal" },
    { label: "Calle y número", key: "grupo.calle_num" },
    { label: "Grado escolar", key: "grupo.grado_escolar" },

    { label: "Nombre del responsable", key: "responsable.nombre" },
    { label: "Primer apellido", key: "responsable.ap_paterno" },
    { label: "Segundo apellido", key: "responsable.ap_materno" },
    { label: "Correo electrónico", key: "responsable.correo_elec" },
    { label: "Teléfono", key: "responsable.telefono" },

    { label: "Tipo vehículo", key: "vehiculos.tipo_vehiculo" },
    { label: "Número de vehículos", key: "vehiculos.num_vehiculos" },
    { label: "Placas", key: "vehiculos.placas" },

    { label: "Primera vez", key: "prim_vez" },
    { label: "Enterado por", key: "enterado_por" },
    { label: "Descripción otro", key: "descripcion_otro" },
    { label: "Expectativas/Intereses", key: "expectativas" },

    { label: "Mediador asignado", key: "mediador.nombre" },

    { label: "Fecha asignada", key: "fecha_final" },
    { label: "Horario de inicio", key: `horario_inicio_r` },
    { label: "Horario de término", key: "horario_fin_r" },
  ];

  return (
    <div>
      <CSVLink
        data={reservacionesConfirmadas}
        headers={headers}
        filename="Reservaciones"
        type=""
      >
        <Button variant="warning"> Exportar </Button>
      </CSVLink>

      <TablaRae
        columns={columns}
        data={reservacionesConfirmadas}
        //onclicNuevo={() => { setMostrarModal(true) }}
        closeModal={cerrarModal}
        show_modal={mostrarModal}
        paginationPerPage={20}
      />

      <ModalGrande size="xl" show_modal={mostrarModal} closeModal={cerrarModal}>
        <ModalGrande.ModalHeader>
          <h2 style={{ color: "white" }}>Información Reservación</h2>
        </ModalGrande.ModalHeader>

        <ModalGrande.ModalBody>
          <FormReservacionConfirmada
            cerrarModalForm={cerrarModal}
            registro_Seleccionado={registroSeleccionado}
            returnSubmitSuccess={() => {
              reservacionConfirmadasGet();
              cerrarModal();
            }}
          />
        </ModalGrande.ModalBody>
      </ModalGrande>
    </div>
  );
};

export default TablaReservacionesConfirmadas;
