import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'

const Loading = () => {

    return (
        <div className='bg-primary'>
            <h3 className='text-center fw-bold text-light p-2'>Cargando</h3>

            <div className=' text-center w-25 m-auto d-flex justify-content-center align-items-center ' style={{ height: '6rem' }}>
                <Spinner animation="border" variant="warning" style={{height: '3rem', width:'3rem'}}/>
               {/*  <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="warning" /> */}
            </div>
        </div>
    )



}

export default Loading