import { ReactNode } from "react";
import { toast, ToastOptions } from "react-toastify";

type TipoAlerta = "success" | "danger" | "info" | undefined

export const AlertaRae = ( styles: TipoAlerta, msg: string | ReactNode | ReactNode[]) => {
    
    const toast_opciones: ToastOptions = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        
    }

    switch (styles) {
        case undefined:
            toast.error(msg, toast_opciones)
            break;
        case "success":
            toast.success(msg, toast_opciones)
            break;
        case "danger":
            toast.error(msg, toast_opciones);
            break
        case "info":
            toast.warn(msg, toast_opciones);
            break
        default:
            toast(msg, toast_opciones);
            break;
    }
}