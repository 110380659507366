import { ErrorMessage, Form, Formik } from "formik";
import { useState, useEffect, ChangeEvent, useRef, useContext } from "react";
import { default as FormRae } from "react-bootstrap/Form";
import { Button, Modal, Tabs } from "react-bootstrap";
import TablaRae from "../../../Components/Tablas/TablaRae";
import { ReservacionesRae } from "../Interfaces/InterfaceReservaciones";
import UseReservaciones from "../Hooks/UseReservaciones";
import ModalGrande from "../../../Components/Modales/ModalGrande";
import { format, getDay } from "date-fns/esm";
import UseMediadores from "../Hooks/UseMediadores";
import * as Yup from "yup";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import {
  addHours,
  addMinutes,
  addMonths,
  setHours,
  setMinutes,
} from "date-fns";
import Loading from "../../../Components/Loaders/Loading";
import UseCatalogos from "../../Publica/hooks/UseCatalogos";
import { diasRally } from "../../../../../backend/src/Modulos/DiasRally/Controllers/ControlerDiasRally";
import { ContextApp } from "../../../Context/StateApp";
import UseReservacionesConfirmadas from "../../ReservacionesConfirmadas/Hooks/UseReservacionesConfirmadas";

interface Props {
  registro_Seleccionado: ReservacionesRae | undefined;
  returnSubmitSuccess: () => void;
  cerrarModalForm: () => void;
}

const FormDatosGenerales = ({
  cerrarModalForm,
  registro_Seleccionado,
  returnSubmitSuccess,
}: Props) => {
  const {
    state: { usuario_logeado, logeado },
  } = useContext(ContextApp);
  const { confirmacionReservacionPost, enviarEmailConfirmacion } =
    UseReservaciones();
  const { cancelarReservacion } = UseReservacionesConfirmadas();

  const [mostraModal, setMostraModal] = useState(false);
  const { mediadoresGet, mediadores } = UseMediadores();
  console.log(mediadores);
  const [loading, setLoading] = useState(false);
  const {
    obtenerDiasFestivosGet,
    diasFestivos,
    diasRally,
    obtenerDiasRallyGet,
  } = UseCatalogos();

  const [reservacionSelect, setReservacionSelect] = useState<
    ReservacionesRae | any
  >({
    id_reservacion: "",
    folio_reservacion: "",
    modalidad: "",

    fecha_registro: "",
    fecha_uno: "",
    horario_uno_inicio: "",
    horario_uno_fin: "",
    fecha_dos: "",
    horario_dos_inicio: "",
    horario_dos_fin: "",

    momento_act: "",
    prim_vez: "",
    enterado_por: "",
    enterado_por_otro: "",
    id_mediador: "",
    estado: "",
    fecha_final: "",
    horario_final: "",

    cuento: {
      id_cuento: "",
      nombre_c: "",
    },

    taller: {
      id_taller: "",
      nombre_t: "",
    },

    idactividad: {
      id_actividad: "",
      nombre_actividad: "",
    },
    cancelar_reservacion_dos: false,
    motivo_cancelacion_dos: "",
    expectativas: "",
  });

  const isWeekday = (date: any) => {
    const day = getDay(date);
    return day !== 0;
  };

  useEffect(() => {
    mediadoresGet();
    obtenerDiasRallyGet();
    obtenerDiasFestivosGet();
  }, []);

  const Festivos = diasFestivos?.map((dias) => {
    const conversion = new Date(dias);
    return conversion;
  });

  const Rally = diasRally!.map((dia) => {
    const dias = dia.fecha_inicio?.replaceAll("-", "/");
    const conversion = new Date(dias!);
    return conversion;
  });

  let highlightWithRanges: any = [
    {
      "react-datepicker__day--highlighted-custom-3": Festivos,
    },
    {
      "react-datepicker__day--highlighted-custom-2": Rally,
    },
  ];

  useEffect(() => {
    if (registro_Seleccionado) {
      obtenerDiasFestivosGet();
      setReservacionSelect({
        id_reservacion: registro_Seleccionado.id_reservacion,
        folio_reservacion: registro_Seleccionado.folio_reservacion,
        nombre_actividad: registro_Seleccionado.idactividad!.nombre_actividad,
        id_actividad_a: registro_Seleccionado.idactividad!.id_actividad,

        modalidad: registro_Seleccionado.modalidad,

        fecha_registro: registro_Seleccionado.fecha_registro,
        fecha_uno: registro_Seleccionado.fecha_uno!,
        horario_uno_inicio: format(
          new Date(registro_Seleccionado.horario_uno_inicio!),
          "HH:mm"
        )!,
        horario_uno_fin: format(
          new Date(registro_Seleccionado.horario_uno_fin!),
          "HH:mm"
        )!,

        fecha_dos: registro_Seleccionado.fecha_dos!,
        horario_dos_inicio: format(
          new Date(registro_Seleccionado.horario_dos_inicio!),
          "HH:mm"
        )!,
        horario_dos_fin: format(
          new Date(registro_Seleccionado.horario_dos_fin!),
          "HH:mm"
        )!,

        momento_act: registro_Seleccionado.momento_act,
        prim_vez: registro_Seleccionado.prim_vez,
        enterado_por: registro_Seleccionado.enterado_por,
        enterado_por_otro: registro_Seleccionado.enterado_por_otro,
        id_mediador: "",
        fecha_final: "",
        horario_final_inicio: registro_Seleccionado.horario_final_inicio,
        horario_final_fin: registro_Seleccionado.horario_final_fin,

        id_responsable: registro_Seleccionado.responsable!.id_responsable,
        nombre: registro_Seleccionado.responsable!.nombre,
        ap_paterno: registro_Seleccionado.responsable!.ap_paterno,
        ap_materno: registro_Seleccionado.responsable!.ap_materno,
        correo_elec: registro_Seleccionado.responsable!.correo_elec,
        telefono: registro_Seleccionado.responsable!.telefono,
        estado: 1,

        cancelar_reservacion_dos: false,
        motivo_cancelacion_dos: "",
        
      });
    }
  }, [registro_Seleccionado]);

  const validaciones = Yup.object().shape({
    id_mediador: Yup.string().when("cancelar_reservacion_dos", {
      is: (value: boolean) => value === false,
      then: Yup.string().required("Este campo es obligatorio"),
    }),
    fecha_final: Yup.string().when("cancelar_reservacion_dos", {
      is: (value: boolean) => value == false,
      then: Yup.string().required("Este campo es obligatorio"),
    }),
    horario_final_inicio: Yup.string()
      .when("cancelar_reservacion_dos", {
        is: (value: boolean) => value == false,
        then: Yup.string().required("Este campo es obligatorio").nullable(),
      })
      .nullable(),
    horario_final_fin: Yup.string()
      .when("cancelar_reservacion_dos", {
        is: (value: boolean) => value == false,
        then: Yup.string().required("Este campo es obligatorio").nullable(),
      })
      .nullable(),

    cancelar_reservacion_dos: Yup.boolean(),
    motivo_cancelacion_dos: Yup.string().when("cancelar_reservacion_dos", {
      is: (value: boolean) => value === true,
      then: Yup.string().required("Este campo es obligatorio"),
    }),
  });

  interface PropsMediadores {
    id_mediador?: number;
    nombre: string;
    ap_paterno: string;
    ap_materno: string;
  }

  const horario_uno_inicio =
    format(new Date(), "yyyy-MM-dd") +
    " " +
    reservacionSelect.horario_uno_inicio;
  const horario_uno_fin =
    format(new Date(), "yyyy-MM-dd") + " " + reservacionSelect.horario_uno_fin;
  const horario_dos_inicio =
    format(new Date(), "yyyy-MM-dd") +
    " " +
    reservacionSelect.horario_dos_inicio;
  const horario_dos_fin =
    format(new Date(), "yyyy-MM-dd") + " " + reservacionSelect.horario_dos_fin;

  return (
    <div>
      <Formik
        initialValues={reservacionSelect}
        onSubmit={async (values) => {
          await Swal.fire({
            title:
              values.cancelar_reservacion_dos === true
                ? "¿Estás seguro de cancelar?"
                : "¿Estás seguro de confirmar?",
            showDenyButton: true,
            confirmButtonText:
              values.cancelar_reservacion_dos === true ? "Aceptar" : "Reservar",
            denyButtonText: `Cancelar`,
            confirmButtonColor: "#13322B",
            denyButtonColor: "#9D2449",
          }).then(async (result) => {
            if (result.isConfirmed) {
              const object = {
                id_evento: "",

                title: values.folio_reservacion,
                allDay: "",
                descripcion: values.nombre_actividad,
                notes: "",
                start: values.fecha_final + " " + values.horario_final_inicio,
                end: values.fecha_final + " " + values.horario_final_fin,
                habilitado: 0,
                responsable:
                  values.nombre +
                  " " +
                  values.ap_paterno +
                  " " +
                  values.ap_materno,

                id_reservacion: values.id_reservacion,
                id_mediador: values.id_mediador,
                fecha_final: values.fecha_final,
                estado: values.estado,
                horario_final_inicio:
                  values.fecha_final + " " + values.horario_final_inicio,
                horario_final_fin:
                  values.fecha_final + " " + values.horario_final_fin,
                usuario_log: usuario_logeado?.id_mediador,
                cancelar_reservacion_dos: values.cancelar_reservacion_dos,
                motivo_cancelacion_dos: values.motivo_cancelacion_dos,
              };

              const email = {
                nombre: values.nombre,
                primer_apellido: values.ap_paterno,
                segundo_apellido: values.ap_materno,
                correo_electronico: values.correo_elec,
                folio_reservacion: values.folio_reservacion,

                fecha: values.fecha_final,
                horario_inicio: values.horario_final_inicio,
                horario_fin: values.horario_final_fin,
              };

              setLoading(true);

              if (values.cancelar_reservacion_dos === true) {
                await cancelarReservacion(email);
              } else {
                await enviarEmailConfirmacion(email);
              }
              await confirmacionReservacionPost(object);
              returnSubmitSuccess();
              setLoading(false);
            } else if (result.isDenied) {
            }
          });
        }}
        enableReinitialize
        validationSchema={validaciones}
      >
        {({ handleChange, values, setFieldValue }) => (
          <Form>
            <Modal
              size="sm"
              show={loading}
              onHide={() => setLoading(false)}
              backdrop="static"
              centered
              className="m-0 p-0"
            >
              <Modal.Body>
                <Loading />
              </Modal.Body>
            </Modal>
            <div className="row d-flex ">
              <FormRae.Group controlId="id_reservacion" className="d-none">
                <FormRae.Label>Folio Reservación</FormRae.Label>
                <FormRae.Control
                  type="text"
                  name="id_reservacion"
                  placeholder="Folio Reservación"
                  value={values.id_reservacion || ""}
                  disabled
                />
              </FormRae.Group>

              <FormRae.Group
                controlId="folio_reservacion"
                className="mb-3 col-12 "
              >
                <FormRae.Label>Folio de reservación</FormRae.Label>
                <FormRae.Control
                  type="text"
                  name="folio_reservacion"
                  placeholder="Folio Reservación"
                  value={values.folio_reservacion || ""}
                  disabled
                />
              </FormRae.Group>

              <div className="row p-0 m-0">
                <div className="col-md-12  col-lg-6 p-2">
                  <FormRae.Group controlId="nombre_actividad">
                    <FormRae.Label>Nombre de la actividad </FormRae.Label>
                    <FormRae.Control
                      type="text"
                      name="nombre_actividad"
                      placeholder="Nombre Actividad"
                      value={values.nombre_actividad || ""}
                      disabled
                    />
                  </FormRae.Group>
                </div>

                <div className="col-md-12 col-lg-6 p-2">
                  <FormRae.Group controlId="modalidad">
                    <FormRae.Label>Modalidad</FormRae.Label>
                    <FormRae.Control
                      type="text"
                      name="modalidad"
                      placeholder="Modalidad Cita"
                      value={values.modalidad || ""}
                      disabled
                    />
                  </FormRae.Group>
                </div>
              </div>

              <div className="row p-0 m-0">
                <div className="col-md-12  col-lg-6 p-2 border border-primary  border-3 ">
                  <FormRae.Group controlId="fecha_uno" className="mb-3 col-12">
                    <FormRae.Label>Primer fecha</FormRae.Label>
                    <FormRae.Control
                      type="text"
                      name="fecha_uno"
                      placeholder="Primer Fecha"
                      disabled
                      value={values.fecha_uno || ""}
                    />
                  </FormRae.Group>

                  <FormRae.Group
                    controlId="horario_uno_inicio"
                    className="mb-3 col-12"
                  >
                    <FormRae.Label>Horario de inicio</FormRae.Label>
                    <DatePicker
                      value={values.horario_uno_inicio || ""}
                      name="horario_uno_inicio"
                      onChange={(e: any) =>
                        setFieldValue(
                          "horario_uno_inicio",
                          format(new Date(e), "HH:mm")
                        )
                      }
                      minDate={new Date()}
                      maxDate={addMonths(new Date(), 5)}
                      filterDate={isWeekday}
                      showDisabledMonthNavigation
                      locale="es"
                      placeholderText="-- Selecciona una fecha --"
                      className="w-100 rounded-2  form-control "
                      dayClassName={(date: Date) => " rounded-4 "}
                      useWeekdaysShort
                      useShortMonthInDropdown
                      dateFormat={"HH:mm"}
                      disabled
                    />
                  </FormRae.Group>

                  <FormRae.Group
                    controlId="horario_uno_fin"
                    className="mb-3 col-12"
                  >
                    <FormRae.Label>Horario de término</FormRae.Label>
                    <DatePicker
                      value={values.horario_uno_fin || ""}
                      name="horario_uno_fin"
                      onChange={(e: any) =>
                        setFieldValue(
                          "horario_uno_fin",
                          format(new Date(e), "HH:mm")
                        )
                      }
                      minDate={new Date()}
                      maxDate={addMonths(new Date(), 12)}
                      filterDate={isWeekday}
                      showDisabledMonthNavigation
                      locale="es"
                      placeholderText="-- Selecciona una fecha --"
                      className="w-100 rounded-2  form-control "
                      dayClassName={(date: Date) => " rounded-4 "}
                      useWeekdaysShort
                      useShortMonthInDropdown
                      dateFormat={"HH:mm"}
                      disabled
                    />
                  </FormRae.Group>
                  <div className=" text-center">
                    <Button
                      className="m-auto  mb-1 bg-danger"
                      onClick={() => {
                        setReservacionSelect({
                          ...reservacionSelect,
                          fecha_final: values.fecha_uno,
                          horario_final_inicio: format(
                            new Date(horario_uno_inicio),
                            "HH:mm"
                          ),
                          horario_final_fin: format(
                            new Date(horario_uno_fin),
                            "HH:mm"
                          ),
                        });
                      }}
                    >
                      Seleccionar
                    </Button>
                  </div>
                </div>
                <div className="col-md-12  col-lg-6 p-2 border border-primary  border-3">
                  <FormRae.Group controlId="fecha_dos" className="mb-3 col-12">
                    <FormRae.Label>Segunda fecha</FormRae.Label>
                    <FormRae.Control
                      type="text"
                      name="fecha_dos"
                      placeholder="Segunda Fecha"
                      disabled
                      value={values.fecha_dos || ""}
                    />
                  </FormRae.Group>

                  <FormRae.Group
                    controlId="horario_dos_inicio"
                    className="mb-3 col-12"
                  >
                    <FormRae.Label>Horario de inicio</FormRae.Label>
                    <DatePicker
                      value={values.horario_dos_inicio || ""}
                      name="horario_dos_inicio"
                      onChange={(e: any) =>
                        setFieldValue(
                          "horario_dos_inicio",
                          format(new Date(e), "HH:mm")
                        )
                      }
                      minDate={new Date()}
                      maxDate={addMonths(new Date(), 12)}
                      filterDate={isWeekday}
                      showDisabledMonthNavigation
                      locale="es"
                      placeholderText="-- Selecciona una fecha --"
                      className="w-100 rounded-2  form-control "
                      dayClassName={(date: Date) => " rounded-4 "}
                      useWeekdaysShort
                      useShortMonthInDropdown
                      dateFormat={"HH:mm"}
                      disabled
                    />
                  </FormRae.Group>
                  <FormRae.Group
                    controlId="horario_dos_fin"
                    className="mb-3 col-12"
                  >
                    <FormRae.Label>Horario de término</FormRae.Label>
                    <DatePicker
                      value={values.horario_dos_fin || ""}
                      name="horario_dos_fin"
                      onChange={(e: any) =>
                        setFieldValue(
                          "horario_dos_fin",
                          format(new Date(e), "HH:mm")
                        )
                      }
                      minDate={new Date()}
                      maxDate={addMonths(new Date(), 12)}
                      filterDate={isWeekday}
                      showDisabledMonthNavigation
                      locale="es"
                      placeholderText="-- Selecciona una fecha --"
                      className="w-100 rounded-2  form-control "
                      dayClassName={(date: Date) => " rounded-4 "}
                      useWeekdaysShort
                      useShortMonthInDropdown
                      dateFormat={"yyyy-MM-dd"}
                      disabled
                    />
                  </FormRae.Group>
                  <div className=" text-center">
                    <Button
                      className="m-auto mb-1 bg-danger"
                      variant="primary"
                      onClick={() => {
                        setReservacionSelect({
                          ...reservacionSelect,
                          fecha_final: values.fecha_dos,
                          horario_final_inicio: format(
                            new Date(horario_dos_inicio),
                            "HH:mm"
                          ),
                          horario_final_fin: format(
                            new Date(horario_dos_fin),
                            "HH:mm"
                          ),
                        });
                      }}
                    >
                      Seleccionar
                    </Button>
                  </div>
                </div>
              </div>

              <br />
              <br />
              {values.nombre_c ? (
                <>
                  <FormRae.Group controlId="id_cuento" className="mb-3">
                    <FormRae.Label>Nombre del cuentacuentos</FormRae.Label>
                    <FormRae.Control
                      type="text"
                      name="id_cuento"
                      placeholder="Nombre del Cuentacuentos"
                      disabled
                      value={values.nombre_c || ""}
                    />
                  </FormRae.Group>
                </>
              ) : (
                ""
              )}

              {values.nombre_t ? (
                <>
                  <FormRae.Group controlId="nombre_t" className="mb-3">
                    <FormRae.Label>Nombre del taller</FormRae.Label>
                    <FormRae.Control
                      type="text"
                      name="nombre_t"
                      placeholder="Nombre del taller"
                      disabled
                      value={values.nombre_t || ""}
                    />
                  </FormRae.Group>
                </>
              ) : (
                ""
              )}

              {values.momento_act ? (
                <>
                  <FormRae.Group controlId="momento_act" className="mb-3">
                    <FormRae.Label>Momento de la actividad</FormRae.Label>
                    <FormRae.Control
                      type="text"
                      name="momento_act"
                      placeholder="Momento de la actividad"
                      disabled
                      value={values.momento_act || ""}
                    />
                  </FormRae.Group>
                </>
              ) : (
                ""
              )}

              <FormRae.Group controlId="id_mediador" className="mb-3">
                <FormRae.Label>* Asignación del guía</FormRae.Label>
                <FormRae.Select
                  name="id_mediador"
                  placeholder="Asigna el guía"
                  onChange={(e) => {
                    setReservacionSelect({
                      ...reservacionSelect,
                      id_mediador: e.target.value,
                    });
                    handleChange(e);
                  }}
                  value={values.id_mediador || ""}
                >
                  <option value="">
                    -- Seleccionar una persona mediadora --
                  </option>

                  {mediadores.map((mediador: PropsMediadores, index) => (
                    <option value={mediador.id_mediador} key={index}>
                      {" "}
                      {mediador.nombre} {mediador.ap_paterno}{" "}
                      {mediador.ap_materno}{" "}
                    </option>
                  ))}
                </FormRae.Select>
                <ErrorMessage
                  name="id_mediador"
                  className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                  component={"div"}
                />
              </FormRae.Group>

              <FormRae.Group controlId="fecha_final" className="mb-3">
                <FormRae.Label>* Asignar fecha final</FormRae.Label>
                <DatePicker
                  value={values.fecha_final || ""}
                  name="fecha_final"
                  onChange={(e: any) =>
                    setFieldValue(
                      "fecha_final",
                      format(new Date(e), "yyyy-MM-dd")
                    )
                  }
                  minDate={new Date()}
                  maxDate={addMonths(new Date(), 12)}
                  filterDate={isWeekday}
                  showDisabledMonthNavigation
                  locale="es"
                  placeholderText="-- Selecciona una fecha --"
                  className="w-100 rounded-2  form-control "
                  dayClassName={(date: Date) => " rounded-4 "}
                  useWeekdaysShort
                  highlightDates={highlightWithRanges}
                  useShortMonthInDropdown
                  dateFormat={"yyyy-MM-dd"}
                />
                <ErrorMessage
                  name="fecha_final"
                  className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                  component={"div"}
                />
              </FormRae.Group>

              <div className="row p-0 m-0">
                <div className="col-lg-6 col-md-12">
                  <FormRae.Group
                    controlId="horario_final_inicio"
                    className="mb-3 p-1"
                  >
                    <FormRae.Label>
                      * Asignar el horario de inicio
                    </FormRae.Label>
                    <DatePicker
                      value={values.horario_final_inicio || ""}
                      name="horario_final_inicio"
                      onChange={(e: any) => {
                        console.log(format(new Date(e), "HH:mm"));
                        console.log(
                          format(setMinutes(new Date(e), 120), "HH:mm")
                        );
                        if (values.id_actividad_a == 1) {
                          setFieldValue(
                            "horario_final_fin",
                            format(addMinutes(new Date(e), 120), "HH:mm")
                          );
                        }
                        if (
                          values.id_actividad_a == 3 ||
                          values.id_actividad_a == 2
                        ) {
                          setFieldValue(
                            "horario_final_fin",
                            format(addMinutes(new Date(e), 135), "HH:mm")
                          );
                        }
                        if (
                          values.id_actividad_a == 5 ||
                          values.id_actividad_a == 4
                        ) {
                          setFieldValue(
                            "horario_final_fin",
                            format(addMinutes(new Date(e), 45), "HH:mm")
                          );
                        }

                        setFieldValue(
                          "horario_final_inicio",
                          format(new Date(e), "HH:mm")
                        );
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      minTime={setHours(setMinutes(new Date(), 0), 9)}
                      maxTime={setHours(setMinutes(new Date(), 0), 18)}
                      timeCaption="Time"
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                      className=" rounded-2  form-control "
                      placeholderText="-- Seleciona un horario -- "
                    />
                    <ErrorMessage
                      name="horario_final_inicio"
                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                      component={"div"}
                    />
                  </FormRae.Group>
                </div>

                <div className="col-lg-6 col-md-12">
                  <FormRae.Group
                    controlId="horario_final_fin"
                    className="mb-3 p-1"
                  >
                    <FormRae.Label>
                      * Asignar el horario de término
                    </FormRae.Label>
                    <DatePicker
                      value={values.horario_final_fin || ""}
                      name="horario_final_fin"
                      onChange={(e: any) =>
                        setFieldValue(
                          "horario_final_fin",
                          format(new Date(e), "HH:mm")
                        )
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      minTime={setHours(setMinutes(new Date(), 0), 9)}
                      maxTime={setHours(setMinutes(new Date(), 0), 18)}
                      timeCaption="Time"
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                      className=" rounded-2  form-control "
                      placeholderText="-- Seleciona un horario -- "
                      disabled
                    />
                    <ErrorMessage
                      name="horario_final_fin"
                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                      component={"div"}
                    />
                  </FormRae.Group>
                </div>
              </div>

              <FormRae.Group
                controlId="cancelar_reservacion"
                className="mb-3 pr-3"
              >
                <FormRae.Label>Cancelar Reservación</FormRae.Label>
                <FormRae.Check
                  type="checkbox"
                  name="cancelar_reservacion_dos"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue("cancelar_reservacion_dos", e.target.checked)
                  }
                  checked={values.cancelar_reservacion_dos || false}
                />
              </FormRae.Group>

              {values.cancelar_reservacion_dos === true ? (
                <>
                  <FormRae.Group
                    controlId="motivo_cancelacion"
                    className="mb-3"
                  >
                    <FormRae.Label>* Motivo de cancelación</FormRae.Label>
                    <FormRae.Control
                      as="textarea"
                      type="text"
                      name="motivo_cancelacion_dos"
                      onChange={handleChange}
                      value={values.motivo_cancelacion_dos || ""}
                      style={{ height: "200px" }}
                      placeholder="Ingresa el motivo de la cancelación"
                    />
                    <ErrorMessage
                      name="motivo_cancelacion_dos"
                      className="text-dark bg-dark rounded-2 bg-opacity-25 p-1"
                      component={"div"}
                    />
                  </FormRae.Group>
                </>
              ) : (
                ""
              )}
            </div>

        

            <TablaRae.ModalFooter>
              <Button variant="primary" type="submit">
                Confirmar
              </Button>
              <Button variant="primary" onClick={cerrarModalForm}>
                Cerrar
              </Button>
            </TablaRae.ModalFooter>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormDatosGenerales;
