import { addMonths, format, getDay, setHours, setMinutes } from 'date-fns';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import TablaRae from '../../../Components/Tablas/TablaRae';
import { ReservacionesRae } from '../Interfaces/InterfaceReservacionesCanceladas';
import DatePicker from 'react-datepicker';
import { ErrorMessage, Form, Formik } from 'formik';
import { default as FormRae } from 'react-bootstrap/Form'
import UseCitasCanceladas from '../Hooks/UseReservacionesCanceladas';




interface Props {
    registro_Seleccionado: ReservacionesRae | undefined;
    returnSubmitSuccess: () => void,
    cerrarModalForm: () => void
}


const FormDatosGenerales = ({ cerrarModalForm, registro_Seleccionado, returnSubmitSuccess }: Props) => {


    const { reservacionesCanceladasGet } = UseCitasCanceladas()
    const [mostraModal, setMostraModal] = useState(false);
    const [reservacionSelect, setReservacionSelect] = useState<ReservacionesRae | any>({
        id_reservacion: '',
        folio_reservacion: '',
        modalidad: '',

        fecha_registro: '',
        fecha_uno: '',
        horario_uno_inicio: '',
        horario_uno_fin: '',
        fecha_dos: '',
        horario_dos_inicio: '',
        horario_dos_fin: '',

        momento_act: '',
        prim_vez: '',
        enterado_por: '',
        enterado_por_otro: '',
        id_mediador: '',
        estado: '',
        fecha_final: '',
        horario_final: '',

        cuento: {
            id_cuento: '',
            nombre_c: '',
        },

        taller: {
            id_taller: '',
            nombre_t: '',
        },

        idactividad: {
            id_actividad: '',
            nombre_actividad: '',
        },

    })

    const isWeekday = (date: any) => {
        const day = getDay(date);
        return day !== 0 && day !== 6;
    };
    const cerrarModal = () => {
        setMostraModal(false)
        if (registro_Seleccionado) {
            setReservacionSelect(false)
        }
    }



    useEffect(() => {
        if (registro_Seleccionado) {
            setReservacionSelect({
                id_reservacion: registro_Seleccionado.id_reservacion,
                folio_reservacion: registro_Seleccionado.folio_reservacion,
                nombre_actividad: registro_Seleccionado.idactividad.nombre_actividad,
                modalidad: registro_Seleccionado.modalidad,

                fecha_registro: registro_Seleccionado.fecha_registro,
                fecha_uno: format(new Date(registro_Seleccionado.fecha_uno!), "yyyy-MM-dd")!,
                horario_uno_inicio: registro_Seleccionado.horario_uno_inicio,
                horario_uno_fin: registro_Seleccionado.horario_uno_fin,

                fecha_dos: format(new Date(registro_Seleccionado.fecha_dos!), "yyyy-MM-dd"),
                horario_dos_inicio: registro_Seleccionado.horario_dos_inicio,
                horario_dos_fin: registro_Seleccionado.horario_dos_fin,

                nombre_t: registro_Seleccionado.taller ? registro_Seleccionado.taller.nombre_t : '',
                nombre_c: registro_Seleccionado.cuento ? registro_Seleccionado.cuento.nombre_c : '',

                momento_act: registro_Seleccionado.momento_act,
                prim_vez: registro_Seleccionado.prim_vez,
                enterado_por: registro_Seleccionado.enterado_por,
                enterado_por_otro: registro_Seleccionado.enterado_por_otro,
                id_mediador: '',
                fecha_final: registro_Seleccionado.fecha_final,
                horario_final_inicio: registro_Seleccionado.horario_final_inicio,
                horario_final_fin: registro_Seleccionado.horario_final_fin,
                motivo_cancelacion: registro_Seleccionado.motivo_cancelacion,

                id_responsable: registro_Seleccionado.responsable.id_responsable,
                nombre: registro_Seleccionado.responsable.nombre,
                ap_paterno: registro_Seleccionado.responsable.ap_paterno,
                ap_materno: registro_Seleccionado.responsable.ap_materno,
                correo_elec: registro_Seleccionado.responsable.correo_elec,
                telefono: registro_Seleccionado.responsable.telefono,

                estado: 1,
            })
        }

    }, [registro_Seleccionado])


    const horario_uno_inicio = format(new Date(), "yyyy-MM-dd") + ' ' + reservacionSelect.horario_uno_inicio;
    const horario_uno_fin = format(new Date(), "yyyy-MM-dd") + ' ' + reservacionSelect.horario_uno_fin;
    const horario_dos_inicio = format(new Date(), "yyyy-MM-dd") + ' ' + reservacionSelect.horario_dos_inicio;
    const horario_dos_fin = format(new Date(), "yyyy-MM-dd") + ' ' + reservacionSelect.horario_dos_fin;


    return (
        <div>
            <Formik
                initialValues={reservacionSelect}
                onSubmit={async (values) => {
                }}

                enableReinitialize
            /*validationSchema={validaciones}*/
            >
                {({ handleChange, values, setFieldValue }) => (
                    <Form>
                        <div className='row d-flex'>
                            <FormRae.Group controlId="folio_reservacion" className="d-none">
                                <FormRae.Label>Folio Reservación</FormRae.Label>
                                <FormRae.Control
                                    type="text"
                                    name="id_reservacion"
                                    placeholder='Folio Reservación'
                                    value={values.id_reservacion || ''}
                                    disabled
                                />
                            </FormRae.Group>

                            <FormRae.Group controlId="folio_reservacion" className="mb-3 col-12">
                                <FormRae.Label>Folio Reservación</FormRae.Label>
                                <FormRae.Control
                                    type="text"
                                    name="folio_reservacion"
                                    placeholder='Folio Reservación'
                                    value={values.folio_reservacion || ''}
                                    disabled
                                />
                            </FormRae.Group>

                            <FormRae.Group controlId="nombre_actividad" className="mb-3 col-6">
                                <FormRae.Label>Nombre de la actividad </FormRae.Label>
                                <FormRae.Control
                                    type="text"
                                    name="nombre_actividad"
                                    placeholder='Nombre Actividad'
                                    value={values.nombre_actividad || ''}
                                    disabled
                                />
                            </FormRae.Group>

                            <FormRae.Group controlId="modalidad" className="mb-3 col-6">
                                <FormRae.Label>Modalidad</FormRae.Label>
                                <FormRae.Control
                                    type="text"
                                    name="modalidad"
                                    placeholder='Modalidad Cita'
                                    value={values.modalidad || ''}
                                    disabled
                                />
                            </FormRae.Group>

                            {values.nombre_c ? (<>
                                <FormRae.Group controlId="id_cuento" className="mb-3">
                                    <FormRae.Label>Nombre del cuentacuentos</FormRae.Label>
                                    <FormRae.Control
                                        type="text"
                                        name="id_cuento"
                                        placeholder='Nombre del Cuentacuentos'
                                        disabled
                                        value={values.nombre_c || ''}
                                    />
                                </FormRae.Group>

                            </>) : ''}

                            {values.nombre_t ?

                                (<>
                                    <FormRae.Group controlId="nombre_t" className="mb-3">
                                        <FormRae.Label>Nombre del taller</FormRae.Label>
                                        <FormRae.Control
                                            type="text"
                                            name="nombre_t"
                                            placeholder='Nombre del taller'
                                            disabled
                                            value={values.nombre_t || ''}
                                        />
                                    </FormRae.Group>

                                </>) : ''}

                            <FormRae.Group controlId="nombre" className="mb-3">
                                <FormRae.Label>Nombre responsable</FormRae.Label>
                                <FormRae.Control
                                    type="text"
                                    name="nombre"
                                    placeholder='Momento de la actividad'
                                    disabled
                                    value={values.nombre + ' ' + values.ap_paterno + ' ' + values.ap_materno || ''}
                                />
                            </FormRae.Group>

                            <FormRae.Group controlId="fecha_final" className="mb-3" >
                                <FormRae.Label>Fecha de asignación </FormRae.Label>
                                <DatePicker
                                    value={values.fecha_final || 'No aplica'}
                                    name="fecha_final"
                                    onChange={(e: any) => setFieldValue("fecha_final", format(new Date(e), 'yyyy-MM-dd'))}
                                    minDate={new Date()}
                                    maxDate={addMonths(new Date(), 2)}
                                    filterDate={isWeekday}
                                    showDisabledMonthNavigation
                                    locale="es"
                                    placeholderText='-- Selecciona una fecha --'
                                    className='w-100 rounded-2  form-control '
                                    dayClassName={(date: Date) => ' rounded-4 '}
                                    useWeekdaysShort
                                    useShortMonthInDropdown
                                    dateFormat={'yyyy-MM-dd'}
                                    disabled
                                />

                            </FormRae.Group>

                            <div className='w-100 d-flex'>
                                <div className='w-50'>
                                    <FormRae.Group controlId="horario_final_inicio" className="mb-3 p-1">
                                        <FormRae.Label>Horario de inicio</FormRae.Label>
                                        <DatePicker
                                            value={values.horario_final_inicio || ''}
                                            name="horario_final_inicio"
                                            onChange={(e: any) => setFieldValue("horario_final_inicio", format(new Date(e), 'HH:mm:ss'))}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            minTime={setHours(setMinutes(new Date(), 0), 9)}
                                            maxTime={setHours(setMinutes(new Date(), 30), 18)}
                                            timeCaption="Time"
                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                            className=' rounded-2  form-control '
                                            placeholderText='-- Seleciona un horario -- '
                                            disabled
                                        />

                                    </FormRae.Group>
                                </div>
                                <div className='w-50'>
                                    <FormRae.Group controlId="horario_final_fin" className="mb-3 p-1">
                                        <FormRae.Label>Horario de término</FormRae.Label>
                                        <DatePicker
                                            value={values.horario_final_fin || ''}
                                            name="horario_final_fin"
                                            onChange={(e: any) => setFieldValue("horario_final_fin", format(new Date(e), 'HH:mm:ss'))}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            minTime={setHours(setMinutes(new Date(), 0), 9)}
                                            maxTime={setHours(setMinutes(new Date(), 30), 18)}
                                            timeCaption="Time"
                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                            className=' rounded-2  form-control '
                                            placeholderText='-- Seleciona un horario -- '
                                            disabled
                                        />

                                    </FormRae.Group>
                                </div>
                            </div>
                            <FormRae.Group controlId="motivo_cancelacion" className="mb-3">
                                <FormRae.Label>Motivo de la cancelación</FormRae.Label>
                                <FormRae.Control
                                    as="textarea"
                                    type="text"
                                    name="motivo_cancelacion"
                                    onChange={handleChange}
                                    value={values.motivo_cancelacion || ''}
                                    style={{ height: "200px" }}
                                    disabled
                                />
                            </FormRae.Group>


                        </div>

                        <TablaRae.ModalFooter>
                            {/*   <Button variant="primary"
                                type="submit"
                            >
                                Confirmar
                            </Button> */}
                            <Button variant="primary" onClick={cerrarModalForm} >
                                Cerrar
                            </Button>

                        </TablaRae.ModalFooter>




                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default FormDatosGenerales