import { format } from 'date-fns';
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { TableColumn } from 'react-data-table-component';
import ModalGrande from '../../../Components/Modales/ModalGrande';
import TablaRae from '../../../Components/Tablas/TablaRae';
import UseReservaciones from '../Hooks/UseReservaciones';
import { ReservacionesPropTabla } from '../Interfaces/InterfaceReservaciones';
import FormCitas from './FormReservaciones';

import { BiEdit } from "react-icons/bi";


const TablaCitas = () => {

    const { reservaciones, reservacionesGet } = UseReservaciones()
    const [mostrarModal, setMostrarModal] = useState(false)
    const [registroSeleccionado, setRegistroSeleccionado] = useState<ReservacionesPropTabla | any>({
        id_reservacion: '',
        folio_reservacion: '',
        modalidad: '',
        fecha_registro: '',

        fecha_uno: '',
        horario_uno_inicio: '',
        horario_uno_fin: '',
        fecha_dos: '',
        horario_dos_inicio: '',
        horario_dos_fin: '',

        momento_act: '',
        prim_vez: '',
        enterado_por: '',
        id_guia: '',
        estado: '',
        fecha_final: '',

        taller: {
            id_taller: '',
            nombre_t: '',
        },

        cuento: {
            id_cuento: '',
            nombre_c: '',
        },

        grupo: {
            tipo_grupo: '',
            numero_hom: '',
            numero_muj: '',
            codigo_postal: '',

            pais: {
                id_pais: '',
                nombre_pais: '',
            },
            estato: {
                id_estado: '',
                nombre_estado: ''
            },
            municipio: {
                id_municipio: '',
                nombre_municipio: '',
            },
            colonia: {
                id_colonia: '',
                nombre: ''
            },
            calle_num: '',
            grado_escolar: '',
            sexo: '',
            nombre_institucion: ''
        },

        responsable: {
            nombre: '',
            ap_paterno: '',
            ap_materno: '',
            correo_elec: '',
            telefono: '',
        },

        vehiculos: {
            tipo_vehiculo: '',
            num_vehiculos: '',
            placas: '',
        },

        idactividad: {
            id_actividad: '',
            nombre_actividad: '',
        },
    })

    useEffect(() => {
        reservacionesGet()
        setRegistroSeleccionado(false)
    }, [])


    useEffect(() => {
        if (mostrarModal == false) {
            reservacionesGet()
        }
    }, [])




    const cerrarModal = () => {
        setMostrarModal(false)
        if (registroSeleccionado) {
            setRegistroSeleccionado(false)
        }
        reservacionesGet()
    }


    const columns: TableColumn<ReservacionesPropTabla | any>[] = [
        {
            cell: (row) => (
                <div>
                    <Button variant='warning' onClick={() => {
                        setRegistroSeleccionado(row)
                        setMostrarModal(true)
                        console.log(row);

                    }} >
                        <BiEdit className='text-light' />
                    </Button>
                </div>
            ),
            grow: 1
        },
        {
            name: "Folio Reservación",
            cell: (row) => row.folio_reservacion,
            grow: 2
        },
        {
            name: "Primer Fecha",
            selector: (row) => row.fecha_uno,
            grow: 2
        },
        {
            name: "Primer Horario inicio",
            selector: (row) => format(new Date(row.horario_uno_inicio), 'HH:mm aa'),
            grow: 2
        },
        {
            name: "Primer Horario fin",
            selector: (row) => format(new Date(row.horario_uno_fin), 'HH:mm aa'),
            grow: 2
        },
        {
            name: "Segunda Fecha",
            selector: (row) => row.fecha_dos,
            grow: 2
        },
        {
            name: "Segundo Horario Inicio",
            selector: (row) => format(new Date(row.horario_dos_inicio), 'HH:mm aa'),
            grow: 2
        },
        {
            name: "Segundo Horario Fin",
            selector: (row) => format(new Date(row.horario_dos_fin), 'HH:mm aa'),
            grow: 2
        },
    ]


    return (

        <div>
            <TablaRae
                columns={columns}
                data={reservaciones}
                //onclicNuevo={() => { setMostrarModal(true) }}
                closeModal={cerrarModal}
                show_modal={mostrarModal}
                paginationPerPage={20}
            />

            <ModalGrande
                size='xl'
                show_modal={mostrarModal}
                closeModal={cerrarModal}
            >
                <ModalGrande.ModalHeader >
                    <h2 style={{ color: "white" }}>Confirmar reservación</h2>
                </ModalGrande.ModalHeader>

                <ModalGrande.ModalBody >
                    <FormCitas
                        cerrarModalForm={cerrarModal}
                        registro_Seleccionado={registroSeleccionado}
                        returnSubmitSuccess={() => {
                            reservacionesGet()
                            cerrarModal()
                        }}
                    />

                </ModalGrande.ModalBody>
            </ModalGrande>





        </div>
    )
}

export default TablaCitas