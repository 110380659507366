import React from 'react'
import { ModalProps, Modal } from 'react-bootstrap';
import { ReactNode } from 'react';



interface PropsModal {

    children?: ReactNode,
    show_modal?: boolean,
    closeModal?: () => void,
    size:"lg"

}


function ModalCalendario({ children, show_modal, closeModal, size }: PropsModal) {


    return (
        <>
            <Modal show={show_modal} onHide={closeModal} size={size} >

                {children}

            </Modal>


        </>
    )
}

const ModalHeaderCalendario = ({ children }: { children: ReactNode }) => {
    return (
        <Modal.Header closeButton className='bg-warning'>
            <Modal.Title >{children}</Modal.Title>
        </Modal.Header>
    )
}


const ModalBodyCalendario = ({ children }: { children: ReactNode }) => {
    return (
        <Modal.Body> {children} </Modal.Body>
    )
}

const ModalFooterCalendario = ({ children }: { children: ReactNode }) => {
    return (
        <Modal.Footer>
          {children}
        </Modal.Footer>
    )
}
ModalCalendario.ModalBody = ModalBodyCalendario
ModalCalendario.ModalHeader = ModalHeaderCalendario
ModalCalendario.ModalFooter = ModalFooterCalendario

export default ModalCalendario