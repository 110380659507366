
import { format } from 'date-fns/esm';


import UseReservacionesRally from '../Hooks/UseReservacionesRally';
import { ReservacionesRae } from '../Interfaces/InterfaceReservacionesRally';
import UseMediadores from '../Hooks/UseMediadores';
import TablaRae from '../../../Components/Tablas/TablaRae';
import FormReservacionesRally from './FormReservacionesRally';

import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import PaginacionTablas from '../../../Components/Paginaciones/PaginacionTablas'


import DataTable, { TableColumn } from 'react-data-table-component';
import ModalGrande from '../../../Components/Modales/ModalGrande';
import { Formik, Form, ErrorMessage } from 'formik';
import { default as FormRae } from 'react-bootstrap/Form'
import { BiMessageSquareEdit } from "react-icons/bi";
import Swal from 'sweetalert2';
import * as Yup from 'yup'
import Loading from '../../../Components/Loaders/Loading';
import { useContext } from 'react';
import { ContextApp } from '../../../Context/StateApp';


const TablaReservacionRally = () => {
    const { confirmacionReservacionRallyPost, enviarEmailConfirmacion,
        diasRally, obtenerDiasRallyGet,
        horarioRally, obtenerHorariosRallyGet,
        reservacionesRally, reservacionesRallyGet } = UseReservacionesRally()

        const { state: { usuario_logeado, logeado } } = useContext(ContextApp)

    const [mostrarModal, setMostrarModal] = useState(false)
    const { mediadoresGet, mediadores } = UseMediadores();
    const [loading, setLoading] = useState(false)

    const [selectRow, setSelectRow] = useState<ReservacionesRae | any>([])


    const [registroSeleccionado, setRegistroSeleccionado] = useState<ReservacionesRae | any>({
        id_reservacion: '',
        folio_reservacion: '',
        modalidad: '',
        fecha_registro: '',
        fecha_inicio: '',
        horario_inicio: '',
        horario_fin: '',

        momento_act: '',
        prim_vez: '',
        enterado_por: '',
        fecha_actividad: '',
        horario_actividad: '',
        estado: 1,
        fecha_final: '',
        horario_final_inicio: '',
        horario_final_fin: '',
        select: '',


        taller: {
            id_taller: '',
            nombre_t: '',
        },

        cuento: {
            id_cuento: '',
            nombre_c: '',
        },

        grupo: {
            tipo_grupo: '',
            codigo_postal: '',

            pais: {
                id_pais: '',
                nombre_pais: '',
            },
            estato: {
                id_estado: '',
                nombre_estado: ''
            },
            municipio: {
                id_municipio: '',
                nombre_municipio: '',
            },
            colonia: {
                id_colonia: '',
                nombre: ''
            },
            calle_num: '',
            grado_escolar: '',
            sexo: '',
            nombre_institucion: ''
        },

        responsable: {
            nombre: '' || null,
            ap_paterno: '' || null,
            ap_materno: '' || null,
            correo_elec: '' || null,
            telefono: '' || null,
        } || null,

        vehiculos: {
            tipo_vehiculo: '',
            num_vehiculos: '',
            placas: '',
        },

        idactividad: {
            id_actividad: '',
            nombre_actividad: '',
        },
    })

    useEffect(() => {
        setLoading(false)
        setRegistroSeleccionado(false)
        mediadoresGet()
        obtenerDiasRallyGet()

    }, [])




    const cerrarModal = () => {
        setMostrarModal(false)
        if (registroSeleccionado) {
            reservacionesRallyGet(registroSeleccionado.fecha_actividad ? registroSeleccionado.fecha_actividad : undefined)
            setRegistroSeleccionado(false)
        }

    }

    interface PropsDiasRally {
        horario_fin?: string,
        horario_inicio?: Date | string | number,
        id_dia?: number,
        nombre_dia?: string,
        fecha_inicio?: string,
        fecha_fin?: string
    }


    interface PropsMediadores {
        id_mediador?: number,
        nombre: string,
        ap_paterno: string,
        ap_materno: string
    }

    const columns: TableColumn<ReservacionesRae | any>[] = [
        {
            cell: (row) => (
                <div>
                    <Button variant='warning' onClick={() => {
                        setRegistroSeleccionado(row)
                        setMostrarModal(true)
                        console.log(row);
                    }} className='border border-light' >
                        <BiMessageSquareEdit className='text-white ' />
                    </Button>
                </div>
            ),
            grow: 1
        },
        {
            name: "Folio Reservación",
            cell: (row) => row.folio_reservacion,
            grow: 2
        },
        {
            name: "Fecha Actividad",
            selector: (row) => row.fecha_actividad,
            grow: 2
        },
        {
            name: "Nombre Responsable",
            selector: (row) => row.responsable.nombre ? row.responsable.nombre + ' ' + row.responsable.ap_paterno + ' ' + row.responsable.ap_materno : '',
            grow: 4
        },

    ]

    const validaciones = Yup.object().shape({
        num_grupo_rally: Yup.string().required('Este campo es obligatorio'),
        select: Yup.string().min(5, 'El campo no puede estar vacío'),
    })

    return (

        <div>

            <Modal
                size="sm"
                show={loading}
                onHide={() => setLoading(false)}
                backdrop="static"
                centered
            >
                <Modal.Body>
                    <Loading />
                </Modal.Body>
            </Modal>


            <Formik


                initialValues={{
                    horario_actividad: "",
                    fecha_actividad: '',
                    num_grupo_rally: '',
                    estado: 1,
                }}
                validationSchema={validaciones}

                onSubmit={
                    async ({ num_grupo_rally, estado }, helpers) => {

                        await Swal.fire({
                            title: '¿Estás seguro de confirmar  y crear el grupo?',
                            showDenyButton: true,
                            confirmButtonText: 'Reservar',
                            denyButtonText: `Cancelar`,
                            confirmButtonColor: '#13322B',
                            denyButtonColor: '#9D2449',
                        }).then(async (result) => {
                            if (result.isConfirmed) {

                                if (selectRow ? selectRow.length === 0 : selectRow === undefined) {
                                    return await Swal.fire({
                                        title: 'Debes de seleccionar por lo menos un registro',
                                        icon: 'error',
                                        showDenyButton: false,
                                        confirmButtonText: 'Aceptar',
                                        confirmButtonColor: '#B38E5D',
                                        buttonsStyling: true,
                                        color: 'white',
                                        background: '#13322B'
                                    })
                                }

                                const object = {
                                    id_evento: '',
                                    title: num_grupo_rally,
                                    allDay: '',
                                    descripcion: 'Rally',
                                    notes: '',
                                    start: selectRow[0].horario_inicio! && selectRow[0].fecha_actividad + ' ' + format(new Date(selectRow[0].horario_inicio!.toString().split(".")[0]), "HH:mm"),
                                    end: selectRow[0].horario_fin! && selectRow[0].fecha_actividad + ' ' + format(new Date(selectRow[0].horario_fin!.toString().split(".")[0]), "HH:mm"),
                                    habilitado: 0,
                                    //responsable: values.nombre + values.ap_paterno + values.ap_materno,
                                    num_grupo_rally: num_grupo_rally,
                                    estado: 1,
                                    reservaciones_seleccionadas: selectRow,
                                    fecha_final: selectRow[0].fecha_actividad,
                                    horario_inicio: selectRow[0].horario_inicio! &&  selectRow[0].horario_inicio!.toString(),
                                    horario_fin: selectRow[0].horario_fin! &&  selectRow[0].horario_fin!.toString(),
                                    usuario_logeado:usuario_logeado?.id_mediador
                                }

                                setLoading(true)
                                for (let i of selectRow!) {
                                    await enviarEmailConfirmacion(i, num_grupo_rally)
                                }
                                await confirmacionReservacionRallyPost(object)
                                setLoading(false)
                                helpers.resetForm()
                                reservacionesRallyGet(undefined)
                                obtenerHorariosRallyGet(undefined)
                                window.location.reload();

                            } else if (result.isDenied) {

                            }
                        })
                    }}

                enableReinitialize
            >
                {({ values, handleChange, resetForm }) => (
                    <Form>

                        <div className='d-flex row shadow bg-secondary w-75 m-auto g-3  border border-3 border-primary p-2 mt-2 rounded-3'>

                            <h3 className='text-start'> Crear grupos </h3>

                            <div className='col-lg-6 col-md-12 col-sm-12'>
                                <FormRae.Group controlId="fecha_actividad" >
                                    <FormRae.Label>Día</FormRae.Label>
                                    <FormRae.Select
                                        name="fecha_actividad"
                                        placeholder='-- Selecciona una fecha --'
                                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                            if (e.target.value.length == 0) {
                                                obtenerHorariosRallyGet(undefined)
                                                reservacionesRallyGet(undefined)
                                            } else {
                                                obtenerHorariosRallyGet(e.target.value)
                                                reservacionesRallyGet(e.target.value)
                                            }
                                            handleChange(e)
                                        }}
                                        value={values.fecha_actividad || ''}
                                    >
                                        <option value="">-- Seleccionar un grupo --</option>
                                        {
                                            diasRally.map((rally: PropsDiasRally, index) => (
                                                <option value={rally.fecha_inicio} key={index} > {rally.fecha_inicio}  </option>
                                            ))
                                        }
                                    </FormRae.Select>
                                </FormRae.Group>
                            </div>

                            <div className='col-lg-6 col-md-12 col-sm-12'>
                                <FormRae.Group controlId="horario_actividad" >
                                    <FormRae.Label>Horario</FormRae.Label>
                                    <FormRae.Control
                                        name="horario_actividad"
                                        placeholder='-- Horario actividad --'
                                        value={`${horarioRally?.fecha_inicio ? format(new Date(horarioRally?.horario_inicio!), 'HH:mm') + ' - ' + format(new Date(horarioRally?.horario_fin!), 'HH:mm') : '-- Horario actividad --'} ` || ''}
                                        disabled
                                    >
                                    </FormRae.Control>
                                </FormRae.Group>

                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <FormRae.Group controlId="num_grupo_rally" >
                                    <FormRae.Label>Número de grupo</FormRae.Label>
                                    <FormRae.Select
                                        name="num_grupo_rally"
                                        placeholder='-- Selecciona un grupo --'
                                        onChange={handleChange}
                                        value={values.num_grupo_rally || ""}
                                    >
                                        <option value="" disabled>-- Seleccionar un grupo --</option>
                                        <option value="Grupo 1">Grupo 1</option>
                                        <option value="Grupo 2">Grupo 2</option>
                                        <option value="Grupo 3">Grupo 3 </option>
                                        <option value="Grupo 4">Grupo 4</option>
                                    </FormRae.Select>
                                    <ErrorMessage name='select' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                                    <ErrorMessage name='num_grupo_rally' className='text-dark bg-dark rounded-2 bg-opacity-25 p-1' component={"div"} />
                                </FormRae.Group>

                            </div>

                            <div className='col-lg-12 col-md-12 col-sm-12 align-content-sm-center'>
                                <Button variant="danger" type='submit'>
                                    Crear
                                </Button>

                            </div>
                        </div>



                        <br />


                    </Form>
                )}
            </Formik>


            <DataTable
                paginationPerPage={10}
                progressPending={loading}
                columns={columns}
                data={reservacionesRally!}
                responsive
                selectableRows
                onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                    setSelectRow([...selectedRows])
                    console.log(selectedRows)
                }}
                noDataComponent={<h3 className='text-warning '> Sin resultados </h3>}
                highlightOnHover
                selectableRowsHighlight
                /* selectableRowSelected={(row) => registro ? row.id === registro  ? true : false : false} */
                customStyles={{

                    headCells: {
                        style: {
                            backgroundColor: "var(--bs-primary)",
                            color: "white",
                            fontSize: ".8rem",
                            width: "auto",

                        }
                    },
                    rows: {

                        selectedHighlightStyle: {
                            color: "white !important",
                            backgroundColor: "var(--bs-warning)!important",
                        }

                    }
                }
                }
                pagination
                paginationComponent={PaginacionTablas}
            />


            <ModalGrande
                size='xl'
                show_modal={mostrarModal}
                closeModal={cerrarModal}
            >
                <ModalGrande.ModalHeader >
                    <h2 style={{ color: "white" }}> Confirmar reservaciones</h2>
                </ModalGrande.ModalHeader>

                <ModalGrande.ModalBody >
                    <FormReservacionesRally cerrarModalForm={cerrarModal} registro_Seleccionado={registroSeleccionado} returnSubmitSuccess={() => {
                        cerrarModal()
                    }} />

                </ModalGrande.ModalBody>
            </ModalGrande>

        </div>
    )
}
export default TablaReservacionRally