import { Children } from "react";
import { IndexRouteProps, LayoutRouteProps, PathRouteProps } from "react-router-dom";

//------Importaciones para las páginas del sistema
import PageReservaciones from '../Modulos/Reservaciones/Pages/PageReservaciones';
import PageReservacionesRally from "../Modulos/ReservacionesRally/Pages/PageReservacionesRally";
import PageReservacionesConfirmadas from "../Modulos/ReservacionesConfirmadas/Pages/PageReservacionesConfirmadas";
import PageReservacionesCanceladas from "../Modulos/ReservacionesCanceladas/Pages/PageReservacionesCanceladas";
import PageCalendario from "../Modulos/Calendario/Pages/PageCalendario";
import { PageCatalogos } from "../Modulos/Catálogos/Pages/PageCatalogos";
import PageCuentacuentos from "../Modulos/Cuentacuentos/Pages/PageCuentacuentos";
import PageTalleres from "../Modulos/Talleres/Pages/PageTalleres";
import PageContactos from "../Modulos/Contactos/Pages/PageContactos";
import PageUsuarios from "../Modulos/Usuarios/Pages/PageUsuarios";

//----Paginas Publicas
import PageBienvenidos from '../Modulos/Publica/Pages/PageBienvenidos';
import PageFormCitas from '../Modulos/Publica/Pages/PageFormCitas';
import PageLogin from '../Modulos/Login/Pages/PageLogin';

//-------Layouts del sistema
import LayoutAdmin from '../Layout/LayoutAdmin';
import LayoutPublic from "../Layout/LayoutPublic";

///----- Paginas para el inicio de sesión
import RequireAuth from './RutasPrivadas/RutasPrivadaLogin';
import RequireAuthAdmin from "./RutasPrivadas/RutaPrivadaAdmin";
import ProviderApp from "../Context/StateApp";

//------Importaciones para proteger las rutas
import RutaPrivadaFormulario from './RutasPrivadas/RutaPrivadaFormulario';
import RutaPrivadaReservaciones from './RutasPrivadas/RutaPrivadaReservaciones';
import RutaPrivadaReservacionesRally from './RutasPrivadas/RutaPrivadaReservacionesRally';
import RutaPrivadaReservacionesConfirmadas from './RutasPrivadas/RutaPrivadaReservacionesConfirmadas';
import RutaPrivadaCitasCanceladas from './RutasPrivadas/RutaPrivadaReservacionesCanceladas';
import RutaPrivadaCalendario from './RutasPrivadas/RutaPrivadaCalendario';
import RutaPrivadaCatalogos from './RutasPrivadas/RutaPrivadaCatalogos';
import RutaPrivadaCuentacuentos from './RutasPrivadas/RutaPrivadaCuentacuentos';
import RutaPrivadaTalleres from './RutasPrivadas/RutasPrivadasTalleres';
import RutaPrivadaContactos from './RutasPrivadas/RutaPrivadaContactos';
import RutaPrivadaUsuarios from './RutasPrivadas/RutasPrivadasUsuarios';


//-------Iconos--------

import { BsFileEarmarkPlusFill } from "react-icons/bs";//---Fomulario
import { BsJournalPlus } from "react-icons/bs";//--------Citas
import { BsJournalText } from "react-icons/bs";//--------Citas confirmadas
import { BsTable } from "react-icons/bs";//------Calendario
import { BsJournalBookmark } from "react-icons/bs";//-------Catalogos
import { BsJournals } from "react-icons/bs";//-----Cuentacuentos y Talleres
import { BsPersonBadgeFill } from "react-icons/bs"; //-----Usuarios

import { BsPersonLinesFill } from "react-icons/bs";
import { BsCalendarX } from "react-icons/bs";


export interface Pagina {

    to: string;
    element: JSX.Element;
    childrens?: Pagina[];
    path?: string /* Obligatorio si index no esta presente */;
    title?: string;
    index?: boolean;
    icon?: React.ReactNode;
    mostrar_en_sidebar?: boolean;
    id_modulo?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11,//---Las rutas con valor cero, tendran acceso a todos los usuarios del administrador
    tooltip?: React.ReactNode | JSX.Element
}

const paginas: Pagina[] = [

    {
        path: "/",
        to: "/",
        element: <LayoutPublic />,
        childrens: [
            {
                to: "/",
                title: "",/*Bienvenida*/
                index: true,
                element: <PageBienvenidos />
            },
            {
                to: "/form",
                title: "", /* Formulario para registrar la cita*/
                path: "/form",
                element: <ProviderApp><PageFormCitas /></ProviderApp>,

            },
        ]
    },


    {
        path: "/admin",
        to: "/admin",
        element: <ProviderApp><RequireAuthAdmin><LayoutAdmin /></RequireAuthAdmin></ProviderApp>,
        childrens: [
            {
                to: "/admin/form",
                title: "Formulario",
                path: "form",
                index: true,
                icon: <div className="text-primary "><BsFileEarmarkPlusFill size={"20px"} /></div>,
                element: <RutaPrivadaFormulario><PageFormCitas /></RutaPrivadaFormulario>,
                mostrar_en_sidebar: true,
                id_modulo: 1,
            },
            {
                to: "/admin/reservaciones",
                title: "Reservaciones",
                path: "reservaciones",
                icon: <div className="text-primary"><BsJournalPlus size={"20px"} /></div>,
                element: <RutaPrivadaReservaciones><PageReservaciones /></RutaPrivadaReservaciones>,
                mostrar_en_sidebar: true,
                id_modulo: 2

            },
            {
                to: "/admin/reservacionesrally",
                title: "Reservaciones Rally",
                path: "reservacionesrally",
                icon: <div className="text-primary"><BsJournalPlus size={"20px"} /></div>,
                element: <RutaPrivadaReservacionesRally><PageReservacionesRally /></RutaPrivadaReservacionesRally>,
                mostrar_en_sidebar: true,
                id_modulo: 3

            },
            {
                to: "/admin/reservacionesconfirmadas",
                title: "R. Confirmadas",
                path: "reservacionesconfirmadas",
                icon: <div className="text-primary"><BsJournalText size={"20px"} /></div>,
                mostrar_en_sidebar: true,
                element: <RutaPrivadaReservacionesConfirmadas><PageReservacionesConfirmadas /></RutaPrivadaReservacionesConfirmadas>,
                id_modulo: 4
            },
            {
                to: "/admin/reservacionescanceladas",
                title: "R. Canceladas",
                path: "reservacionescanceladas",
                mostrar_en_sidebar: true,
                icon: <div className="text-primary"><BsCalendarX size={"20px"} /></div>,
                element: <RutaPrivadaCitasCanceladas><PageReservacionesCanceladas /></RutaPrivadaCitasCanceladas>,
                id_modulo: 5,

            },
            {
                to: "/admin/calendario",
                title: "Calendario",
                path: "calendario",
                mostrar_en_sidebar: true,
                icon: <div className="text-primary"><BsTable size={"20px"} /></div>,
                element: <RutaPrivadaCalendario><PageCalendario /></RutaPrivadaCalendario>,
                id_modulo: 6
            },
            {
                to: "/admin/catalogos",
                title: "Catálogos",
                path: "catalogos",
                mostrar_en_sidebar: true,
                icon: <div className="text-primary"><BsJournalBookmark size={"20px"} /></div>,
                element: <RutaPrivadaCatalogos><PageCatalogos /></RutaPrivadaCatalogos>,
                id_modulo: 7
            },
            {
                to: "/admin/cuentacuentos",
                title: "Cuentacuentos",
                path: "cuentacuentos",
                mostrar_en_sidebar: true,
                icon: <div className="text-primary"><BsJournals size={"20px"}/></div>,
                element: <RutaPrivadaCuentacuentos><PageCuentacuentos /></RutaPrivadaCuentacuentos>,
                id_modulo: 8
            },
            {
                to: "/admin/talleres",
                title: "Talleres",
                path: "talleres",
                mostrar_en_sidebar: true,
                icon: <div className="text-primary"><BsJournals size={"20px"} /></div>,
                element: <RutaPrivadaTalleres><PageTalleres /></RutaPrivadaTalleres>,
                id_modulo: 9

            },
            {
                to: "/admin/contactos",
                title: "Contactos",
                path: "contactos",
                mostrar_en_sidebar: true,
                icon: <div className="text-primary"><BsPersonLinesFill size={"20px"} /></div>,
                element: <RutaPrivadaContactos><PageContactos /></RutaPrivadaContactos>,
                id_modulo: 10,

            },
            {
                to: "/admin/usuarios",
                title: "Usuarios",
                path: "usuarios",
                mostrar_en_sidebar: true,
                icon: <div className="text-primary"><BsPersonBadgeFill size={"20px"} /></div>,
                element: <RutaPrivadaUsuarios><PageUsuarios /></RutaPrivadaUsuarios>,
                id_modulo:11,

            },
        ]
    },
    {
        to: "/login",
        path: "login",
        element: <ProviderApp><RequireAuth><LayoutAdmin /></RequireAuth></ProviderApp>,
        childrens: [
            {
                to: "/login",
                title: "",
                index: true,
                element: <PageLogin />,
            }
        ]
    },
    {
        path: "/*",
        to: "/",
        element: <p className="text-center p-5 "> No existe la pagina </p>,
    }



]
export default paginas;