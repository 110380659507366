import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { TableColumn } from "react-data-table-component"
import TablaRae from "../../../Components/Tablas/TablaRae"
import useDiasFestivos from "../Hooks/UseDiasFestivos"
import { DiasFestivosProps } from "../Interfaces/InterfaceCatalogos"
import FormDiasFestivos from "./FormDiasFestivos"
import { BiHighlight } from "react-icons/bi"

import { BiMessageSquareEdit } from "react-icons/bi";

const TablaDiaFestivo = () => {

    const { diasFestivosAdmin, diasFestivosGetAdmin} = useDiasFestivos()
    const [mostrarModal, setMostrarModal] = useState(false)
    const [registroSeleccionado, setRegistroSeleccionado] = useState<DiasFestivosProps | any>({
        id_dia: undefined,
        nombre_dia: '',
        fecha_inicio: '',
        fecha_fin: ''
    })

    useEffect(() => {
        diasFestivosGetAdmin()
        setRegistroSeleccionado(false)
    }, [])

    const cerrarModal = () => {
        setMostrarModal(false)
        if (registroSeleccionado) {
            setRegistroSeleccionado(false)
        }
    }
    
    const columns: TableColumn<DiasFestivosProps>[] = [
        {
            cell: (row) => (
                <div>
                    <Button variant='warning' onClick={() => {
                        console.log(row)
                        setRegistroSeleccionado(row)
                        setMostrarModal(true)
                    }}>
                        <BiMessageSquareEdit size={'1.3rem'} className="text-light" />
                    </Button>
                </div>
            ),

        },
        {
            name: "Número",
            selector: (row) => row.id_dia!,
            grow: 1,
            center: true
        },
        {
            name: "Descripción ",
            selector: (row) => row.nombre_dia!,
            grow: 4,

        },
        {
            name: "Fecha de inicio",
            selector: (row) => row.fecha_inicio,
            grow: 1,
            center: true
        },
        {
            name: "Fecha de término",
            selector: (row) => row.fecha_fin,
            grow: 1,
            center: true
        }
    ]
    return (
        <div>
            <TablaRae
                columns={columns}
                data={diasFestivosAdmin}
                onclicNuevo={() => { setMostrarModal(true) }}
                closeModal={cerrarModal}
                show_modal={mostrarModal}
                paginationPerPage={10}
              
            >
                <TablaRae.ModalHeader >{registroSeleccionado ? "Actualizar Día festivo o excepción" : "Nuevo Día festivo o excepción"}  </TablaRae.ModalHeader>
                <TablaRae.ModalBody>
                    <FormDiasFestivos
                        registro_Seleccionado={registroSeleccionado}
                        returnSubmitSuccess={() => {
                            diasFestivosGetAdmin()
                            cerrarModal()
                        }}
                        cerrarModalForm={cerrarModal}
                    />
                </TablaRae.ModalBody>

            </TablaRae>
        </div>
    )
}

export default TablaDiaFestivo