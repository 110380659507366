import React from 'react'
import { ModalProps, Modal } from 'react-bootstrap';
import { ReactNode } from 'react';



interface PropsModal {

    children?: ReactNode,
    show_modal?: boolean,
    closeModal?: () => void,
    size:"xl"

}


function ModalGrande({ children, show_modal, closeModal, size }: PropsModal) {


    return (
        <>
            <Modal show={show_modal} onHide={closeModal} size={size} >

                {children}

            </Modal>


        </>
    )
}

const ModalHeaderlg = ({ children }: { children: ReactNode }) => {
    return (
        <Modal.Header closeButton className='bg-warning'>
            <Modal.Title >{children}</Modal.Title>
        </Modal.Header>
    )
}


const ModalBodylg = ({ children }: { children: ReactNode }) => {
    return (
        <Modal.Body> {children} </Modal.Body>
    )
}

const ModalFooterlg = ({ children }: { children: ReactNode }) => {
    return (
        <Modal.Footer>
          {children}
        </Modal.Footer>
    )
}
ModalGrande.ModalBody = ModalBodylg
ModalGrande.ModalHeader = ModalHeaderlg
ModalGrande.ModalFooter = ModalFooterlg

export default ModalGrande